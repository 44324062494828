import React from "react";

import {
    Grid,
    Button,
    Typography,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    List,
    ListItem,
    Card,
    CardContent,
    CardActions,
    Hidden, TableSortLabel
} from "@material-ui/core";
import {I18n} from "../i18n";
import classNames from "classnames";
import {Link} from "react-router-dom";

const ResponsiveTable = (props) => {
    const {columns, items, lang, i18n, classes, renderActions} = props;

    return (
        <>
            <Hidden mdUp>
                <List>
                    {items &&
                        items.map &&
                        items.map((row, rowIndex) => {
                            return (
                                <ListItem class={classes.tableCardItem}>
                                    <Card classes={{root: classes.tableCard}}>
                                        <CardContent>
                                            <table className={classes.cardTable}>
                                                {columns.map((column, index) => {
                                                    return (
                                                        <tr>
                                                            <td className={classes.cardTableHeader}>
                                                                <span>{i18n[column.title ?? column.id] ?? column.title}</span>
                                                            </td>

                                                            <td className={classes.cardTableValue}>
                                                                        <span>
                                                                          {column.displayFunc
                                                                              ? column.displayFunc(row, rowIndex)
                                                                              : row[column.id]}
                                                                        </span></td>

                                                        </tr>
                                                    );
                                                })}
                                            </table>
                                        </CardContent>

                                        {renderActions && (
                                            <CardActions  className={classNames("actions", classes.wauto)}>
                                                {renderActions(row)}
                                            </CardActions>
                                        )}

                                    </Card>
                                </ListItem>)
                        })}
                </List>
            </Hidden>

            <Hidden only={['xs', 'sm']}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {columns.map((item) => {
                                return (
                                    <TableCell
                                        className={classNames({
                                            [classes.centerCell]: item.center,
                                        })}
                                    >
                                        {i18n[item.title ?? item.id] ?? item.title}
                                    </TableCell>
                                );
                            })}
                            {renderActions && (
                                <TableCell></TableCell>
                            )}
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {items &&
                            items.map &&
                            items.map((row, rowIndex) => {
                                return (
                                    <TableRow key={row.id}>
                                        {columns.map((column, index) => {
                                            return (
                                                <TableCell
                                                    className={classNames({
                                                        [classes.evenCell]: index % 2 === 0,
                                                        [classes.centerCell]: column.center,
                                                    })}
                                                >
                                                    {column.displayFunc
                                                        ? column.displayFunc(row, rowIndex)
                                                        : row[column.id]}
                                                </TableCell>
                                            );
                                        })}
                                        {renderActions && (
                                            <TableCell  className={classNames("actions")}>
                                                {renderActions(row)}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </Hidden>
        </>
    )
        ;
}

export default ResponsiveTable;