export const availableColors = [
  '#2d77fc',
  '#33b0b1',
  '#bf17ff',
  '#003EB0',
  '#B07D10',
  '#9EDA13',
  '#FCBB2D',
  '#75edfd',
  '#000000'
];


export const tabs = [
  [ 'tab1', 'Events & Participants' ],
  [ 'tab2', 'U-LEAD with Europe results' ],
  [ 'tab3', 'Thematic Support Packages' ],
  [ 'tab4', 'Sector Decentralisation' ],
  // [ 'tab5', 'Regional Initiatives' ],
  // [ 'tab6', 'Micro-grants to CSOs' ]
]

export const layers = {
  tab1: [
    'total_events', 'event_formats',
    'total_participations', 'total_participants',
    'employer_types', 'employer_levels',
    'event_results', 'participant_results'
  ],
  tab2: [
    'so1d', 'r2a', 'r3b', 'r6a', 'r6b'
  ],
  tab3: [
    'startup', 'leader_steps', 'special_steps'
  ],
  tab4: [
    'sector_decentr_ah_outreach', 'sector_decentr_events', 'sector_decentr_participants'
  ]
}

export const coloredLayers = [
  layers.tab1[0],
  layers.tab1[2],
  layers.tab1[3],
  layers.tab2[0],
  layers.tab2[1],
  layers.tab2[2],
  layers.tab2[3],
  layers.tab2[4]
]
