import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { I18n } from "../../i18n/";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import classNames from "classnames";
import { Close } from "@material-ui/icons";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "94%",
    margin: "30px 3%",
    "& .required": {
      background: "red",
    },
  },
  modal: {
    position: "absolute",
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: "50%",
    height: "90vh",
    overflowY: "auto",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
    "&.error-field fieldset": {
      borderLeft: "1px solid #fe8368",
      borderLeftWidth: "6px",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      marginBottom: "0",
    },
  },
  buttonCancel: {
    marginRight: "20px",
  },
  select: {
    marginBottom: "20px",
    minWidth: "100%",
    "& > div": {
      background: "#f7f9fb",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
    "&.error-field > div": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const required = (value) => (value ? undefined : "Required");

const clusters = [
  { label: "a", value: "a" },
  { label: "b", value: "b" },
  { label: "c", value: "c" },
  { label: "I", value: "I" },
  { label: "II", value: "II" },
  { label: "III", value: "III" },
  { label: "IV", value: "IV" },
  { label: "V", value: "V" },
];

class ItemEdit extends React.Component {
  state = {
    open: true,
    errorCluster: false,
  };

  componentDidMount() {
    if (!this.props.item) {
      this.setState({ errorCluster: true });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to="/thematic-packages" />;
    }
  };

  saveItem = async (values) => {
    const data = {};
    const errorOpts = {};

    ["id", "nameEn", "nameUa", "position"].forEach((x) => {
      data[x] = values[x];
    });

    if (values.cluster && values.cluster.value) {
      data.cluster = values.cluster.value;
    } else if (typeof values.cluster != "string") {
      errorOpts.errorCluster = true;
    }

    if (errorOpts.errorCluster) {
      this.setState(errorOpts);
      return;
    }

    if (values.id) {
      await fetchData("put", `/thematic_modules/${values.id}`, data);
    } else {
      await fetchData("post", "/thematic_modules", data);
    }
    this.setState({ open: false });

    this.props.handleItemSave();
  };

  onSubmit = async (values) => {
    await sleep(300);
    this.saveItem(values);
  };

  handleDropdownChange = (option) => {
    this.setState({ [option]: false });
  };

  render() {
    const { classes, item, lang } = this.props;
    const { errorCluster } = this.state;

    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className="modal">
            <a className="close" onClick={this.handleClose}>
              <Close />
            </a>

            <Grid container spacing={24} alignItems="center">
              <Grid item xs={6}>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="h2"
                  style={{ color: "#fdbb2d" }}
                >
                  {item
                    ? `${I18n[lang].thematicPackages.edit} ${item.id}`
                    : I18n[lang].thematicPackages.create}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.data}>
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="nameUa" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label={I18n[lang].common.nameUa}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="nameEn" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label={I18n[lang].common.nameEn}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="cluster" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <InputLabel>{I18n[lang].common.cluster}</InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classNames(classes.select, {
                              "error-field": errorCluster,
                            })}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={clusters}
                            onMenuOpen={() =>
                              this.handleDropdownChange("errorCluster")
                            }
                            {...input}
                            value={clusters.find(
                              (option) => option.value === input.value
                            )}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="position">
                      {({ input }) => (
                        <TextField
                          label={I18n[lang].common.position}
                          margin="normal"
                          type="number"
                          className="text-field"
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
