export const styles = theme => ({
  root:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.only('md')]: {
      alignItems: 'flex-end',
    },
    '& .flex': {
      fontSize: '0.8rem',
      margin: '0',
      marginTop: '5px',
      fontWeight: '400',
      [theme.breakpoints.only('md')]: {
        minWidth: '100px',
      },
    },
  },
  yearsFilter:{
    color: '#778899',
    letterSpacing: '1.2px',
    '& .yearsFilter__title':{
      fontSize: '0.8rem',
      margin: '0',
      marginTop: '5px',
      marginBottom:'7px',
      paddingRight: '10px',
      fontWeight: '400',
      [theme.breakpoints.only('md')]: {
        fontSize: '1rem',
      },
    },
    '& .yearsFilter_color':{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.only('md')]: {
        width: 'auto',
        alignItems: 'flex-end',
      },
    },

  },
  dateWrap:{
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  colorBox:{
    height: '6px', 
    width: '100%  ',
    maxWidth: '80px',
  },
})
