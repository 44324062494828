import React from "react";
import {MuiPickersUtilsProvider, InlineDatePicker} from "material-ui-pickers";
import {Grid, Button, InputLabel} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import {withStyles} from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import RegistryGraphs from "./registry";
import IndicatorGraphs from "./indicators";
import {Link, Route} from "react-router-dom";
import {each, find} from "lodash";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {selectStyles} from "../../style/select-styles";
import {styles} from "./styles";
import Loader from "../../components/loader";
import {I18n} from "../../i18n";
import ItemEdit from "../indicator/item-edit";
import Print from "../../components/Print"
import Communities from "../communities";

class Graphs extends React.Component {

    state = {
        monthFrom: "",
        monthTo: "",
        regions: [],
        regionsSelected: [],
        owners: [],
        ownersSelected: [],
        formats: [],
        formatsSelected: [],
        graphsFilter: "",
        isVisibleDataPicker: false,
        loading: false,
    };


    getGraphFilters = (state) => {
        let {
            ownersSelected,
            formatsSelected,
            regionsSelected,
            monthFrom,
            monthTo,
        } = state;

        let filters = [];

        if (!monthFrom) {
            monthFrom = this.defaultStartDate();
        }

        if (!monthTo) {
            monthTo = this.defaultEndDate();
        }

        filters.push(`monthFrom=${monthFrom}`);
        filters.push(`monthTo=${monthTo}`);

        if (regionsSelected?.length) {
            let ids = regionsSelected
                .map((option) => `regionIds[]=${option.value}`)
                .join("&");
            filters.push(ids);
        }
        if (ownersSelected?.length) {
            let ids = ownersSelected
                .map((option) => `ownerIds[]=${option.value}`)
                .join("&");
            filters.push(ids);
        }
        if (formatsSelected?.length) {
            let ids = formatsSelected
                .map((option) => `formatIds[]=${option.value}`)
                .join("&");
            filters.push(ids);
        }

        if (filters.length) {
            filters = filters.join("&") + "&";
            return filters;
        } else {
            return "";
        }
    }

    componentDidMount() {
        this.InitFilter();
    }

    async InitFilter() {
        const regionsData = await fetchData("get", "/regions?size=1000");
        const regions = regionsData[0];
        let regionsList = [];
        each(regions, (item) => {
            if (item.en == "N/A" || item.en == "Autonomous Republic of Crimea") {
                return;
            }
            regionsList.push({
                value: item.id,
                label: this.props.lang === "Eng" ? item.en : item.ua,
            });
        });
        regionsList = regionsList.sort(function (a, b) {
            var nameA = a.label.toUpperCase();
            var nameB = b.label.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });

        let list = [];
        if (this.props.user && this.props.user.isAdmin) {
            const data = await fetchData("get", "/users/event_owners");
            list = data[0].map((item) => {
                return {value: item.id, label: item.name};
            });
        }

        const data2 = await fetchData("get", "/formats?size=200");
        const list2 = data2[0].map((item) => {
            return {value: item.id, label: item.en};
        });

        const graphsFilter = this.getGraphFilters(this.state)

        this.setState({
            isInitialized: true,
            regions: regionsList,
            owners: list,
            formats: list2,
            graphsFilter: graphsFilter
        });
    }

    handleDate = (field, date = new Date()) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const data = `${year}-${month}`;
        let value = data;
        if (value.indexOf("-") > 0) {
            let [year, month] = value.split("-");
            value = `${year.substr(0, 4)}-${month}`;
        }

        this.setState({[field]: value}, () => {
            this.handleFilterChange();
        });
    };

    handleClearDate = (field) => {
        this.setState({[field]: ""}, () => {
            this.handleFilterChange();
        });
    };

    isFirstPhase = () => {
        return this.props.user?.currentPhaseNumber == 1;
    }

    defaultStartDate = () => {
        return this.isFirstPhase() ? "2016-1" : "2020-7";
    }
    defaultEndDate = () => {
        return this.isFirstPhase() ? "2020-7" : "2025-1";
    }

    handleDropdownChange = (field) => {
        return (options) => {
            this.setState({[field + "Selected"]: options}, () => {
                this.handleFilterChange();
            });
        };
    };

    handleFilterChange = () => this.setState({graphsFilter: this.getGraphFilters(this.state)})

    render() {
        const {classes, lang, user} = this.props;
        const {regions, owners, formats, graphsFilter, isInitialized} = this.state;

        let kind = this.props.kind;
        if (!kind) {
            kind = "registry";
        }

        const isAdmin = user && user.isAdmin;
        const isGuest = user && user.authorities.indexOf("ROLE_GUEST") >= 0;
        const isFirstPhase = this.isFirstPhase();

        const currentPath = document.location.pathname;

        return !isInitialized ? (<div></div>) : (
            <>
                {this.state.loading && <Loader/>}

                <Grid container spacing={24} className={classes.root}>
                    <Grid item lg={4} md={12}>
                        <div className={classes.headerLinks}>
                            {isGuest ? (
                                ""
                            ) : (
                                <>
                                    <Link to={`/graphs`}>
                                        <Button
                                            variant={
                                                !!currentPath.includes("/graphs")
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            color="primary"
                                        >
                                            U-Data Registry Graphs
                                        </Button>
                                    </Link>

                                    <Link to={`/indicator-graphs`}>
                                        <Button
                                            variant={
                                                !!currentPath.includes("/indicator-graphs")
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            color="primary"
                                        >
                                            Indicator Graphs
                                        </Button>
                                    </Link>

                                </>
                                )
                                }
                        </div>
                    </Grid>
                    {kind != "indicators" && (
                        <Grid item lg={8} md={12} className={classes.filters}>
                            <div>
                                <InputLabel>Date range</InputLabel>
                                <div
                                    className={classNames(classes.calendar, classes.dateBlock)}
                                >
                                    <div
                                        className={classNames(classes.dateWrap, {
                                            transparent: this.state.monthFrom === "",
                                        })}
                                    >
                                        <div className={classes.dataIcon}>
                                            <img
                                                src="/images/icons/calendar.svg"
                                                alt="calendar"
                                                className="date-icon"
                                            />
                                        </div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <InlineDatePicker
                                                views={["year", "month"]}
                                                className={classNames(
                                                    "text-field",
                                                    classes.textField,
                                                    classes.dateField
                                                )}
                                                value={this.state.monthFrom}
                                                onChange={(data) => this.handleDate("monthFrom", data)}
                                                labelFunc={(date) =>
                                                    date ? moment(date).format("MM.YYYY") : ""
                                                }
                                            />
                                            {this.state.monthFrom !== "" ? (
                                                <HighlightOffIcon
                                                    className="clearDate"
                                                    onClick={() => this.handleClearDate("monthFrom")}
                                                />
                                            ) : null}
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div
                                        className={classNames(classes.dateWrap, {
                                            transparent: this.state.monthTo === "",
                                        })}
                                    >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className={classes.dataIcon}>
                                                <img
                                                    src="/images/icons/calendar.svg"
                                                    alt="calendar"
                                                    className="date-icon"
                                                />
                                            </div>
                                            <InlineDatePicker
                                                views={["year", "month"]}
                                                className={classNames(
                                                    "text-field",
                                                    classes.textField,
                                                    classes.dateField
                                                )}
                                                value={this.state.monthTo}
                                                onChange={(data) => this.handleDate("monthTo", data)}
                                                labelFunc={(date) =>
                                                    date ? moment(date).format("MM.YYYY") : ""
                                                }
                                                invalidDateMessage=""
                                                inputVariant="filled"
                                            />
                                            {this.state.monthTo !== "" ? (
                                                <HighlightOffIcon
                                                    className="clearDate"
                                                    onClick={() => this.handleClearDate("monthTo")}
                                                />
                                            ) : null}
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <InputLabel>Geography</InputLabel>
                                <Select
                                    styles={selectStyles()}
                                    className={classNames(classes.select)}
                                    closeMenuOnSelect={false}
                                    components={makeAnimated()}
                                    options={regions}
                                    onChange={this.handleDropdownChange("regions")}
                                    isMulti
                                />
                            </div>
                            {isAdmin && (
                                <div>
                                    <InputLabel>Event Owner</InputLabel>
                                    <Select
                                        styles={selectStyles()}
                                        className={classNames(classes.select)}
                                        closeMenuOnSelect={false}
                                        components={makeAnimated()}
                                        options={owners}
                                        onChange={this.handleDropdownChange("owners")}
                                        isMulti
                                    />
                                </div>
                            )}
                            <div>
                                <InputLabel>Event Format</InputLabel>
                                <Select
                                    styles={selectStyles()}
                                    className={classNames(classes.select)}
                                    closeMenuOnSelect={false}
                                    components={makeAnimated()}
                                    options={formats}
                                    onChange={this.handleDropdownChange("formats")}
                                    isMulti
                                />
                            </div>
                            <Link to={`/graphs/print`}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    className={classNames(
                                        classes.buttonGrey,
                                        classes.buttonDialogHeader
                                    )}
                                >
                                    <i/>
                                    {I18n[lang].common.print}
                                </Button>
                            </Link>
                        </Grid>
                    )}
                    {kind == "registry" ? (
                        <RegistryGraphs graphsFilter={graphsFilter} user={this.props.user} {...this.props}/>
                    ) : kind == "indicators" ? (
                        <IndicatorGraphs graphsFilter={graphsFilter} user={this.props.user}/>
                    ) : (
                        <>Custom Graph</>
                    )}
                </Grid>

                <Route
                    path="/graphs/print"
                    render={() => (
                        <Print graphsFilter={graphsFilter}
                               monthFrom={this.state.monthFrom === "" ? this.defaultStartDate() : this.state.monthFrom}
                               monthTo={this.state.monthTo === "" ? this.defaultEndDate() : this.state.monthTo}
                               regionsSelected={this.state.regionsSelected}
                               formatsSelected={this.state.formatsSelected}
                               ownersSelected={this.state.ownersSelected}

                               user={this.props.user}
                               {...this.props}/>
                    )}
                />
            </>
        );
    }
}

export default withStyles(styles)(Graphs);
