import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { MoreHoriz } from '@material-ui/icons/';
import './pagination.scss';

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className="pagination-action">
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        className="p0"
      >
        <img
          className='pagination-action__double-arrow'
          src='/images/icons/pagination-first.png'
          alt='First Page'
         />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0} 
        className="p0"
        >
        <img
        className='pagination-action__arrow'
        src='/images/icons/pagination-prew.png'
        alt='Previous Page'
        />
      </IconButton>
      {page === 0 ?
        ''
        :
        <span
          color='default'
          onClick={handleBackButtonClick}
        >
          {page}
        </span>}
      <span
        style={{color: 'grey', cursor: 'default'}}
        disabled={true}>
        {page + 1}
      </span>
      {Math.max(0, Math.ceil(count / rowsPerPage)) <= 1 ?
        ''
        :
      page === Math.max(0, Math.ceil(count / rowsPerPage) - 1) ? '' :
        <span
          color='default'
          onClick={handleNextButtonClick}>
          {page + 2}
        </span>}
      {Math.max(0, Math.ceil(count / rowsPerPage) - 1) <= 1 ?
        ''
        :
        page === Math.max(0, Math.ceil(count / rowsPerPage) - 1) ? '' :
          <IconButton disabled={true} className='pagination-action__more-horiz'>
            <MoreHoriz />
          </IconButton>
      }
      {Math.max(0, Math.ceil(count / rowsPerPage) - 1) <= 1 ?
        ''
        :
        page === Math.max(0, Math.ceil(count / rowsPerPage) - 1) ? '' :
          <span
            color='default'
            onClick={handleLastPageButtonClick}>
              {Math.max(0, Math.ceil(count / rowsPerPage))}
          </span>}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={Math.max(0, Math.ceil(count / rowsPerPage) - 1) === page} 
        className="p0"
        >
        <img
          className='pagination-action__arrow'
          src='/images/icons/pagination-next.png'
          alt='Next Page'
          />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={Math.max(0, Math.ceil(count / rowsPerPage) - 1) === page}
        className="p0"
        >
        <img
          className='pagination-action__double-arrow'
          src='/images/icons/pagination-last.png'
          alt='Last Page'
          />
      </IconButton>
    </div>
  );
}
