import React from "react";
import classNames from "classnames";
import { Field } from "react-final-form";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {},
  label: {
    textAlign: "left",
  },
  error: {
    color: "red",
  },
};

const GIZRadioGroup = ({
  classes,
  name,
  label,
  values,
  validator = null,
  disabled = false,
  className,
}) => {
  return (
    <FormControl
      component="fieldset"
      className={classNames(classes.root, className)}
    >
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <RadioGroup name={name} aria-label={label} row>
        {values.map(({ label, value }) => (
          <FormControlLabel
            key={label}
            label={label}
            value={value}
            control={
              <Field
                name={name}
                type="radio"
                validate={validator}
                render={({ input, meta }) => (
                  <Radio
                    color="primary"
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    checked={input.checked}
                    className={classNames({
                      [classes.error]:
                        meta.invalid && (meta.touched || meta.visited),
                    })}
                  />
                )}
              />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(GIZRadioGroup);
