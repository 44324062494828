import { MapLayer, withLeaflet } from "react-leaflet";
import L from 'leaflet';
import { isEqual } from 'lodash';
import fetchData from '../../utils/fetch';

const colors = [
  "#f44236",
  "#ea1e63",
  "#9c28b1",
  "#673bb7",
  "#3f51b5",
  "#2196f3",
  "#69c5ef",
  "#04adc3",
  "#009788",
  "#3fc244",
  "#99c24a",
  "#d8e64b",
  "#fae421",
  "#fec107",
  "#ff9700",
  "#b7a1ff",
  "#fb6231",
  "#9e9e9e",
  "#607d8b",
  "#34e2d3",
  "#c1114d",
  "#4d0759",
  "#1833cb",
  "#8449ae",
  "#357f38",
  "#d5c963",
  "#d48006",
  "#fe5722",
  "#327799",
];

class HromadaLayer extends MapLayer {
  componentWillReceiveProps({ yearFilter,showOblastBorders }) {
    if (!isEqual(yearFilter, this.props.yearFilter)) {
      this.loadMapLayer(yearFilter, showOblastBorders);
    }

    if(this.regionsLayer && !isEqual(showOblastBorders, this.props.showOblastBorders))
    {
      this.regionsLayer.setStyle({stroke:showOblastBorders})
    }
  }

  state = {
    areasData: null,
    regionsData: null
  }

  createLeafletElement() {
    return null;
  }

  async getAreas() {
    let regionsData = await fetchData('get', '/map/regions')
    let areasData = await fetchData('get', '/map/communities')

    this.setState(
      {
        regionsData: regionsData[0],
        areasData: areasData[0]
      }, ()=>{
        this.loadMapLayer()
    })
  }

  componentDidMount() {
    this.getAreas()
  }

  componentWillUnmount() {
    if (this.overLayer) { this.props.leaflet.map.removeLayer(this.overLayer); }
    if (this.regionsLayer) { this.props.leaflet.map.removeLayer(this.regionsLayer); }
  }

  onEachFeature = (feature, layer) => {
    if (!this.props.yearFilter[parseInt(feature.properties.year)]){
      return;
    }
    layer.on('mouseover', function (_) {
      layer.setStyle({ weight: 3 });
    })
    // TODO: apply current lang
    const isEng = true
    const props = feature.properties;
    const [ centerX, centerY ] = props.visualCenterLatLong;
    const rows = [
      '<b>Name of centre:</b></br> ' + (isEng ? props.centerEn : props.center),
      '<b>Number of settlements:</b> ' + props.counsilSize,
      '<b>Population:</b> ' + props.population.toLocaleString(),
      '<b>Area of the AH:</b> ' + props.square,
    ]
    const title = props[isEng ? "nameEn" : "nameUa"];
    let popupRow = `<h4>${title}</h4>${rows.join('<br/>')}<br/><br/><a target="_blank" href="/communities/view/${props.id}">More information →</a>`;

    layer._leaflet_id = 1000000 + props.id
    layer.bindTooltip(title)

    layer.on('click', () => {

      if (layer.isPopupOpen()){
        layer.closePopup()
        layer.unbindPopup()
        return;
      }

      let tempPopupContent = popupRow

      const statData = this.props.preparePopupData(props.id)
      if (statData){
        tempPopupContent += '<br/><br/>' + statData
      }

      this.props.leaflet.map.setView([centerY, centerX])
      let popup = L.popup({autoClose: false, closeOnClick: false, autoPan: false})
        .setLatLng([centerX, centerY])
        .setContent(tempPopupContent)
        .openOn(this.props.leaflet.map)

      layer.bindPopup(popup)
      layer.openPopup()
    })
    layer.on('popupclose', (e)=> {
      layer.closeTooltip()
      layer.unbindPopup()
    })
    layer.on("mouseout", function (e) {
      layer.setStyle({ weight: 0.5 });
    });

  }

  onEachRegionFeature = (feature, layer) => {
    layer.on('mouseover', function (_) {
      layer.setStyle({ weight: 3 });
    })
    layer.on("mouseout", function (e) {
      layer.setStyle({ weight: 0.5 });
    });

  }

  loadMapLayer(yearFilter = this.props.yearFilter, showOblastBorders = this.props.showOblastBorders) {
    const map = this.props.leaflet.map
    const areasData = this.state.areasData
    const regionsData = this.state.regionsData

    if (regionsData){
      this.regionsLayer = L.geoJson(regionsData, {
        onEachFeature: this.onEachRegionFeature,
        style: function(feature){
          return {
            stroke: showOblastBorders,
            weight: 0.5,
            color: '#999999',
            fillColor: 'transparent'
          }
        }
      }).addTo(map)
    }

    if (areasData){
      const legendData = [];
      const yearColors = {};
      const curYear = new Date().getFullYear();
      for(let year = 2015; year<=curYear; year++){
        let color = colors[year % colors.length]
        yearColors[year] = color
        legendData.push([color, year])
      }

      this.overLayer = L.geoJson(areasData, {
        onEachFeature: this.onEachFeature,
        style: function(feature){
          const year = parseInt(feature.properties.year)
          const isHidden = !yearFilter[year]
          return {
            stroke: true,
            weight: 0.5,
            color: (isHidden ? 'transparent' : '#999999'),
            fillColor: (isHidden ? 'transparent' : yearColors[year]),
            fillOpacity: (isHidden ? 0 : 0.5)
          }
        }
      }).addTo(map)

      const legendTitle = 'Year of amalgamation'
      this.props.onLoad({ legendTitle, legendData, areasData, baseLayer: this.overLayer })
    }
  }
}

export default withLeaflet(HromadaLayer);
