import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Dialog ,
  InputLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import fetchMultiPartData from "../../utils/fetchMultiPart";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { I18n } from "../../i18n/";
import { isEmpty } from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { each, filter } from "lodash";
import CheckboxWithLabel from "../../components/checkbox-with-label";
import classNames from "classnames";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "94%",
    margin: "30px 3%",
    "& .required": {
      background: "red",
    },
  },

  textField: {
    width: "100%",
    marginBottom: "20px",
    "&.error-field fieldset": {
      borderLeft: "1px solid #fe8368",
      borderLeftWidth: "6px",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      marginBottom: "0",
    },
  },
  buttonCancel: {
    marginRight: "20px",
  },
  select: {
    marginBottom: "20px",
    minWidth: "100%",
    "& > div": {
      background: "#f7f9fb",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
    "&.error-field > div": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
});

const langKeys = [
  { label: "English", value: "en" },
  { label: "Українська", value: "ua" },
];

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const required = (value) => (value ? undefined : "Required");

class ItemEdit extends React.Component {
  state = {
    open: true,
    roles: [],
    selectedRoles: [],
    thematicModulesList: [],
    selectedThematicModules: [],
    errorLang: false,
    errorRoles: false,
  };

  componentDidMount() {
    this.getData();
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to="/user" />;
    }
  };

  async getData() {
    let roles = await fetchData("get", "/users/authorities?size=1000");
    roles = roles[0];
    const rolesList = [];

    each(roles, (item) => {
      rolesList.push({ value: item, label: item });
    });

    let thematicModules = await fetchData("get", "/thematic_modules?size=1000");
    const thematicModulesListBuf = [];
    const thematicModulesList = [
      { label: I18n[this.props.lang].events.clusterA, options: [] },
      { label: I18n[this.props.lang].events.clusterB, options: [] },
      { label: I18n[this.props.lang].events.clusterC, options: [] },
    ];
    each(thematicModules[0], (item) => {
      thematicModulesListBuf.push({
        value: item.id.toString(),
        label: this.props.lang === "Eng" ? item.nameEn : item.nameUa,
        cluster: item.cluster,
      });
    });
    each(thematicModulesListBuf, (item, index) => {
      let clusterPos = null;
      switch (item.cluster) {
        case "a":
          clusterPos = 0;
          break;
        case "b":
          clusterPos = 1;
          break;
        case "c":
          clusterPos = 2;
          break;
        default:
          break;
      }
      if (clusterPos !== null) {
        thematicModulesList[clusterPos].options.push(item);
      }
    });

    const stateOpts = {
      loading: false,
      roles: rolesList,
      selectedRoles: [],
      thematicModulesList: thematicModulesList,
      selectedThematicModules: [],
    };

    const item = this.props.item;
    if (item) {
      stateOpts.selectedRoles = filter(rolesList, (e) => {
        return (
          item.authorities &&
          !!item.authorities.find((opt) => {
            return opt == e.value;
          })
        );
      });
      stateOpts.selectedThematicModules = filter(
        thematicModulesListBuf,
        (e) => {
          return (
            item.thematicModules &&
            !!item.thematicModules.find((opt) => {
              return opt.id == e.value;
            })
          );
        }
      );
    } else {
      stateOpts.errorLang = true;
      stateOpts.errorRoles = true;
    }
    this.setState(stateOpts);
  }

  handleRolesChange = (selectedOption) => {
    this.setState({ selectedRoles: selectedOption });
  };

  handleThematicModulesChange = (selectedOption) => {
    this.setState({ selectedThematicModules: selectedOption });
  };

  saveItem = async (values) => {
    var data = new FormData();
    const errorOpts = {};

    for (let name in values) {
      let value = values[name];
      data.append(name, value);
      if (name === "langKey") {
        value = value.value || this.props.item.langKey;
        data.set("langKey", value);
        if (!value) {
          errorOpts.errorLang = true;
        }
      }
    }

    const roles = this.state.selectedRoles.map((item) => item.value);
    data.set("role_ids", roles);

    if (!roles.length) {
      errorOpts.errorRoles = true;
    }

    if (!isEmpty(errorOpts)) {
      this.setState(errorOpts);
      return;
    }

    data.delete("thematicModules");
    if (this.state.selectedThematicModules) {
      const thematicModulesIds = this.state.selectedThematicModules.map(
        (item) => parseInt(item.value)
      );
      data.set("thematicModuleIds", JSON.stringify(thematicModulesIds));
    }

    const result = await fetchMultiPartData(
      values.id ? "put" : "post",
      `/users${values.id ? "/" + values.id : ""}`,
      data
    );
    if (result.error) {
      alert(result.error);
    } else {
      this.setState({ open: false });
      this.props.handleItemSave();
    }
  };

  handleDropdownChange = (option) => {
    this.setState({ [option]: false });
  };

  onSubmit = async (values) => {
    await sleep(300);
    this.saveItem(values);
  };

  render() {
    const { classes, item, lang } = this.props;
    const {
      roles,
      selectedRoles,
      thematicModulesList,
      selectedThematicModules,
      errorLang,
      errorRoles,
    } = this.state;
    return (
      <>
        {this.renderRedirect()}

        <Dialog
            aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
          fullScreen
        >
          <div className="modal">
            <a className="close" onClick={this.handleClose}>
              <Close />
            </a>

            <Grid container spacing={24} alignItems="center">
              <Grid item xs={6}>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="h2"
                  style={{ color: "#fdbb2d" }}
                >
                  {item
                    ? `${I18n[lang].user.edit} ${item.id}`
                    : I18n[lang].user.create}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.data}>
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="login" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label={I18n[lang].user.login}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="name" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label={I18n[lang].user.name}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="email" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label={I18n[lang].user.email}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    {false && (
                      <Field name="activated">
                        {({ input }) => (
                          <CheckboxWithLabel
                            label={I18n[lang].user.activated}
                            value="true"
                            input={input}
                            initialCheck={item && item.activated}
                          />
                        )}
                      </Field>
                    )}

                    <Field name="langKey" validate={required}>
                      {({ input, meta }) => (
                        <>
                          <InputLabel
                            className={classNames(classes.label, {
                              "error-field": errorLang,
                            })}
                          >
                            {I18n[lang].user.lang_key}
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classNames(classes.select, {
                              "error-field": errorLang,
                            })}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={langKeys}
                            onMenuOpen={() =>
                              this.handleDropdownChange("errorLang")
                            }
                            {...input}
                            value={langKeys.find(
                              (option) => option.value === input.value
                            )}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="roleIds">
                      {({ input }) => (
                        <>
                          <InputLabel
                            className={classNames(classes.label, {
                              "error-field": errorRoles,
                            })}
                          >
                            {I18n[lang].user.profiles}
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classNames(classes.select, {
                              "error-field": errorRoles,
                            })}
                            closeMenuOnSelect={false}
                            components={makeAnimated()}
                            options={roles}
                            onMenuOpen={() =>
                              this.handleDropdownChange("errorRoles")
                            }
                            isMulti
                            {...input}
                            onChange={this.handleRolesChange}
                            value={selectedRoles}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="thematicModules">
                      {({ input }) => (
                        <>
                          <InputLabel>
                            {I18n[lang].events.thematicModules}
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classes.select}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={thematicModulesList}
                            isMulti
                            {...input}
                            onChange={this.handleThematicModulesChange}
                            value={selectedThematicModules}
                          />
                        </>
                      )}
                    </Field>

                    <Field name="password" validate={!item && required}>
                      {({ input, meta }) => (
                        <TextField
                          type="password"
                          label={I18n[lang].user.password}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": !item && meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field
                      name="passwordConfirmation"
                      validate={!item && required}
                    >
                      {({ input, meta }) => (
                        <TextField
                          type="password"
                          label={I18n[lang].user.passwordConfirm}
                          margin="normal"
                          className={classNames("text-field", {
                            "error-field": !item && meta.error && meta.touched,
                          })}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Dialog >
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
