import metaFetch from './metaFetch';

/**
 * @deprecated use DataFetcher instead
 */
export default async function fetchMultiPartData(method, endpoint, body, onSuccess) {
  return metaFetch(endpoint, {
    method,
    body: body
  }, (result, json) => {
    if (typeof onSuccess == 'function') { onSuccess(result, json) }
    return json;
  })
}
