import metaFetch from './metaFetch';

/**
 * @deprecated use DataFetcher instead
 */
export default async function fetchBase64(endpoint, callback) {
  return metaFetch(endpoint, { method: 'GET', responseType: 'blob' }, (result, blob) => {
    let filename = result.headers.get('content-disposition');
    if (filename) {
      var reader = new FileReader();
      reader.onload = function () {
        var dataUrl = reader.result;
        callback(dataUrl);
      };
      reader.readAsDataURL(blob);
    }
    return filename;
  });
}
