import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import fetchData from '../../../utils/fetch';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { I18n } from '../../../i18n/';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  MuiPickersUtilsProvider,
  InlineDatePicker
} from "material-ui-pickers";
import moment from 'moment';
import 'polyfill-array-includes';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
    '& #picker-popover > div':{
      opacity: 0,
    },
  },
  '& #picker-popover > div': {
    opacity: 0,
  },
  '& #picker-popover div:first-child': {
    opacity: 0,
  },
  ' #picker-popover div:first-child': {
    opacity: 0,
  },
  ' #picker-popover > div': {
    opacity: 0,
  },
  date:{
    color: '#6495ed',
    fontSize: '24pt',
    fontWeight: '200',
    letterSpacing: '1.2px',
  },
  dFlex: {
    display: 'flex',
  },
  dataIcon: {
    position: 'relative',
    zIndex: '2',
    background: '#add8e6',
    width: '51px',
    height: '45px',
    borderRadius: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
  },
  dataPick: {
    width: 'calc(100% - 230px)',
    '& input':{
      top: '-45px',
      left: '45px',
      width: '84px',
      height: '24px',
      position: 'absolute',
      zIndex: '1',
    },
  },
  textField: {
    '& input': {
      width: '70px',
      position: 'absolute',
      top: '5px',
    }
  },
  modal:{
    position: 'absolute',
    width: theme.spacing.unit * 150,
    backgroundColor: '#f7f9fb',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    width: '760px',
    height: '440px',
    overflowY: 'auto',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    borderRadius: '10px',
    '&:focus':{
      outline: '5px solid transparent'
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '37px',
      textTransform: 'uppercase',
      color: '#88ADF0',
      marginBottom: '20px'
    },
    '& .text-area': {
      '& label': {
        transform: 'translate(0, 0) scale(1)',
        marginBottom: '10px',
        fontSize: '24pt',
        color: '#6495ed',
        letterSpacing: '1.2px',
      }
    },
    '& .btn': {
      display: 'block',
      width: '45px',
      height: '45px',
      background: '#9BD9C8 no-repeat center center / 23px 22px',
      borderRadius: '6px',
      '&.btn-calendar': {
        marginTop: '10px',
        backgroundImage: 'url(/images/icons/calendar.svg)'
      }
    }
  },
  buttons: {
    textAlign: 'right',
    position: 'absolute',
    bottom: '20px',
    width: 'calc(100% - 40px)',
    '& .button-submit': {
      marginRight: '0',
      height: '47px',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '20px',
    backgroundColor: '#E1EDFB'
  },
  buttonCancel: {
    marginRight: '20px'
  }
});

const TODAY = new Date();
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
const required = value => (value ? undefined : "Required");

class Milestone extends React.Component {
  state = {
    open: true,
    item: null,
    isVisibleDataPicker: false,
  }

  dataPickerFlag = React.createRef();
  
  handleToggleCalendar = event => {
    this.dataPickerFlag.current.open(event);
    this.setState({ isVisibleDataPicker: true });
  }
  componentDidMount() {
    if (this.props.item){
      const item = cloneDeep(this.props.item)
      item.date = item.date.match(/\d{4}-\d{2}/)[0]
      this.setState({ item: item })
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/indicator/${this.props.indicatorId}`} />
    }
  }

  saveItem = async (values) => {
    const data = {}
    data.indicatorId = this.props.indicatorId;
    data.id = values.id
    data.date = values.date + '-01'
    data.description = values.description || '';

    if (data.id) {
      await fetchData('put', `/measurements/milestones/${data.id}`, data);
    } else {
      await fetchData('post', '/measurements/milestones', data);
    }
    this.setState({ open: false });

    this.props.handleItemSave();
  }

  onSubmit = async values => {
    await sleep(300)
    this.saveItem(values);
  }

  render() {
      const { classes, lang } = this.props;
      const { item } = this.state;
      return (
        <>
          {this.renderRedirect()}

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className={classes.modal}>
              <a className="close" onClick={this.handleClose}>
                <Close/>
              </a>
              <Typography gutterBottom variant="h2" component="h2">
                {item ? 'Edit' : 'Add'} Milestone
              </Typography>

              <Typography className={classes.data}>
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={item}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={24} alignItems='flex-start'>
                        <Grid item xs={3}>
                            <Field name="date">
                            {({ input, meta }) =>
                              <div>
                                <div>
                                  <div className={classes.date}> Date </div>
                                  <div className={classes.dataIcon} onClick={this.handleToggleCalendar}>
                                    <img src="/images/icons/calendar.svg" alt="cal" style={{ color: '#e6feff', width: 32 }}/>
                                  </div>
                                </div>
                                <div className={classes.dataPick} style={{ display: this.state.isVisibleDataPicker ? 'block' : 'none' }}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <InlineDatePicker
                                      className={classNames('text-field', classes.textField, classes.dateField, 'inline-field', { 'w30': (!input.value) }, { 'error-field': (meta.error && meta.touched) })}
                                      {...input}
                                      value={input.value || TODAY}
                                      ref={this.dataPickerFlag}
                                      labelFunc={date => (date ? moment(date).format('MM.YYYY') : "")}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                              </div>
                            }

                        </Field>
                        </Grid>
                        <Grid item xs={9}>
                          <Field name="description">
                            {({ input }) =>
                              <TextField
                                label='Milestone'
                                multiline
                                rows="7"
                                rowsMax="7"
                                className='text-area'
                                variant="outlined"
                                {...input}
                              />}
                          </Field>
                        </Grid>
                      </Grid>
                      <Grid container spacing={24} alignItems='center'>
                        <Grid item xs={12} className={classes.buttons}>
                          <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                            <i></i>
                            {I18n[lang].common.cancel}
                          </Button>
                          <Button variant="contained" className='button-submit' type="submit" color="primary">
                            <i></i>
                            {I18n[lang].common.save}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Typography>
            </div>
          </Modal>
        </>
      );
  }
}

export default withRoot(withStyles(styles)(Milestone));
