import React from 'react';
import {
    Grid,
    Button,
    Paper,
    Typography,
    Hidden, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog,
} from '@material-ui/core';
import fetchData from '../../../utils/fetch';
import fetchMultiPartData from '../../../utils/fetchMultiPart';
import downloadFile from '../../../utils/fetchFile'
import {Edit, Add, Close, ShowChart, DeleteForever} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import {I18n} from '../../../i18n/';
import {isString} from 'lodash';
import GIZButton from "../../../components/GIZButton";

import {Link, Route, Redirect} from 'react-router-dom';
import classNames from 'classnames';

import Milestone from './milestone'
import DataCollection from './data-collection'
import KeyData from './key-data'
import MilestoneData from './milestone-data'
import {stateLabel, milestoneDate, metricValue} from '../utils';
import FileUploadProgress from '../../../components/file-upload-progress';
import RegionData from "./regionData";
import EventService from "../../../services/EventService";
import IndicatorRegionsService from "../../../services/IndicatorRegionService";
import {ResponsiveTable} from "../../../components";
import styles from "./styles";

const lang = 'Eng'

const Title = (props) => {
    const title = props.title;
    const size = props.size;
    const Component = props.component;

    return (
        <Grid item xs={size}>
            <Component> {title} </Component>
        </Grid>
    )
}

class IndicatorDetails extends React.Component {
    state = {
        loading: true,
        item: null,
        fileInput: null,
        deleteItem: false,
        currentMilestone: {},
        fileUploadModal: false,
        fileUploadStatus: '',
        print_info: true
    };

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const itemId = window.location.href.match(/indicator\/(\d+)/)[1]
        const item = (await fetchData('get', `/indicators/${itemId}`))[0];

        if (!item || item.error) {
            this.setState({loading: false});
        } else {
            this.setState({loading: false, item: item});
        }
    }

    handleItemSave = () => {
        this.getData()
    }

    handleDownload = (file) => {
        return async (e) => {
            e.preventDefault()
            downloadFile(file);
        }
    }

    openMilestone = ({match: {params: {indicator_id, id}}}) => {
        const milestone = id == 'new' ? null : this.state.item.milestones.find(x => x.id == id)
        return <Milestone lang={lang} handleItemSave={this.handleItemSave} indicatorId={indicator_id}
                          item={milestone}/>;
    }

    async deleteMilestone(milestone) {
        await fetchData('delete', `/measurements/milestones/${milestone.id}`);
        this.getData();
        this.setState({deleteItem: false});
    }

    showDeleteModal = (milestone) => {
        this.setState({deleteItem: true, currentMilestone: milestone});
    }

    closeModal = () => {
        this.setState({deleteItem: false});
    }

    openDataCollection = ({match: {params: {indicator_id}}}) => {
        return <DataCollection lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item}/>;
    }

    openKeyData = ({match: {params: {indicator_id}}}) => {
        return <KeyData lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item}/>;
    }

    openMilestoneData = ({match: {params: {indicator_id, id}}}) => {
        const milestone = this.state.item.milestones.find(x => x.id == id)
        return <MilestoneData lang={lang} handleItemSave={this.handleItemSave} indicator={this.state.item}
                              item={milestone} user={this.props.user}/>;
    }

    measurementValueWithDescription = (value, index) => {
        let description = this.state.item['measurementDescription' + (index + 1)]
        description = description.replace(/^\%\s/, '')
        return `${value} ${description}`
    }

    renderMilestoneMetrics = (milestone) => {
        const indicator = this.state.item;
        const metricArray = Array(indicator.measurementCount).fill(0);
        return metricArray.map((el, i) => {
            const num = i + 1;
            let value = milestone[`quantitiveValue${num}`]
            if ((!value || isNaN(value)) && indicator.isAutomated) {
                value = milestone[`autoQuantitiveValue${num}`]
            }
            value = metricValue(indicator, value, i);
            return (
                <span className='metric-value' key={i}>{`${num}) ${value}`}</span>
            )
        })
    }

    handlePDFDownload = async fileName => {
        if (this.state[fileName]) {
            const withLang = this.props.lang ? '?lang=' + this.props.lang : '';
            this.setState({[fileName]: false});
            await downloadFile(`/indicators/${this.state.item.id}/${fileName}.pdf${withLang}`);
            this.setState({[fileName]: true});
        }
    }

    unitName = (unit) => {
        return unit.name || unit.login.toUpperCase()
    }

    fileInput = null

    clickFileUpload = (item) => {
        return async (event) => {
            event.preventDefault()
            this.fileInputs[item.id].click()
        }
    }

    handleFileUpload = (item) => {
        return async (event) => {
            this.setState({fileUploadModal: true, fileUploadStatus: 'loading'})
            const file = event.target.files[0]
            if (file.size < (20 * 1024 * 1024)) {
                var data = new FormData();
                data.set('dataFile', file)
                await fetchMultiPartData('put', `/indicators/${item.id}/data_upload`, data);
                this.setState({fileUploadStatus: 'success'})
                this.getData();
            }
        }
    }

    render() {
        const {classes, user} = this.props;
        const {item, loading} = this.state;
        if (loading) return '';
        if (!item || item.readOnly && !item.restrictedView) return (<Redirect to='/indicators'/>);

        const asAdmin = user && user.isAdmin
        const asEditor = asAdmin || user && !item.restrictedView;
        const asGuest = user && (user.authorities.indexOf('ROLE_GUEST') >= 0)

        return (
            <>
                <div className={classNames(classes.root, classes.individualPage)}>

                    <Grid container spacing={24}>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={12} md={8}>
                                        <Typography
                                            variant="h3"> {asEditor && item.fake ? 'Dummy ' : ''}{item.code}: {item.jhiIndicator}</Typography>
                                        <Typography variant="h6"> Results: {item.result}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="h5"> Lead Unit:<br/>{this.unitName(item.leadUnit)}
                                        </Typography>
                                        <Typography variant="h6">Supporting Units:<br/>
                                            {item.supportUnits.map(unit => this.unitName(unit)).join(', ')}
                                        </Typography>
                                    </Grid>
                                    <Hidden only={['xs', 'sm']}>
                                        <Grid item xs={6} md={1}>
                                            {!asGuest && (
                                                <Link to={`/indicator-graphs/${item.id}`}>
                                                    <Button variant="contained" className={classes.buttonGraphs}>
                                                        <i></i>
                                                        Graphs
                                                    </Button>
                                                </Link>
                                            )}
                                            <Link to='#'
                                                  className={classNames(classes.button, {'disabled': !this.state.print_info})}
                                                  onClick={() => this.handlePDFDownload('print_info')}>
                                                <Button variant="contained" className={classes.buttonPrint}>
                                                    <i/>
                                                    Print
                                                </Button>
                                            </Link>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={5}>

                            <Paper className={classes.paper}>
                                <Grid container xs={12} spacing={8}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">Baseline value(s)</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <ul>
                                            {item.baselineValues.map((baselineValue, i) => {
                                                return (
                                                    <li key={i}>{this.measurementValueWithDescription(baselineValue, i)}</li>
                                                )
                                            })}
                                        </ul>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">Measurement</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <Typography variant="p">{item.keyDatum.measurement}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">Uploaded files</Typography>
                                    </Grid>
                                    <Grid item
                                          xs={12} md={8}
                                          style={{
                                              display: 'flex',
                                              flexWrap: 'wrap'
                                          }}
                                    >
                                        {item.keyDatum.files.map((file, i) => {
                                            return (
                                                <Link
                                                    key={i}
                                                    title={`Download '${file.name}'`}
                                                    to='#' className={classes.buttonWord}
                                                    onClick={this.handleDownload(file.url)}
                                                />
                                            )
                                        })}
                                    </Grid>

                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">Target value(s)</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <ul>
                                            {item.targetValues.map((targetValue, i) => {
                                                return (
                                                    <li key={i}>{this.measurementValueWithDescription(targetValue, i)}</li>
                                                )
                                            })}
                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <Typography variant="h6">Explanatory notes</Typography>

                                    </Grid>

                                    <Grid item xs={12} md={8}>
                                        <Typography variant="p">{item.keyDatum.explanatoryNotes}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Paper className={classes.paper}>
                                <Grid container xs={12} spacing={8}>
                                    <Grid item xs={12}>
                                        {asEditor && (
                                            <Link title="Edit Key Data" to={`/indicator/${item.id}/key-data`}>
                                                <Button variant="contained" color="primary" className="btn-edit">
                                                    <img
                                                        src="/images/icons/icon-action-edit.svg"
                                                        alt="edit"
                                                        width="17"
                                                    />
                                                    Edit
                                                </Button>
                                            </Link>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Frequency of measurement</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="p">{item.keyDatum.measurementFrequency}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="h6">External support</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="p">{item.keyDatum.externalSupport}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Indicator definition</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography variant="p">{item.keyDatum.indicatorDefinition}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container xs={12} spacing={8}>
                                    <Grid item xs={12} md={11}>
                                        <Typography variant="h3">DATA COLLECTION INFORMATION</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={1}>
                                        {asEditor && (
                                            <Link title="Edit Data Collection Information"
                                                  to={`/indicator/${item.id}/data-collection`}>
                                                <Button variant="contained" color="primary" className="btn-edit">
                                                    <i></i>
                                                    Edit
                                                </Button>
                                            </Link>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Sources/Methods of data collection</Typography>
                                        <Typography variant="p">{item.sourcesAndMethods}</Typography>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Validity/Data limitation</Typography>
                                        <Typography variant="p">{item.validityAndLimitation}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3">Data collection process</Typography>
                                    </Grid>


                                    <Grid item xs={12}>
                                        <ResponsiveTable lang={this.props.lang} i18n={I18n[this.props.lang].events}
                                                         classes={classes}
                                                         items={item.processSteps}
                                                         columns={[
                                                             {title: "No", displayFunc: (row, index) => (index + 1)},
                                                             {id: "description", title: "Process step"},
                                                             {id: "responsible", title: "Responsible"},
                                                             {id: "dueDate", title: "Due date"}
                                                         ]}/>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Partner involment</Typography>
                                        <Typography variant="hp">{item.partnerInvolvment}</Typography>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Uploaded files</Typography>
                                        <Typography variant="p">
                                            {item.files.map((file, i) =>
                                                <Link title={`Download '${file.name}'`}
                                                      to='#'
                                                      onClick={this.handleDownload(file.url)}
                                                      key={i}
                                                />
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            {/*<Paper className={classes.paper}>*/}
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <Typography variant="h3">MEASUREMENT DATA</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    {(asAdmin || asGuest) && (
                                        <Link to={`/indicator/${item.id}/milestone/new`}>
                                            <GIZButton color="primary" icon="Add" isRound>
                                                Add Milestone
                                            </GIZButton>
                                        </Link>)}

                                    {item.isImportable && (
                                        <>
                                            <a href="#"

                                               onClick={event => {
                                                   event.preventDefault();
                                                   this.fileInput.click()
                                               }}>
                                                Upload datafile of indicator
                                                <i></i>
                                            </a>
                                            <input
                                                type="file"
                                                name="dataFileInput"
                                                ref={
                                                    input => {
                                                        if (!input) return;
                                                        input.addEventListener('change', this.handleFileUpload(item));
                                                        this.fileInput = input;
                                                    }
                                                }
                                                accept='.xlsx'
                                                style={{'display': 'none'}}
                                            />
                                            {item.dataFileUrl && (
                                                <a
                                                    href="#"

                                                    onClick={this.handleDownload(item.dataFileUrl)}>
                                                    Download datafile of indicator
                                                    <i></i>
                                                </a>
                                            )}
                                        </>
                                    )}

                                </Grid>


                                <Grid item xs={12}>
                                    <ResponsiveTable lang={this.props.lang} i18n={I18n[this.props.lang].events}
                                                     classes={classes}
                                                     items={item.milestones}
                                                     columns={[
                                                         {title: "Date", displayFunc: milestoneDate},
                                                         {
                                                             title: "Status",
                                                             displayFunc: (milestone) => (<div
                                                                 className={classes.selector}> {stateLabel(milestone.status)}</div>)
                                                         },
                                                         {id: "description", title: "Milestone"},
                                                         {
                                                             title: "Quantitative measurement",
                                                             displayFunc: this.renderMilestoneMetrics
                                                         },
                                                         {id: "qualitiveMeasurement", title: "Qualitative measurement"},
                                                         {
                                                             title: "Uploaded files",
                                                             displayFunc: (milestone) => (<div style={{
                                                                 display: "flex",
                                                                 alignItems: "center",
                                                                 justifyContent: "space-evenly"
                                                             }}>
                                                                 {milestone.files.map((file, i) =>
                                                                     (<Link
                                                                         to='#'
                                                                         title={`Download '${file.name}'`}
                                                                         className={classes.buttonWord}
                                                                         onClick={this.handleDownload(file.url)}
                                                                         key={i}
                                                                     />))}
                                                             </div>)
                                                         },
                                                     ]}
                                                     renderActions={(milestone) => (
                                                         <>
                                                             {(asEditor || asAdmin) && (
                                                                 <Link
                                                                     title="Enter Milestone Data"
                                                                     to={`/indicator/${item.id}/milestone-data/${milestone.id}`}
                                                                 >
                                                                     <img src="/images/icons/data-icon.png" alt="data"
                                                                          width='34'/>
                                                                 </Link>
                                                             )}
                                                             {(asAdmin || asGuest) ? (
                                                                 <>
                                                                     <Link
                                                                         title="Edit Milestone"
                                                                         to={`/indicator/${item.id}/milestone/${milestone.id}`}
                                                                     >
                                                                         <img
                                                                             src="/images/icons/outline_edit_white_36dp.png"
                                                                             alt="edit"
                                                                             width='16'/>
                                                                     </Link>
                                                                     <a
                                                                         title="Delete Milestone"
                                                                         onClick={(event) => {
                                                                             event.preventDefault();
                                                                             //this.deleteMilestone(milestone);
                                                                             this.showDeleteModal(milestone);
                                                                         }}
                                                                         href="#"
                                                                     >
                                                                         <Close/>
                                                                     </a>
                                                                 </>
                                                             ) : (
                                                                 // just to make first link blue (because of .action a:last-child is filled as delete)
                                                                 <span></span>
                                                             )}
                                                         </>)}
                                    />

                                </Grid>
                            </Grid>
                            {/*</Paper>*/}
                        </Grid>


                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <div className={classNames(classes.regionData)}>
                                    <RegionData
                                        serviceClass={IndicatorRegionsService}
                                        itemId={item.id}
                                        lang={this.props.lang}
                                        isEditable={true}
                                        i18nCommon={I18n.Eng.common}
                                    />
                                </div>
                            </Paper>
                        </Grid>

                        {
                            this.state.fileUploadModal && (
                                <FileUploadProgress status={this.state.fileUploadStatus}/>
                            )
                        }
                    </Grid>
                    <Route path="/indicator/:indicator_id/milestone/:id" render={this.openMilestone}/>
                    <Route path="/indicator/:indicator_id/data-collection" render={this.openDataCollection}/>
                    <Route path="/indicator/:indicator_id/key-data" render={this.openKeyData}/>
                    <Route path="/indicator/:indicator_id/milestone-data/:id" render={this.openMilestoneData}/>
                </div>
                <Dialog
                    open={this.state.deleteItem}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Delete milestone?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <h3>Attention!</h3>
                            <p>Are you sure you want to delete this milestone?</p>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" className={classes.buttonSubmit} type="submit"
                                color="primary"
                                onClick={() => this.deleteMilestone(this.state.currentMilestone)}>
                            Yes
                        </Button>
                        <Button variant="contained" className={classes.buttonCancel}
                                onClick={this.closeModal}>
                            Cancel
                        </Button>

                    </DialogActions>

                </Dialog>
            </>
        )
            ;
    }
}

export default withRoot(withStyles(styles)(IndicatorDetails));
