import React, {Fragment} from "react";
import PropTypes from "prop-types";
import EditInline from "../../../components/dialog/editInline.js";
import {
    FormTextField,
    FormCheckboxField, FormNumberField,
} from "../../../components/dialog/formField";
import {Paper, Grid, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import styles from "../../../style/styles";

const RegionData = (props) => {
    const {i18n, i18nCommon, serviceClass} = {...props};
    const {classes, ...rest} = {i18n, ...props};

    return (
        <EditInline
            i18n={i18n}
            i18nCommon={i18nCommon}
            serviceClass={serviceClass}
            {...rest}
            render={(fieldProps) => (
                <Grid container xs="12" spacing="16">
                    <Grid item xs="12">  <Typography variant="h3">REGION DATA:</Typography></Grid>

                    {fieldProps.formState && fieldProps.formState.regions &&
                        fieldProps.formState.regions.map(region => (<Grid item xs={6} md={2}>
                            <FormNumberField label={region.name}egio
                                             value={region.value} {...fieldProps}
                                             isEditable={true}
                                             onChange={(e) => fieldProps.action({regionId: region.Id, value: e.currentTarget.value}, false)}
                            /></Grid>))
                    }
                </Grid>
            )}
        />
    );
};

RegionData.propTypes = {
    i18n: PropTypes.object.isRequired,
    serviceClass: PropTypes.any.isRequired,
};

export default withStyles(styles)(RegionData);
