import React from "react";
import classNames from "classnames";
import { Field } from "react-final-form";
import { InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Map, TileLayer, Marker } from "react-leaflet";
import AddressControl from "./AddressControl";

const styles = {
  root: {
    marginBottom: "20px",
  },
  label: {
    padding: 0,
    textAlign: "left",
  },
  map: {
    height: "300px",
  },
  error: {
    position: "relative",
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: "7px",
      backgroundColor: "#fe8368",
      zIndex: 1024,
    },
  },
};

export const GIZAddressField = withStyles(styles)(
  ({ classes, name, label, validator = null, className = null, zoom = 12 }) => {
    return (
      <Field name={name} validate={validator}>
        {({ input, meta }) => {
          const { value, onChange } = input;
          return (
            <div className={classNames(classes.root, className)}>
              <InputLabel className={classes.label}>{label}</InputLabel>
              <Map
                className={classNames(classes.map, {
                  [classes.error]:
                    meta.invalid &&
                    (meta.touched || (meta.visited && meta.dirty)),
                })}
                center={[50.4501, 30.5234]}
                zoom={zoom}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <AddressControl
                  onCoordinatesChange={(location, addressParts, address) =>
                    onChange({
                      location,
                      address,
                    })
                  }
                />

                <Marker
                  position={value?.location ?? [50.4501, 30.5234]}
                ></Marker>
              </Map>
            </div>
          );
        }}
      </Field>
    );
  }
);

export default GIZAddressField;
