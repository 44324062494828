import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph';
import {Settings} from "../../../config"
const styles = theme => ({
})

const datasetOptions = [{
  label: 'Number of Events',
  type: 'bar',
  data: [200, 185, 590, 621, 250, 400, 95],
  // borderDash: [10,5],
  fill: false,
  lineTension: 0,
  order: 2,
  pointStyle: 'rect',
  backgroundColor: '#fdbb2d',
  borderColor: '#fdbb2d',
  hoverBackgroundColor: '#fdbb2d',
  hoverBorderColor: '#fdbb2d',
  yAxisID: 'y-axis-female-percent'
}, {
  label: '% Female',
  type:'line',
  data: [51, 65, 40, 49, 60, 37, 40],
  fill: false,
  lineTension: 0,
  borderWidth: 5,
  pointRadius: 0,
  order: 1,
  pointStyle: 'line',
  borderColor: '#6495ed',
  backgroundColor: '#6495ed',// 65,115,90
  pointBorderColor: '#6495ed',
  pointBackgroundColor: '#6495ed',
  pointHoverBackgroundColor: '#6495ed',
  pointHoverBorderColor: '#6495ed',
  yAxisID: 'y-axis-1'
}]

const options = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: false
        },
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-female-percent',
        ticks: {
          precision: 0,
          beginAtZero: true
        },
        gridLines: {
          display: false
        },
        labels: {
          show: true
        }
      },
      {
        type: 'linear',
        display: true,
        position: 'right',
        id: 'y-axis-1',
        gridLines: {
          display: true,
          drawBorder: false
        },
        labels: {
          show: true
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
          callback: (value, index) => (index % 2 === 0) ? value + '%' : ''
        },
      }
    ]
  }
};

const plugins = [{
  afterDatasetDraw: (chartInstance) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = chartInstance.scales.font;
    ctx.fillStyle = '#555'
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";


    chartInstance.data.datasets.forEach((dataset, i) => {
      const meta = chartInstance.controller.getDatasetMeta(i);

      meta.data.forEach((bar, index) => {
          const data = dataset.data[index];
          if (i === 1) {
            const shortData = data < 1 ? data.toFixed(1) : data.toFixed(0);
            const result = shortData % 1 === 0 ? Math.round(shortData) : shortData;
            return ctx.fillText(result + '%', bar._model.x , bar._model.y + 35);
          }
          else {
           ctx.fillText(Math.round(data).toString(), bar._model.x , bar._model.y - 5);
          }
      });
    })
  }
}];

const pluginsForPrint = [{
  afterDatasetDraw: (chartInstance) => {
    const ctx = chartInstance.chart.ctx;
    ctx.font = chartInstance.scales.font;
    ctx.fillStyle = '#555'
    ctx.textAlign = "center";
    ctx.textBaseline = "bottom";


    chartInstance.data.datasets.forEach((dataset, i) => {
      const meta = chartInstance.controller.getDatasetMeta(i);

      meta.data.forEach((bar, index) => {
        const data = dataset.data[index];
        if(i !== 1) {
          ctx.fillText(Math.round(data).toString(), bar._model.x , bar._model.y - 5);
        }
      });
    })
  }
}];

class GenderBalanceChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='bar'
        tableTitle={`Number of ${Settings.Tenant.ProgramName} events and gender balance of participants`}
        url='/graph/event_gender_balance'
        showLegend
        chartOptions={options}
        datasetOptions={datasetOptions}
        filter={this.props.filter}
        plugins={this.props.forPrint ? pluginsForPrint : plugins}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(GenderBalanceChart);
