import baseStyle from "../../../style/styles";
import {Breakpoints} from "../../../config.js"
import {config} from "../../../style/config";

const style = (theme) => {
    return {
        ...baseStyle(theme),

        table:
            {
                "& thead tr th":
                    {
                        fontSize: config.table.header.fontSize,
                        color: config.table.header.color,
                        fontWeight: config.table.header.fontWeight,
                    },
                "& tbody tr td":
                    {
                        fontSize: config.table.data.fontSize,
                        color: config.table.data.color,
                        fontWeight: config.table.data.fontWeight,
                        "&.actions": {
                            textAlign: "right",
                            "& a": {
                                minWidth: "52px",
                                height: "42px",
                                alignItems: "center",
                                justifyContent: "center",
                                "& i": {
                                    marginRight: "0",
                                },
                            },
                        },
                    }
            },

        buttonEdit: {
            "&:hover":
                {
                    backgroundColor: '#5486e0',
                }
        },

        buttonCreate: {
            "&:hover":
                {
                    backgroundColor: '#5486e0 !important',
                }
        },

        buttonUpload: {
            "&:hover":
                {
                    backgroundColor: '#9bcfe0 !important',
                }
        },

        buttonDownload: {
            "&:hover":
                {
                    backgroundColor: '#9bd9c8 !important',
                }
        },

        actions: {
            "& a:edit":
                {
                    backgroundColor: '#6495ed !important',
                    '&:hover': {
                        backgroundColor: '#5486e0 !important',
                    },
                },
            '& a.view': {
                backgroundColor: '#add8e6',
                '&:hover': {
                    backgroundColor: '#9bcfe0',
                },
            },
            '& a.delete': {
                backgroundColor: '#fe8368 !important',
                '&:hover': {
                    backgroundColor: '#f67a5f !important',
                },
            },
        },

        buttonGraphs: {
            height: 47,
            color: "#ffffff",
            backgroundColor: '#FCBA2B',
            border: '1px solid #FCBA2B',
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: '.75rem',
            fontWeight: '700',
            marginRight: '0',
            padding: '.5rem 1rem',
            marginTop: '23px',
            minWidth: '100px',
            borderRadius: '6px',
            '&:hover': {
                backgroundColor: '#f8d95d',
            },
            '& i': {
                display: 'inine-block',
                width: '16px',
                height: '16px',
                marginRight: '5px',
                background: 'url(/images/icons/header-dashboard.svg) no-repeat 0 0 / 16px 16px'
            },
            '& span': {
                fontSize: '12px',
                fontWeight: '500',
                letterSpacing: '1.2px',
            },
        },
        buttonPrint: {
            backgroundColor: '#778899',
            borderRadius: '6px',
            height: 47,
            color: "#ffffff",
            border: '1px solid #778899',
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: '.75rem',
            fontWeight: '700',
            marginRight: '0',
            padding: '.5rem 1rem',
            marginTop: '23px',
            minWidth: '100px',
            '&:hover': {
                backgroundColor: '#778899',
            },
            '& i': {
                display: 'inline-block',
                width: '16px',
                height: '16px',
                marginRight: '10px',
                background: 'url(/images/icons/print-icon.png) no-repeat 0 0 / 16px 16px'
            },
            '& span': {
                fontSize: '12px',
                fontWeight: '500',
                letterSpacing: '1.2px',
            },
        },
        buttonWord: {
            width: "45px",
            height: "45px",
            display: "block",
            borderRadius: "6px",
            background: "#c3bdd2 no-repeat center center",
            backgroundImage: "url(/images/icons/icon-word.png)",
            '&:hover': {
                backgroundColor: '#958DAE'
            },
            [theme.breakpoints.down(Breakpoints.md)]: {
                width: "32px",
                height: "32px",
            },
        },
        selector: {
            position: 'relative',
            '& .on': {
                backgroundColor: '#77D2B7'
            },
            '& .minor': {
                backgroundColor: '#DDD330'
            },
            '& .major': {
                backgroundColor: '#FCBA2B'
            },
            '& .off': {
                backgroundColor: '#FE8368'
            },
            '& .not': {
                backgroundColor: '#A7A9AC'
            },
            '& .selected-value': {
                textAlign: 'center',
                fontSize: '11px',
                color: "#ffffff",
                height: '47px',
                width: '70px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px',
                cursor: 'pointer',
                padding: 5,
                '&:hover + .list': {
                    display: 'block'
                },
                [theme.breakpoints.down(Breakpoints.md)]: {
                    height: "32px",
                },
            },
        },
    }
};
export default style;