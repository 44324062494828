import React, { useContext } from "react";
import { observer } from "mobx-react";
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './style';
import { StoreContext } from "../../../stores/context";

const HromadaLayerCustom = (props) => {
  const { classes, onChange, value } = props;
    const { userStore } = useContext(StoreContext);

  const RenderFilter = () => {

    const DATA = [
      { date: 2015, color: "#ff970080" },
      { date: 2016, color: "#b7a1ff80" },
      { date: 2017, color: "#fb623180" },
      { date: 2018, color: "#9e9e9e80" },
      { date: 2019, color: "#607d8b80" },
    ];

    if (!userStore.isFirstPhase) {
      DATA.push({ date: 2020, color: "#34e2d380" });
    }

    return (
      DATA.map((item, i) => {
        const isSelected = value[item.date];
        return (
          <div className={classNames('flex', classes.dateWrap)} onClick={() => onChange(item.date)} key={i}>
            <div style={{ backgroundColor: [isSelected ? item.color : 'transparent'] }} className={classes.colorBox} />
            <span> {item.date} </span>
          </div >
        )
      })
      );
  }

  return(
    <div className={classNames(classes.root, classes.yearsFilter )}>
      <h4 className='yearsFilter__title'>
        Year of Amalgamation
      </h4>
      <div className='yearsFilter_color'>
        <RenderFilter />
      </div>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(HromadaLayerCustom);
