import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import ItemEdit from "./item-edit";
import GoodPracticeView from "./view";
import { I18n } from "../../i18n";
import GoodPracticeService from "../../services/GoodPracticeService";

class GoodPractice extends React.Component {
  render() {
    const path = "good-practices";
    const i18nProps = {
      i18n: I18n[this.props.lang].goodPractice,
      i18nCommon: I18n[this.props.lang].common,
    };

    const regionNameField =
      this.props.lang === "Eng" ? "regionNameEn" : "regionNameUa";

    return (
      <IndexTable
        {...this.props}
        {...i18nProps}
        serviceClass={GoodPracticeService}
        path={path}
        hideButtonsOnMobile={true}
        columns={[
          { id: "id", title: "id" },
          { id: "title", title: "title" },
          { id: "ownerName", title: "owner" },
          { id: "shortDescription", title: "description" },
          { id: "initiativesCount", title: "sumOfInitiatives", center: true },
          { id: regionNameField, title: "region" },
        ]}
        onEditItem={(itemId, item, handleItemSave) => {
          return (
            <ItemEdit
              itemId={itemId}
              serviceClass={GoodPracticeService}
              indexPath={path}
              handleItemSave={handleItemSave}
              isEditable={true}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
        onViewItem={(itemId, item) => {
          return (
            <GoodPracticeView
              item={item}
              id={itemId}
              indexPath={path}
              isEditable={false}
              {...i18nProps}
              {...this.props}
            />
          );
        }}
      />
    );
  }
}

export default GoodPractice;
