import React from 'react';
import {
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
  root: {
    padding: '0 30px',
    minHeight: 'calc(100vh - 200px)'
  },
});

class Notallowed extends React.Component {

    render() {
      const { classes } = this.props;
      const lang = this.props.lang;
        return (
          <div className={classes.root}>
            <Typography gutterBottom variant="h2" component="h2" style={{'color': '#fdbb2d'}}>
              Not Allowed, Sorry!
            </Typography>
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(Notallowed));
