import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import { each } from "lodash";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import Select from "react-select";
import { I18n } from "../../i18n/";
import makeAnimated from "react-select/lib/animated";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "94%",
    margin: "30px 3%",
  },
  modal: {
    position: "absolute",
    width: theme.spacing.unit * 150,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: "50%",
    height: "90vh",
    overflowY: "auto",
    top: "50%",
    transform: `translate(-50%, -50%)`,
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      marginbBottom: "0",
    },
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
  },
  buttonCancel: {
    marginRight: "20px",
  },
  select: {
    "& .css-vj8t7z": {
      border: "0",
      backgroundColor: "#E1EDFB",
      borderRadius: "6px",
    },
    "& .css-d8oujb": {
      display: "none",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
    "& .css-1ep9fjw": {
      backgroundColor: "#B4C3D1",
      padding: "11px",
      borderRadius: "0 6px 6px 0",
      "& svg": {
        background:
          "url(/images/icons/icon-arrow-bottom.png) no-repeat center center / 15px 15px",
        "& path": {
          display: "none",
        },
      },
    },
    "&.error-field > div": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class ItemEdit extends React.Component {
  state = {
    open: true,
    usersList: [],
    selectedMembers: null,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const item = this.props.item;
    const stateOpts = {};

    const usersList = [];
    const usersData = await fetchData("get", "/users?size=200");
    each(usersData[0], (item) => {
      usersList.push({ value: item.id.toString(), label: item.login });
    });
    stateOpts.usersList = usersList;

    if (item) {
      stateOpts.selectedMembers = item.members.map((user) =>
        usersList.find((option) => option.value == user.id)
      );
    }
    this.setState(stateOpts);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleMembersChange = (selectedOption) => {
    this.setState({ selectedMembers: selectedOption });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to="/team" />;
    }
  };

  saveItem = async (values) => {
    const { selectedMembers } = this.state;

    const data = {
      name: values.name,
      description: values.description,
    };

    data.memberIds = selectedMembers.map((selectedUser) => selectedUser.value);

    if (values.id) {
      await fetchData("put", `/teams/${values.id}`, data);
    } else {
      await fetchData("post", "/teams", data);
    }
    this.setState({ open: false });
    this.props.handleItemSave();
  };

  onSubmit = async (values) => {
    await sleep(300);
    this.saveItem(values);
  };

  render() {
    const { classes, item, lang } = this.props;
    const { usersList, selectedMembers } = this.state;

    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className="modal">
            <a className="close" onClick={this.handleClose}>
              <Close />
            </a>

            <Grid container spacing={24} alignItems="center">
              <Grid item xs={12}>
                <Typography
                  gutterBottom
                  variant="h2"
                  component="h2"
                  style={{ color: "#fdbb2d" }}
                >
                  {item
                    ? `${I18n[lang].team.edit} ${item.id}`
                    : I18n[lang].team.create}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.data}>
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="name">
                      {({ input }) => (
                        <TextField
                          label={I18n[lang].common.name}
                          margin="normal"
                          className="text-field"
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="description">
                      {({ input }) => (
                        <TextField
                          label={I18n[lang].common.description}
                          margin="normal"
                          className="text-field"
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="members">
                      {({ input, meta }) => (
                        <>
                          <InputLabel className={classes.label}>
                            {I18n[lang].common.memberId}
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classes.select}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            isMulti
                            {...input}
                            options={usersList}
                            value={selectedMembers}
                            onChange={this.handleMembersChange}
                          />
                        </>
                      )}
                    </Field>

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
