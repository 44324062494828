import React from "react";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "#fff",
    padding: "8px 10px",
    borderRadius: "5px",
    fontWeight: 700,
  },
  round: {
    borderRadius: "20px",
  },
  primary: {
    backgroundColor: "#6495ed",
    "&:hover": {
      backgroundColor: "#5486e0",
    },
  },
  secondary: {
    backgroundColor: "#fe8368",
    "&:hover": {
      backgroundColor: "#f67a5f",
    },
  },
  tertiary: {
    backgroundColor: "#add8e6",
    "&:hover": {
      backgroundColor: "#9bcfe0",
    },
  },
  quaternary: {
    backgroundColor: "#789",
    "&:hover": {
      backgroundColor: "#789",
      opacity: "0.9",
    },
  },
  icon: {
    fontSize: "16px",
    marginRight: "4px"
  }
};

const GIZButton = (props) => {
  const { children, classes, icon = null, color, isRound = false, className, ...rest } = props;
  const { primary, secondary, tertiary, quaternary, round: roundClass, icon: iconClass, ...restClasses } = classes;

  let cssClass = "";
  switch (color) {
    case "primary":
      cssClass = primary;
      break;
    case "secondary":
      cssClass = secondary;
      break;
    case "tertiary":
      cssClass = tertiary;
      break;
    case "quaternary":
      cssClass = quaternary;
      break;
    default:
      cssClass = "none";
  }

  let IconComponent;
  if (icon) {
    IconComponent = require(`@material-ui/icons`)[icon];
    if (!IconComponent) {
      IconComponent = require(`./icons/${icon}.js`).default;
    }
  }


  return (
    <Button
      variant="contained"
      classes={restClasses}
      className={classNames(cssClass, className, {
        [roundClass]: isRound,
      })}
      {...rest}
    >
      {IconComponent && <IconComponent className={iconClass} />}
      {children}
    </Button>
  );
};

export default withStyles(styles)(GIZButton);
