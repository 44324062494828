import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import fetchMultiPartData from '../../utils/fetchMultiPart';
import classNames from 'classnames';
import Loader from '../../components/loader';
import { I18n } from '../../i18n/';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '94%',
    margin: '30px 3%',
  },
  modal: {
    width: '900px !important',
    height: '54vh !important',
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  alignRight: {
    textAlign: 'right'
  },
  data: {
    '& h4': {
      marginBottom: '0'
    }
  },
  textField: {
    width: '100%',
    marginBottom: '20px',
    '&.error-field fieldset': {
      borderLeft: '1px solid #fe8368',
      borderLeftWidth: '6px'
    }
  },
  inputFile: {
    '& label': {
      display: 'block',
      marginBottom: '10px'
    },
    '& input': {
      display: 'block',
      width: '0.1px',
      height: '0.1px',
      opacity: '0',
      overflow: 'hidden',
      position: 'absolute',
      zIndex: '-1'
    },
    '& input + label': {
      fontSize: '0.8rem',
      fontWeight: '300',
      color: '#fff',
      backgroundColor: '#add8e6',
      display: 'inline-block',
      marginBottom: '0',
      padding: '7px 26px',
      borderRadius: '15px',
      cursor: 'pointer'
    },
    '& span': {
      marginLeft: '5px',
      fontSize: '10px',
      color: 'gray',
      fontWeight: '700'
    },
    '& p': {
      marginTop: '4px',
      fontSize: '10px',
      color: 'gray',
      fontWeight: '700'
    }
  },
  error: {
    width: '100%',
    color: '#a94442',
    border: '1px solid #ebcccc',
    borderRadius: '4px',
    backgroundColor: '#f2dede',
    padding: '10px 20px',
    marginBottom: '10px'
  },
  buttonCancel: {
    marginRight: '20px'
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const required = value => (value ? undefined : "Required");

class ItemEdit extends React.Component {
  state = {
    loading: false,
    open: true,
    errorFile: false,
    fileName: ''
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  renderRedirect = () => {
      if (!this.state.open) {
        return <Redirect to='/templates' />
      }
  }

  saveItem = async (values) => {
    let formErrorState = false;
    var templateFile = document.querySelector('input[type="file"][name="file"]')

    var data = new FormData();
    data.set('id', values.id)
    data.set('title', values.title)
    data.set('subtitle', values.subtitle)

    if(templateFile && templateFile.files[0]){
      if (templateFile.files[0].size < 10485760) {
        data.set('file', templateFile.files[0]);
        data.set('fileName', templateFile.files[0].name);
        data.set('fileContentType', templateFile.files[0].type);
      } else {
        this.setState({errorFile: true});
        formErrorState = true;
      }
    } else {
      data.delete('file');
    }

    if (formErrorState) {
      this.setState({loading: false});
      return false;
    }

    if (values.id) {
      await fetchMultiPartData('put', `/utemplates/${values.id}`, data);
    } else {
      await fetchMultiPartData('post', '/utemplates', data);
    }

    this.setState({ open: false });

    this.props.handleItemSave();
  }

  onSubmit = async values => {
    this.setState({ loading: true });
    await sleep(300)
    this.saveItem(values);
  }

  handleFile = () => {
    var file = document.querySelector('#fileInput');
    if (file && file.files[0]) {
      this.setState({fileName: file.files[0].name});
    }
  }

  render() {
      const { classes, item, lang } = this.props;
      const { errorFile, fileName } = this.state;
      return (
        <>
          {this.renderRedirect()}

          {this.state.loading && (
            <Loader />
          )}

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
          {item ? (
            <div className={classNames('modal', classes.modal)}>
              <a className="close" onClick={this.handleClose}>
                <Close/>
              </a>

              <Grid container spacing={24} alignItems='center'>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h2" component="h2" style={{'color': '#fdbb2d'}}>
                    {I18n[lang].templates.edit} {item.id}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={classes.data}>
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={item}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>

                      <Field name="title" validate={required}>
                        {({ input, meta }) =>
                          <TextField
                            label={I18n[lang].templates.title}
                            margin="normal"
                            className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                            variant="outlined"
                            {...input}
                          />}
                      </Field>

                      <Field name="subtitle">
                        {({ input, meta }) =>
                          <TextField
                            label={I18n[lang].templates.subTitle}
                            margin="normal"
                            className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                            variant="outlined"
                            {...input}
                          />}
                      </Field>

                      <Field name="file">
                        {() => (
                          <div class={classes.inputFile}>
                            <InputLabel>{I18n[lang].templates.file}</InputLabel>
                            <input type="file" id="fileInput" name="file"
                                   onChange={() => this.handleFile()}/>
                            <label for="fileInput">Add file</label>
                            <span>{fileName}</span>
                            <p>max. 10 MB</p>
                            {errorFile && (
                              <div className={classes.error}>File size should be 10MB max</div>
                            )}
                          </div>
                        )}
                      </Field>

                      <Grid container spacing={24} alignItems='center'>
                        <Grid item xs={12} className={classes.alignRight}>
                          <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                            <i></i>
                            {I18n[lang].common.cancel}
                          </Button>
                          <Button variant="contained" className='button-submit' type="submit" color="primary">
                            <i></i>
                            {I18n[lang].common.save}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Typography>
            </div>
          ) : (
            <div className={classNames('modal', classes.modal)}>
              <a className="close" onClick={this.handleClose}>
                <Close/>
              </a>

              <Grid container spacing={24} alignItems='center'>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h2" component="h2" style={{'color': '#fdbb2d'}}>
                    {I18n[lang].templates.create}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className={classes.data}>
                <Form
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>

                      <Field name="title" validate={required}>
                        {({ input, meta }) =>
                          <TextField
                            label={I18n[lang].templates.title}
                            margin="normal"
                            className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                            variant="outlined"
                            {...input}
                          />}
                      </Field>

                      <Field name="subtitle">
                        {({ input, meta }) =>
                          <TextField
                            label={I18n[lang].templates.subTitle}
                            margin="normal"
                            className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                            variant="outlined"
                            {...input}
                          />}
                      </Field>

                      <Field name="file">
                        {() => (
                          <div class={classes.inputFile}>
                            <InputLabel>{I18n[lang].templates.file}</InputLabel>
                            <input type="file" id="fileInput" name="file"
                                   onChange={() => this.handleFile()}/>
                            <label for="fileInput">Add file</label>
                            <span>{fileName}</span>
                            <p>max. 10 MB</p>
                            {errorFile && (
                              <div className={classes.error}>File size should be 10MB max</div>
                            )}
                          </div>
                        )}
                      </Field>

                      <Grid container spacing={24} alignItems='center'>
                        <Grid item xs={12} className={classes.alignRight}>
                          <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                            <i></i>
                            {I18n[lang].common.cancel}
                          </Button>
                          <Button variant="contained" className='button-submit' type="submit" color="primary">
                            <i></i>
                            {I18n[lang].common.save}
                          </Button>
                        </Grid>
                      </Grid>

                    </form>
                  )}
                />
              </Typography>
            </div>
          )}
          </Modal>
        </>
      );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
