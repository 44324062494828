import React from 'react';
import {Button, Grid} from '@material-ui/core';
import GenderBalanceChart from '../../components/charts/registry/gender-balance';
import ParticipantEmployerLevelsChart from '../../components/charts/registry/participant-employer-levels';
import ParticipantEmployerTypesChart from '../../components/charts/registry/participant-employer-types';
import EventTargetGroupsChart from '../../components/charts/registry/event-target-groups';
import EventSatisfactionChart from '../../components/charts/registry/event-satisfactions';
import EventFormatsChart from '../../components/charts/registry/event-formats';
import EventResultsChart from '../../components/charts/registry/event-results';
import ParticipantEventFormatChart from '../../components/charts/registry/participant-event-formats';
import EventByTSPChart from '../../components/charts/registry/event-by-tsp';
import TSPModuleSfSChart from "../../components/charts/registry/tsp-module-sfs";
import TSPModuleSfLChart from "../../components/charts/registry/tsp-module-sfl";
import EventsPerOblastChart from "../../components/charts/registry/events-per-oblast";
import MunicipalitiesInOblastChart from "../../components/charts/registry/municipalities-in-oblast";
import eventResults from "../../components/charts/registry/event-results";

import classNames from "classnames";
import {I18n} from "../../i18n";
import {Link} from "react-router-dom";
import Loader from "../../components/loader";

class RegistryGraphs extends React.Component {

    render() {
        const graphsFilter = this.props.graphsFilter;
        const { classes, lang, forPrint } = this.props;
        return (
            <>
                <Grid item xs={12} className="pageBreak">
                    <GenderBalanceChart filter={graphsFilter} noActions={forPrint} forPrint={forPrint} />
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} >
                    <ParticipantEmployerLevelsChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} className="pageBreak">
                    <ParticipantEmployerTypesChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} >
                    <EventTargetGroupsChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} className="pageBreak">
                    <EventSatisfactionChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6}>
                    <EventFormatsChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} className="pageBreak">
                    <ParticipantEventFormatChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} >
                  <EventResultsChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} className="pageBreak">
                    <EventByTSPChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} >
                    <TSPModuleSfSChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} className="pageBreak">
                    <TSPModuleSfLChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6} >
                    <EventsPerOblastChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>
                <Grid item xs={forPrint ? 12 : 6}>
                    <MunicipalitiesInOblastChart filter={graphsFilter} noActions={forPrint}/>
                </Grid>

            </>
        )
    }
}

export default RegistryGraphs;
