import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { I18n } from "../../i18n/";
import { filter } from "lodash";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import classNames from "classnames";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";
import CalendarToday from "@material-ui/icons/CalendarToday";
import { selectStyles } from "../../style/select-styles";

const TODAY = new Date();

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "652px",
    margin: "30px 3%",
  },
  modal: {
    width: "652px !important",
    height: "629px !important",
    padding: "40px 32px 26px !important",
    borderRadius: "10px",
    "& .text-area": {
      marginBottom: "16px !important",
      "& label": {
        paddingBottom: "0px !important",
        color: "#6495ed",
        fontWeight: "300",
      },
    },
    "& label": {
      fontSize: "24px !important",
      padding: "0 !important",
    },
    "& h2": {
      textTransform: "uppercase",
      marginBottom: "32px !important",
      color: "#88ADF0 !important",
      fontSize: "36px !important",
      fontFamily: "Montserrat",
      fontWeight: 900,
      letterSpacing: "0.15rem",
      textAlign: "center",
    },
    "& .inline-select": {
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "row",
      // alignItems: 'end',
      margin: "16px 0",
      "& label": {
        margin: "0 15px 0 0",
        color: "#6495ed",
        fontWeight: "300",
      },
      "& > div": {
        flexGrow: 1,
      },
      "& .short-select": {
        "& .css-vj8t7z": {
          width: "170px",
        },
      },
    },
  },
  alignRight: {
    textAlign: "right",
  },
  data: {
    "& h4": {
      marginBottom: "0",
    },
  },
  dataLabel: {
    padding: "1vh 0",
    fontSize: "24pt",
    transform: "translate(0, 0px) scale(1)",
    fontFamily: "Montserrat, sans-serif",
    lineHeight: 1,
    color: "#6495ed",
    fontWeight: "300",
  },
  textField: {
    width: "100%",
    marginBottom: "32px !important",
    "& label": {
      marginBottom: "10px",
    },
    "&.inline-field": {
      display: "flex",
      justifyContent: "flex-start",
      // alignItems: 'end',
      flexDirection: "row",
      "& label": {
        margin: "0 15px 0 0",
        color: "#6495ed",
        fontWeight: "300",
      },
      "& > div": {
        flexGrow: 1,
        marginTop: 0,
        "&:before": {
          display: "none",
        },
      },
      "& input": {
        height: "24px",
      },
    },
  },
  dateField: {
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      color: "rgba(0, 0, 0, 0)",
      opacity: "1",
      display: "block",
      background:
        "#add8e6 url(/images/icons/calendar.svg) no-repeat center center",
      backgroundSize: "22px 22px",
      borderRadius: "8px",
      width: "40px",
      height: "40px",
      borderWidth: "thin",
      position: "absolute",
      right: "0",
      top: "0",
    },
    "&.w30": {
      width: "235px",
      "& > div": {
        width: "30px",
      },
    },
  },
  select: {
    "& .css-vj8t7z": {
      border: "0",
      backgroundColor: "#E1EDFB",
      borderRadius: "6px",
      minHeight: "45px",
    },
    "& .css-d8oujb": {
      display: "none",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
    "& .css-1wy0on6": {
      width: "52px",
      minHeight: "45px",
    },
    "& .css-1uq0kb5": {
      padding: "0 !important",
    },
    "& .css-1ep9fjw": {
      minHeight: "45px",
      backgroundColor: "#B4C3D1",
      padding: "16px 8px 5px",
      borderRadius: "0 6px 6px 0",
      display: "none",
      "&:last-child": {
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      },
      "& svg": {
        marginTop: "-10px",
        background:
          "#B4C3D1 url(/images/icons/icon-arrBot.png) no-repeat center center / 20px 20px",
        "& path": {
          display: "none",
        },
      },
    },
  },
  buttons: {
    marginTop: "20px",
    "& span": {
      fontSize: "12px !important",
    },
    paddingBottom: "0",
    "& .button-submit": {
      marginRight: "0",
    },
    "& .button-cancel": {
      marginRight: "15px",
    },
  },
  closeButton: {
    color: "#b4c3d1 !important",
    "& svg": {
      width: "1.4em !important",
      height: "1.4em !important",
    },
  },
  dFlex: {
    display: "flex",
  },
  dataIcon: {
    background: "#add8e6",
    width: 51,
    height: 45,
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
  },
  dataPick: {
    width: "calc(100% - 230px)",
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const initiativeTypes = [
  { value: "conferences", label: "Conferences, all types, at all levels" },
  {
    value: "seminars",
    label: "Training sessions/seminars, workshops, round-tables ",
  },
  { value: "oblast_events", label: "Events at Oblast level" },
  { value: "sector_events", label: "Sectoral development events" },
  { value: "hromada_fairs", label: "(Amalgamated) Hromada fairs" },
  {
    value: "publications",
    label: "Guidebooks and other publications (studies on experiences)",
  },
  { value: "letters", label: "Newsletter/ leaflets/ flyers " },
  { value: "website", label: "Website" },
  {
    value: "social_media",
    label: "Social media (Facebook/ Twitter) and digital communications",
  },
  {
    value: "tv_radio",
    label: "Radio features & spots, TV contributions, spots etc",
  },
  { value: "print_media", label: "Print media " },
  { value: "press_releases", label: "Press releases" },
];

const allTargetGroups = [
  { value: "min_region", label: "MinRegion" },
  { value: "ministries", label: "Other line ministries" },
  {
    value: "oblast_administration",
    label: "Oblast state administration/council",
  },
  {
    value: "administration",
    label: "Municipalities – administration, elected representatives",
  },
  { value: "non_amalgamated_hromadas", label: "Non-amalgamated hromadas" },
  { value: "civil_society", label: "Civil society organisations" },
  { value: "general_public", label: "The general public" },
  {
    value: "social_sector",
    label: "Education, health and other sector-specific institutions",
  },
  { value: "academia", label: "Academia and external experts" },
  {
    value: "international",
    label: "International partners and donor organisations",
  },
];

class ItemEdit extends React.Component {
  state = {
    open: true,
    selectedTargetGroups: [],
    isVisibleDataPicker: false,
  };

  dataPickerFlag = React.createRef();

  handleToggleCalendar = (event) => {
    this.dataPickerFlag.current.open(event);
    this.setState({ isVisibleDataPicker: true });
  };

  componentDidMount() {
    const item = this.props.item;
    const stateOpts = {};
    if (item) {
      stateOpts.selectedTargetGroups = filter(allTargetGroups, (e) => {
        return item.targetGroupIds && item.targetGroupIds.indexOf(e.value) >= 0;
      });
    }
    this.setState(stateOpts);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/good-practices/view/${this.props.practiceId}`} />;
    }
  };

  handleTargetGroupsChange = (selectedOption) => {
    this.setState({ selectedTargetGroups: selectedOption });
  };

  saveItem = async (values) => {
    const data = {
      goodPracticeId: this.props.practiceId,
      id: values.id,
      date: values.date,
      url: values.url,
      description: values.description,
    };

    const targetGroupIds = [];
    this.state.selectedTargetGroups.forEach((option) => {
      targetGroupIds.push(option.value);
    });
    data.targetGroupIds = targetGroupIds;

    data.kind =
      values.kind && values.kind.value
        ? values.kind.value
        : values.kind || null;

    if (values.id) {
      await fetchData("put", `/good_practice_initiatives/${values.id}`, data);
    } else {
      await fetchData("post", "/good_practice_initiatives", data);
    }
    this.setState({ open: false });

    this.props.handleItemSave();
  };

  onSubmit = async (values) => {
    await sleep(300);
    this.saveItem(values);
  };

  render() {
    const { classes, item, lang } = this.props;
    const { selectedTargetGroups } = this.state;
    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classNames("modal", classes.modal)}>
            <a
              className={classNames(classes.closeButton, "close")}
              onClick={this.handleClose}
            >
              <Close />
            </a>
            <Typography gutterBottom variant="h2" component="h2">
              Add or Edit Initiative
            </Typography>

            <Typography className={classes.data} component="div">
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Field name="kind">
                      {({ input, meta }) => (
                        <div className="inline-select">
                          <InputLabel className={classes.label}>
                            Type:
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            options={initiativeTypes}
                            {...input}
                            value={initiativeTypes.find(
                              (x) => x.value == input.value
                            )}
                          />
                        </div>
                      )}
                    </Field>

                    <Field name="description">
                      {({ input, meta }) => (
                        <TextField
                          label="Description:"
                          margin="normal"
                          className={classNames(
                            classes.textarea,
                            "text-area",
                            "description-field",
                            { "error-field": meta.error && meta.touched }
                          )}
                          variant="outlined"
                          multiline
                          rows="4"
                          rowsMax="20"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="url">
                      {({ input }) => (
                        <TextField
                          label="URL:"
                          margin="normal"
                          className={classNames(
                            "text-field",
                            classes.textField,
                            "inline-field"
                          )}
                          variant="outlined"
                          {...input}
                        />
                      )}
                    </Field>

                    <Field name="date">
                      {({ input, meta }) => (
                        <div className={classes.dFlex}>
                          <div className={classes.dFlex}>
                            <label className={classes.dataLabel}>
                              Date of initiative:
                            </label>
                            <div
                              className={classes.dataIcon}
                              onClick={this.handleToggleCalendar}
                            >
                              <CalendarToday
                                style={{ color: "#e6feff", width: 32 }}
                              />
                            </div>
                          </div>
                          <div
                            className={classes.dataPick}
                            style={{
                              display: this.state.isVisibleDataPicker
                                ? "block"
                                : "none",
                            }}
                          >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <InlineDatePicker
                                className={classNames(
                                  "text-field",
                                  classes.textField,
                                  classes.dateField,
                                  "inline-field",
                                  { w30: !input.value },
                                  { "error-field": meta.error && meta.touched }
                                )}
                                {...input}
                                value={input.value || TODAY}
                                ref={this.dataPickerFlag}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      )}
                    </Field>

                    <Field name="targetGroups">
                      {({ input, meta }) => (
                        <div className="inline-select">
                          <InputLabel className={classes.label}>
                            Target group:
                          </InputLabel>
                          <Select
                            styles={selectStyles()}
                            className={classNames(
                              { "short-select": !selectedTargetGroups.length },
                              classes.select
                            )}
                            closeMenuOnSelect={true}
                            components={makeAnimated()}
                            onChange={this.handleTargetGroupsChange}
                            //onMenuOpen={() => this.handleDropdownChange('errorTargetGroups')}
                            options={allTargetGroups}
                            value={selectedTargetGroups}
                            isMulti
                          />
                        </div>
                      )}
                    </Field>

                    <Grid
                      container
                      spacing={24}
                      alignItems="center"
                      className={classes.buttons}
                    >
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(ItemEdit));
