import ItemService from "./ItemService";
import { Endpoints } from "../config.js";

export class SelectOptionService extends ItemService {
  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "langKey");
    this.validationRequired(result, state, "ua");
    this.validationRequired(result, state, "en");

    return result;
  }

  MapStateToModel(state) {
    let data = {};
    this.GetFields().forEach((x) => {
      data[x] = state[x];
    });

    return data;
  }

  GetFields() {
    return ["id", "langKey", "en", "ua", "descEn", "descUa", "active"];
  }
}

export class CategoryService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Category.path, onUpdateState, lang);
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "langKey");
    this.validationRequired(result, state, "ua");
    this.validationRequired(result, state, "en");
    this.validationRequired(result, state, "keywords");

    return result;
  }

  GetFields() {
    return [
      "id",
      "en",
      "ua",
      "descEn",
      "descUa",
      "langKey",
      "active",
      "keywords",
      "categoryResultId",
    ];
  }

  getAdditionalParams() {
    return "all=true";
  }
}

export class FundingSourceService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.FundingSource.path, onUpdateState, lang);
  }
}

export class EmployerLevelService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.EmployerLevel.path, onUpdateState, lang);
  }
}

export class EmployertypeService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.EmployerType.path, onUpdateState, lang);
  }
}

export class TargetGroupService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.TargetGroup.path, onUpdateState, lang);
  }
}

export class TargetLevelService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.TargetLevel.path, onUpdateState, lang);
  }
}

export class OrganizerService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Organizer.path, onUpdateState, lang);
  }
}

export class FormatService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Format.path, onUpdateState, lang);
  }
}

export class EventCharacterService extends SelectOptionService {
  constructor(onUpdateState, lang) {
    super(Endpoints.EventCharacter.path, onUpdateState, lang);
  }
}
