import React from 'react';
import domtoimage from 'dom-to-image';
import { HorizontalBar, Bar, Pie } from 'react-chartjs-2';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import downloadFile from '../../utils/fetchFile';

import { set } from 'lodash';


class BaseGraph extends React.Component {

  chartData = {
    datasets: [
    ]
  }

  chartReference = {}

  chartKey = Math.random()

  state = {
    labels: [],
    data: [],
    data2: null,
    data3: null,
    data4: null,
    data5: null,
    data6: null,
    secondaryData: null,
    prevFilter: '',
    prevUrl: '',
    hover: false,
    hoverId: ''
  }

  componentWillMount() {
    this.chartData.datasets = this.props.datasetOptions
  }

  componentDidMount() {
    this.getChartData()
  }

  componentDidUpdate(nextProps){
    if (this.state.prevFilter != this.props.filter || this.state.prevUrl != this.props.url){
      this.chartData.datasets = this.props.datasetOptions
      if (this.state.prevUrl != this.props.url){
        this.chartReference.chartInstance && this.chartReference.chartInstance.destroy();
        this.chartKey = Math.random()
      }
      this.getChartData()
    }
  }

  dataURL = (format) => {
    let url = this.props.url;
    let filter = this.props.filter || ''
    if (format == 'xlsx') {
      url += '.' + format
      filter += 'chart_type='+this.props.type
    }

    url += filter ? '?' + filter : '';
    return url;
  }

  async getChartData() {
    const stats = (await fetchData('get', this.dataURL()))[0];
    this.setState({
      labels: stats.labels,
      data: stats.data,
      data2: (!!stats.data2 ? stats.data2 : null),
      data3: (!!stats.data3 ? stats.data3 : null),
      data4: (!!stats.data4 ? stats.data4 : null),
      data5: (!!stats.data5 ? stats.data5 : null),
      data6: (!!stats.data6 ? stats.data6 : null),
      secondaryData: (!!stats.secondaryData ? stats.secondaryData : null),
      prevFilter: this.props.filter,
      prevUrl: this.props.url
    })
  }

  handleDownload = async (event) => {
    await downloadFile(this.dataURL('xlsx'));
  }

  prepareDownloadableImage = (animation) => {
    if (!this.imageLink) { return }
    this.imageLink.href = animation.chart.canvas.toDataURL('image/png')
    this.imageLink.download = this.props.url.replace('/graph/','')+'_chart.png'
  }


  handleCreatePng  = () => {
    const input = document.getElementById(this.props.url);

    domtoimage.toPng(input)
      .then(function (dataUrl) {
        var element = document.createElement('a');
        element.href = dataUrl;
        element.download = 'chart.png';
        element.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  changeBg = (value) => {
    this.setState({
      hover: !this.state.hover,
      hoverId: value
    })
  }

  render() {
    const { labels, data, data2, data3, data4, data5, data6, secondaryData } = this.state;
    const { type, footnotes, showLegend } = this.props;
    let chartOptions = this.props.chartOptions;
    if (!chartOptions.animation) { chartOptions.animation = {} }
    chartOptions.animation.onProgress = this.onAnimProgress
    chartOptions.animation.onComplete = this.prepareDownloadableImage
    chartOptions.layout = {
      padding: {
        //top: 20
      }
    };
    chartOptions.pointStyle = 'circle';
    chartOptions.legend = {
      display: showLegend,
      labels: {
        usePointStyle: true
      }
    }

    set(chartOptions, 'legend.position', 'bottom');

    chartOptions.title = {
      display: this.props.showTitle === 'hidden' ? false : true ,
      text: this.props.tableTitle,
      fontSize: 18,
      padding:20,
      lineHeight: 3,
      fontFamily: "'Montserrat-Black', 'Helvetica', 'Arial', sans-serif",
      fontColor: '#191970',
      fontStyle: this.props.showTitle === 'hidden' ? '700' : '400'
    }

    if (this.props.showTitle === 'hidden' ) {
      chartOptions.layout = {
        padding: {
          top: 30
        }
      }
    }

    switch(type){
      case 'pie': ;
      case 'horizontalBar':
        this.chartData.labels = labels;
        chartOptions.layout.padding.right = 25
        break;
      case 'bar':;
      case 'line':
        chartOptions.spanGaps = true;
        chartOptions.scales.xAxes[0].labels = labels;
        let ticks = chartOptions.scales.xAxes[0].ticks || {};
        ticks.autoSkip = false
        chartOptions.scales.xAxes[0].ticks = ticks;
        break;
    }

    this.chartData.datasets[0].data = data;
    if (data2 && (this.chartData.datasets.length > 1)) { this.chartData.datasets[1].data = data2 }
    if (data3 && (this.chartData.datasets.length > 2)) { this.chartData.datasets[2].data = data3 }
    if (data4 && (this.chartData.datasets.length > 3)) { this.chartData.datasets[3].data = data4 }
    if (data5 && (this.chartData.datasets.length > 4)) { this.chartData.datasets[4].data = data5 }
    if (data6 && (this.chartData.datasets.length > 5)) { this.chartData.datasets[5].data = data6 }

    if (secondaryData) { this.chartData.datasets[0].secondaryData = secondaryData }

    const plugins = this.props.plugins || []

    return (
      <div className='chartContainer'>
        {!this.props.noActions && (
          <p style={{display: 'flex', marginTop: '25px'}}>
            <a
              href="javascript:void(0);"
              style={{
                display: 'flex',
                color: 'rgb(255, 255, 255)',
                background: this.state.hover && this.state.hoverId == 1 ? '#9bd9c8' : 'rgb(119, 210, 183)',
                fontSize: '14px',
                minWidth: '250px',
                textAlign: 'center',
                minHeight: '40px',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px'}}
              onClick={this.handleDownload}
              className="btn btn-download"
              onPointerEnter={() => this.changeBg(1)}
              onPointerLeave={() => this.changeBg(0)}
            >
              Download .xlsx
            </a>
            <a
              // ref={(link) => { this.imageLink = link }}
              className="imageUrl btn btn-download"
              style={{
                display: 'flex',
                color: 'rgb(255, 255, 255)',
                background: this.state.hover && this.state.hoverId == 2 ? '#9bd9c8' : 'rgb(119, 210, 183)',
                fontSize: '14px',
                minWidth: '250px',
                textAlign: 'center',
                minHeight: '40px',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px'
              }}
              onClick={this.handleCreatePng}
              onPointerEnter={() => this.changeBg(2)}
              onPointerLeave={() => this.changeBg(0)}
              href="javascript:void(0);"
            >
              Download .png
            </a>
          </p>
        )}
        <div id={this.props.url} style={{ minHeight: '480px'}}>
          {
            (type == 'horizontalBar') ? (
              <HorizontalBar
                width={100}
                height={this.props.noActions ? 73 : 100}
                options={{ maintainAspectRatio: false }}
                key={this.chartKey}
                ref={(reference) => this.chartReference = reference}
                data={this.chartData}
                options={chartOptions}
                plugins={plugins}
              />
            ) : (type == 'bar' || type == 'line') ? (
              <Bar
                width={100}
                height={this.props.noActions ? 73 : 100}
                options={{ maintainAspectRatio: false }}
                key={this.chartKey}
                ref={(reference) => this.chartReference = reference}
                data={this.chartData}
                options={chartOptions}
                plugins={plugins}
              />
            ) : (type == 'pie') ? (
              <Pie
                width={200}
                height={this.props.noActions ? 73 : 100}
                options={{ maintainAspectRatio: false }}
                key={this.chartKey}
                ref={(reference) => this.chartReference = reference}
                data={this.chartData}
                options={chartOptions}
                plugins={plugins}
              />
            ) : ('')
          }
          {footnotes &&
            <div>
              {footnotes.map((item) => <div> {item} </div>)}
            </div>
          }
        </div>
      </div>
    );
  }
};


export default withRoot(BaseGraph);
