const drawerWidth = 400;

export const styles = theme => ({
  root: {
    display: 'flex',
    background: '#6495ed',
    '& .mh50':{
      minHeight: '50px !important',
      maxHeight: '50px !important',
    },
    '& .fs08 button span':{
      fontSize: '1rem',
    },
    '& .mr10':{
      marginRight: '10px',
    },
    '& .my-35':{
      marginTop: '-35px !important',
      marginBottom: '35px !important',
    },
    '& label span.switch.checked > span:last-child': {
      backgroundColor: '#8ccfe5',
    },
    '& .select':{
      boxShadow: 'inset 4px 0px 0px #e6feff',
    },
    '& .max-filter':{
      paddingLeft: '111px',
      color: '#ffffff',
      fontSize: '1rem',
      textDecoration: 'underline',
    },
    '& .gender':{
      height: '35px',
    },
    '& .m0':{
      margin: '0 !important',
    },
    '& .selectIcon:before':{
      content: ' " " ',
      position: 'absolute',
      left: '0px',
      bottom: '-1px',
      height: '52px',
      width: '5px',
      marginTop: '-15px',
      boxShadow: 'inset 4px 0px 0px #e6feff',
    },
    '& .selectIcon img':{
      opacity: '1'
    }
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: '100%'
  },
  drawerOpen: {
    width: drawerWidth,
    position: 'absolute',
    top: '0',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .toolbar': {
      justifyContent: 'flex-end',
    },
    '& .wrap-list': {
      position: 'absolute',
      width: '100%',
      boxSizing: 'content-box',
      marginTop: '25px',
      paddingBottom: '40vh',

      '&::-webkit-scrollbar':{
        display: 'none'
      },
    }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '110px',
    position: 'absolute',
    top: '0',
    [theme.breakpoints.up('sm')]: {
      width: '110px',
    },
    '& .toolbar':{
      justifyContent: 'center',
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  customDrawer:{
    '& div .toolbar':{
      minHeight: '25px',
      '& button':{
        padding: '5px',
      },
    },
    '&>div > div':{
      zIndex: '1000',
      height: '100%',
      position: 'relative',
      paddingBottom: '20vh',

      [theme.breakpoints.up('lg')]: {
      },

      '&::-webkit-scrollbar': {
        display: 'none'
      },
    },
    '& .button':{
      marginBottom: '35px',
      '& >div:first-child':{
        marginRight: '20px',
        marginLeft: '26px',
      }
    },
    '& .checked':{
      color: '#e1edfb',
      padding: '0',
    },
    '& .tabs-img': {
      filter: 'brightness(0) invert(1)',
      opacity: '0.6',
      '&:hover': {
        opacity: '1',
      },
    },
    '& .tabsSvg': {
      position: 'relative',
      right: '13px'
    },
  },
  primaryCustom:{
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: '1.2px',
    color: '#ffffff',
    '& span':{
      color: '#ffffff',
      whiteSpace: 'normal'
    }
  },
  containedCustom: {
    margin: '1px',
    border: '1px solid transparent',
    backgroundColor: '#6495ed',
    color: 'white',
    maxHeight: '80px',
    whiteSpace: 'pre-wrap',
    textAlign: 'inherit',
    '&:hover': {
      backgroundColor: '#5486e0',
    },
  },
  outlinedCustom: {
    color: '#ffffff',
    margin: '1px',
    border: '1px solid #fdfdfd',
    minHeight: '34px',
    whiteSpace: 'pre-wrap',
    '&:hover': {
      boxShadow: '0px 0px 0px 1px white !important',
      backgroundColor: '#5486e0',
    },
  },
  nested:{
    paddingLeft: '110px',
    maxHeight: '30px',
  },
  gradientWrapp:{
    paddingLeft: '90px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  '@keyframes slide': {
    from: {
      transform: 'scaleY(0)',
      opacity: '0',
    },
    to:{
      transform: 'scaleY(1)',
      opacity: '1',
    }
  },
  subfilter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '112px',
    marginTop: '-14px',
    animation: 'slide 400ms',
    transformOrigin: 'top center',
  },
  contribute:{
    display: 'block',
    color: 'white',
    marginLeft: '112px',
    whiteSpace: 'pre-wrap',
  },
  checkbox: {
    paddingLeft: '111px',
    color: '#ffffff',
    '& .checkbox': {
      width: '20px',
      height: '20px',
      borderRadius: '5px',
      backgroundColor: '#E1EDFB',
      marginRight: '9px',

      '&--checked': {
        backgroundImage: 'url(/images/icons/icon-check-mark.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      }
    }
  },
  filter: {
    display: 'flex',
    marginTop: '5px',
    '& label': {
      flexGrow: 1,
      color: 'rgb(25, 25, 112)',
      fontSize: '12px'
    }
  },
  controlLabel: {
    alignItems: 'flex-start',
    '& span': {
      paddingTop: '0',
      paddingBottom: '0',
      color: '#191970',
      fontSize: '12px',
      letterSpacing: '0.1em'
    },
    '& svg path': {
      fill: '#ADD8EF',
      fillOpacity: 1,
    },
    '& + .count': {
      color: '#778899',
      fontSize: '12px',
      letterSpacing: '0.1em',
      fontWeight: '900'
    }
  },

})
