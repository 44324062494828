import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";

const styles = {
  label: {
    whiteSpace: "nowrap",
    color: "#191970",
  },
  icon: {
    position: "relative",
    top: "1px",
    backgroundColor: "#a6a6a6",
    boxShadow: "4px 0px 4px rgba(73, 66, 66, 0.25)",
  },
  iconChecked: {
    right: "-2px",
    backgroundColor: "#add8e6",
    boxShadow: "-4px 0px 4px rgba(73, 66, 66, 0.25)",
  },
  switchBase: {
    height: "15px",
  },
  colorPrimary: {
    "&$checked": {
      "& + $bar": {
        backgroundColor: "red",
      },
    },
  },
  checked: {
    "& + $bar": {
      backgroundColor: "#8ccfe5",
    },
  },
  bar: {
    width: "30px",
  },
};

const GIZSwitch = (props) => {
  const { label, checked, onChange, classes, className = "" } = props;

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          color="default"
          classes={{
            icon: classes.icon,
            iconChecked: classes.iconChecked,
            switchBase: classes.switchBase,
            checked: classes.checked,
            bar: classes.bar,
          }}
        />
      }
      label={label}
      className={cn(classes.label, className)}
    />
  );
};

export default withStyles(styles)(GIZSwitch);
