import React from "react";
import PropTypes from "prop-types";
import Edit from "../../components/dialog/edit.js";
import {
  FormTextField,
  FormCheckboxField,
} from "../../components/dialog/formField";
import { Paper, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";

const Item = (props) => {
  const { i18n, i18nCommon, serviceClass } = { ...props };
  const { classes, ...rest } = { i18n, ...props };

  return (
    <Edit
      i18n={i18n}
      i18nCommon={i18nCommon}
      serviceClass={serviceClass}
      {...rest}
      classes={classes}
      render={(fieldProps) => (
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={16}>
            <Grid item xs="6">
              <FormTextField
                fieldName="langKey"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
            <Grid item xs="6">
              <FormTextField
                fieldName="ua"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
            <Grid item xs="6">
              <FormTextField
                fieldName="en"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
            <Grid item xs="6">
              <FormCheckboxField
                fieldName="active"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
            <Grid item xs="12">
              <FormTextField
                fieldName="descEn"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
            <Grid item xs="12">
              <FormTextField
                fieldName="descUa"
                {...fieldProps}
                i18n={fieldProps.i18nCommon}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    />
  );
};

Item.propTypes = {
  i18n: PropTypes.object.isRequired,
  serviceClass: PropTypes.any.isRequired,
};

export default withStyles(styles)(Item);
