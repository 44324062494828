import React from "react";
import { observer } from "mobx-react";
import { Grid, Typography, InputLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import { Field } from "react-final-form";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import { StoreContext } from "../../stores/context";
import { REQUEST_STATES } from "../../utils/RequestStates";
import { required } from "../../validators/required";
import { email } from "../../validators/email";
import Loader from "../../components/loader";
import GIZAlert from "../../components/GIZAlert";
import GIZForm from "../../components/GIZForm";
import GIZTextField from "../../components/GIZTextField";
import GIZButton from "../../components/GIZButton";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    minHeight: "calc(100vh - 210px)",
  },
  textField: {
    width: "100%",
  },
  label: {
    padding: 0,
  },
  formControl: {
    width: "100%",
    marginBottom: "20px",
    background: "#e1edfb",
  },
  textArea: {
    width: "100%",
    marginBottom: "20px",
    background: "#e1edfb",
    "& textarea": {
      resize: "both",
      overflow: "scroll",
    },
    "&.error-field fieldset": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "400px",
    margin: "0 auto",
    justifyContent: "stretch",
  },
  formContainer: {
    padding: "30px 0",
    marginBottom: "50px",
  },
  alignRight: {
    textAlign: "right",
  },
  alert: {
    width: "400px",
  },
  select: {
    marginBottom: "20px",
    minWidth: "100%",
    "& > div": {
      background: "#e1edfb",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
  },
});

const langKeys = [
  { label: "English", value: "en" },
  { label: "Українська", value: "ua" },
];

class Settings extends React.Component {
  static contextType = StoreContext;

  constructor(props) {
    super(props);
    this.state = { valid: false };
  }

  onValid = (valid) => {
    this.setState({ valid });
  };

  onSubmit = async (values) => {
    await this.context.userStore.updateSettings(
      values.name,
      values.email,
      values.langKey.value ?? values.langKey
    );
  };

  render() {
    const { classes } = this.props;
    const { userStore, langStore } = this.context;
    const { valid } = this.state;

    return (
      <div className={classes.root}>
        {userStore.isPendingState && <Loader />}

        <Typography
          gutterBottom
          variant="h2"
          component="h2"
          style={{ color: "#fdbb2d" }}
        >
          User setting for [{userStore.login}]
        </Typography>

          <div className={classes.data}>
            <div className={classes.formContainer}>
              {userStore.updateState === REQUEST_STATES.Success && (
                <GIZAlert variant="success" className={classes.alert}>
                  Your settings were successfully saved.
                </GIZAlert>
              )}
              {userStore.updateState === REQUEST_STATES.Error && (
                <GIZAlert variant="error" className={classes.alert}>
                  Your settings weren't saved.
                </GIZAlert>
              )}
              <GIZForm
                onSubmit={this.onSubmit}
                initialValues={{
                  name: userStore.name,
                  email: userStore.email,
                  langKey: langStore.langKey,
                }}
                onValid={this.onValid}
                className={classes.form}
              >
                <GIZTextField
                  name="name"
                  label={langStore.common.name}
                  validator={required}
                  className={classes.textField}
                />
                <GIZTextField
                  name="email"
                  label={langStore.common.email}
                  validator={email}
                  className={classes.textField}
                />

                    <InputLabel className={classes.label}>
                      {langStore.common.language}
                    </InputLabel>
                    <Field name="langKey" validate={required}>
                      {({ input, meta }) => (
                        <Select
                          styles={selectStyles()}
                          className={classes.select}
                          closeMenuOnSelect={true}
                          components={makeAnimated()}
                          options={langKeys}
                          onChange={this.handleLanguage}
                          {...input}
                          value={langKeys.find(
                            (option) => option.value === input.value
                          )}
                        />
                      )}
                    </Field>

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.alignRight}>
                        <GIZButton color="primary" icon="Save" type="submit" disabled={!valid}>
                          {langStore.common.save}
                        </GIZButton>
                      </Grid>
                    </Grid>
              </GIZForm>
            </div>
          </div>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(observer(Settings)));
