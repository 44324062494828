import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import classNames from 'classnames';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

const RenderListItem = ({ ListItemTextClass, onClick, img, primary, isVisible}) => {

  return (
    <ListItem button onClick={onClick} className="button mh50">
      <ListItemIcon>
        <img src={img} className="tabs-img" style={{ marginRight: '25px'}} />
      </ListItemIcon>
      <ListItemText inset
        primary={primary}
        className={classNames('p0', ListItemTextClass, )}
      />
      {isVisible ? 
        <img src='./images/icons/triangle-bot.png' alt="open" />
        : null
      }
    </ListItem>
  );
  }

export default RenderListItem;
