import React from 'react';
import withRoot from '../../../withRoot';
import BaseGraph from '../base-graph';
import {barDatasetOptions, barOptions, barPlugins} from "../constants";
import {Settings} from "../../../config"
class ParticipantEmployerLevelsChart extends React.Component {
  render() {
    return (
      <BaseGraph
        type='bar'
        tableTitle={`Participants of ${Settings.Tenant.ProgramName} events by employer level (%)`}
        url='/graph/participant_employer_levels'
        chartOptions={barOptions()}
        datasetOptions={barDatasetOptions}
        filter={this.props.filter}
        plugins={barPlugins()}
        noActions={this.props.noActions}
      />
    );
  }
}

export default withRoot(ParticipantEmployerLevelsChart);
