import React from "react";
import { Form, FormSpy } from "react-final-form";

const GIZForm = ({
  children,
  onSubmit,
  initialValues = {},
  onValid = null,
  onRender = null, // try not to use that callback
  className = null,
}) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, form }) => {
        if (onRender) {
          onRender(form);
        }

        return (
          <form onSubmit={handleSubmit} className={className}>
            {onValid && (
              <FormSpy
                subscription={{ valid: true }}
                onChange={(props) => onValid(props.valid)}
              />
            )}
            {children}
          </form>
        );
      }}
    />
  );
};

export default GIZForm;
