import { makeAutoObservable } from "mobx";

export class AppStore {
  isHeaderShowing = false;

  constructor() {
    makeAutoObservable(this);
  }

  showHeader() {
    this.isHeaderShowing = true;
  }

  hideHeader() {
    this.isHeaderShowing = false;
  }
}
