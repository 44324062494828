import React from 'react';
import {
  Grid,
  Button,
  Paper,
  Typography
} from '@material-ui/core';
import { Add, Edit, RemoveRedEye, DeleteForever } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import Loader from '../../components/loader';
import { Link, Route } from 'react-router-dom';
import { find } from 'lodash';
import ItemEdit from './item-edit';
import downloadFile from '../../utils/fetchFile'
import history from '../../utils/history';
import { I18n } from '../../i18n/';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '100%',
    padding: '30px 3%',
    backgroundColor: '#f7f9fb'
  },
  title:{
    color: '#fdbb2d',
  },
  paper: {
    overflowX: 'auto',
    padding: '10px 20px 20px',
    boxShadow: 'none',
    borderRadius: '15px',
    [theme.breakpoints.down(1723)]: {
      padding: 20,
      display:'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      overflowX: 'hidden',
      '& p':{
        marginTop: 0,
      }
    },
  },
  alignRight: {
    textAlign: 'right'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  h3: {
    fontSize: '20px',
    color: '#6495ed',
    fontWeight: '500',
    [theme.breakpoints.down(1723)]: {
      marginTop: 0,
      lineHeight: '20px',
    },
  },
  h5: {
    fontSize: '12px',
    color: '#191970',
    fontWeight: '400',
    [theme.breakpoints.down(1723)]: {
      marginTop: 0,
    },
  },
  actions: {
    whiteSpace: 'nowrap',
    display: 'flex',
    '& a': {
      display: 'inline-flex',
      color: '#fff',
      fontSize: '12px',
      lineHeight: '25px',
      alignItems: 'center',
      backgroundColor: '#6495ed',
      padding: '6px 14px',
      minWidth: '96px',
      justifyContent: 'center',
      '&:first-child': {
        backgroundColor: '#6495ed',
        borderRadius: '0.5rem 0 0 0.5rem'
      },
      '&:last-child': {
        backgroundColor: '#fe8368',
        borderRadius: '0 0.5rem 0.5rem 0',
        '& i': {
          display: 'block',
          width: '14px',
          height: '14px',
          marginRight: '5px',
          background: 'url(/images/icons/icon-action-delete.svg) no-repeat 0 0 / 14px 14px',
        }
      },
    },
    '& svg': {
      width: '15px',
      height: '15px',
      marginRight: '2px'
    }
  },
});

class Templates extends React.Component {
    state = {
      loading: true,
      items: [],
      page: 0,
      total: 0,
      rowsPerPage: 25,
      searchQuery: '',
      order: 'desc',
      orderBy: ''
    };
    componentDidMount() {
      this.getData();
    }

    componentDidUpdate(prevProps) {
      if (this.props.id !== prevProps.id) {
        this.getData();
      }
    }

    async getData() {
      const data = await fetchData('get', '/utemplates');
      this.setState({ loading: false, items: await data[0], total: data[1] });
    }

    async deleteItem(id) {
      const {lang} = this.props;
      if (window.confirm(`${I18n[lang].common.deleteConfirm} ${I18n[lang].templates.template} ${id}?`)) {
        await fetchData('delete', `/utemplates/${id}`);
        this.getData();
      }
    }

    handleChangePage = (event, page) => {
      this.getPage(page);
      this.setState({ loading: true, page: page });
      history.push({
        search: `?page=${page}`
      })
    }

    async getPage(pageNumber) {
      const data = await fetchData('get', `/utemplates?page=${pageNumber + 1}`);
      this.setState({ loading: false, items: await data[0] });
    }

    editItem = ({ match: { params: { id } } }) => {
      if (this.state.loading) return null;
      const item = find(this.state.items, { id: Number(id) });

      return <ItemEdit item={item} handleItemSave={this.handleItemSave} lang={this.props.lang} />;
    }

    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    }

    async getSearchResults(searchQuery) {
      this.setState({ loading: false, items: await fetchData('get', `/_search/categories?query=${searchQuery}`) });
    }

    updateInputValue = e => this.setState({ searchQuery: e.target.value });

    handleSubmit = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const search = this.state.searchQuery;
      this.getSearchResults(search);

      history.push({
        search: `?search=${this.state.searchQuery}`
      })
    }

    handleItemSave = () => {
      this.setState({ loading: true });
      this.getData();
    }

    handleSort = orderBy => event => {
      let order = 'desc';
      if (this.state.orderBy === orderBy && this.state.order === 'desc') {
        order = 'asc';
      }
      this.setState({ loading: true, orderBy: orderBy, order: order });
      this.getSort(orderBy, order);

      history.push({
        search: `?sort=${orderBy},${order}`
      })

    }

    async getSort(orderBy, order) {
      const data = await fetchData('get', `/categories?sort=${orderBy},${order}`);
      this.setState({ loading: false, items: await data[0] });
    }

    handleDownload = (id) => {
      return async (e) => {
        e.preventDefault()
        downloadFile(`/utemplates/${id}/download`);
      }
    }

    render() {
        const { classes, match, lang, user } = this.props;
        const { items, page, rowsPerPage, total, order, orderBy } = this.state;

        const asAdmin = user && user.isAdmin;

        return (
          <div className={classes.root}>
            {this.state.loading && (
              <Loader />
            )}
            <Grid container spacing={24} alignItems='center'>
              <Grid item xs={9}>
                <Typography gutterBottom variant="h2" component="h2" className={classes.title}>
                  {I18n[lang].templates.templates}
                </Typography>
              </Grid>
              <Grid item xs={3} className={classes.alignRight}>
                {asAdmin && (
                  <Link component={Button} to={`/templates/create/`}>
                    <Button variant="contained" className="btn-create" color="primary">
                      <Add />
                      {I18n[lang].templates.createNew}
                    </Button>
                  </Link>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={24} alignItems='top'>
              {items
                .map(row => {
                return (
                  <Grid item xs={4}>
                    <Paper className={classes.paper} key={row.id}>
                      <h3 className={classes.h3}>{row.title}</h3>
                      <h5 className={classes.h5}>{row.subtitle}</h5>
                      <p>
                        <a href="#" onClick={this.handleDownload(row.id)}>{row.fileName}</a>
                      </p>
                      {asAdmin && (
                        <div className={classes.actions}>
                          <Link to={`/templates/edit/${row.id}`}>
                            <Edit/>
                            <span>{I18n[lang].common.edit}</span>
                          </Link>
                          <a onClick={() => this.deleteItem(row.id)} href="#" className="delete">
                            <i></i>
                            <span>{I18n[lang].common.delete}</span>
                          </a>
                        </div>
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <Route path="/templates/edit/:id" render={this.editItem} />
            <Route path="/templates/create" render={this.editItem} />
          </div>
        );
    }
}

export default withRoot(withStyles(styles)(Templates));
