import React from 'react';

class About extends React.Component {
    render() {
        return (
          <h1>About Page</h1>
        );
    }
}

export default About;
