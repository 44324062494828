import 'isomorphic-fetch';
//const API = require(document.location.port.length ? '../config.local' : '../config').API;
import { API } from '../config';

/**
 * @deprecated use DataFetcher instead
 */
export default async function fetchResetPassword(method, endpoint, body) {
  try {
    const response = await fetch(`${API}${endpoint}`, {
      method,
      body: body && JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        accept: 'application/json',
      },
    });
    const result = await response;
    return result.json();
  } catch (error) {
    console.error(error);
  }
}
