import React from "react";
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
    Hidden, ListItem, Card, CardContent, CardActions, List
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import fetchMultiPartData from "../../utils/fetchMultiPart";
import downloadFile from "../../utils/fetchFile";
import Loader from "../../components/loader";
import {Link, Route} from "react-router-dom";
import history from "../../utils/history";
import {find, clone} from "lodash";
import classNames from "classnames";
import ItemEdit from "./item-edit";
import "url-search-params-polyfill";
import {I18n} from "../../i18n/";
import {styles} from "./styles";
import {stateLabel} from "./utils";
import GIZButton from "../../components/GIZButton";

const tableTitles = [
    {id: "code", title: "code"},
    {id: "indicatorText", title: "indicatorText"},
    {id: "frequency", title: "frequency"},
    {id: "updatedAt", title: "updated"},
    {id: "currentValue", title: "currentValue", titleShort: "currentValueShort"},
    {id: "targetValue", title: "targetValue", titleShort: "targetValueShort"},
    {id: "status", title: "status"},
    {id: "manage", title: "manage"},
];

class Indicator extends React.Component {
    state = {
        loading: true,
        items: [],
        page: 0,
        total: 0,
        rowsPerPage: 25,
        order: "desc",
        orderBy: "",
        deleteItem: false,
    };

    componentDidMount() {
        this.getData();
    }

    async getData() {
        const data = await fetchData("get", "/indicators");
        this.setState({loading: false, items: data[0], total: data[1]});
    }

    async deleteItem(id) {
        const {lang} = this.props;
        if (
            window.confirm(
                `${I18n[lang].common.deleteConfirm} ${I18n[lang].header.indicators} ${id}?`
            )
        ) {
            await fetchData("delete", `/indicators/${id}`);
            this.getData();
        }
    }

    editItem = ({
                    match: {
                        params: {id},
                    },
                }) => {
        if (this.state.loading) return null;
        const item = find(this.state.items, {id: Number(id)});

        return (
            <ItemEdit
                item={item}
                handleItemSave={this.handleItemSave}
                user={this.props.user}
                lang={this.props.lang}
            />
        );
    };

    handleSort = (orderBy) => (event) => {
        let order = "desc";
        if (this.state.orderBy === orderBy && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({loading: true, orderBy: orderBy, order: order});
        this.getSort(orderBy, order);

        history.push({
            search: `?sort=${orderBy},${order}`,
        });
    };

    async getSort(orderBy, order) {
        const data = await fetchData("get", `/indicators?sort=${orderBy},${order}`);
        this.setState({loading: false, items: await data[0]});
    }

    handleItemSave = () => {
        this.setState({loading: true});
        this.getData();
    };

    handleIndicatorActivation = (item) => {
        return async (event) => {
            event.preventDefault();
            await fetchData(
                "post",
                `/indicators/${item.id}/${item.activated ? "deactivate" : "activate"}`
            );
            this.getData();
        };
    };

    changeIndicatorPosition = (item, direction) => {
        return async (event) => {
            event.preventDefault();
            await fetchData("post", `/indicators/${item.id}/move_${direction}`);
            this.getData();
        };
    };

    fileInputs = {};

    clickFileUpload = (item) => {
        return async (event) => {
            event.preventDefault();
            this.fileInputs[item.id].click();
        };
    };

    handleFileUpload = (item) => {
        return async (event) => {
            const file = event.target.files[0];
            if (file.size < 20 * 1024 * 1024) {
                var data = new FormData();
                data.set("dataFile", file);
                await fetchMultiPartData(
                    "put",
                    `/indicators/${item.id}/data_upload`,
                    data
                );
                this.getData();
            }
        };
    };

    handleDownload = (file) => {
        return async (e) => {
            e.preventDefault();
            downloadFile(file);
        };
    };

    render() {
        const {classes, lang, user} = this.props;
        const {items, order, orderBy} = this.state;
        const asEditor = user && user.isAdmin;
        const asGuest = user && user.authorities.indexOf("ROLE_GUEST") >= 0;

        let columnTitles = clone(tableTitles);
        if (!asEditor && !asGuest) {
            let manageTitle = find(columnTitles, {id: "manage"});
            const manageColumnIndex = columnTitles.indexOf(manageTitle);
            if (manageColumnIndex >= 0) {
                columnTitles.splice(manageColumnIndex, 1);
            }
        }
        return (
            <div className={classes.root}>
                {this.state.loading && <Loader/>}
                {this.state.deleteItem && (
                    <div className="modal">
                        <a className="close" onClick={this.handleClose}>
                            <Close/>
                        </a>
                        <div className="container">
                            <div className="modal">
                                <a className="close">
                                    <Close/>
                                </a>
                                <h3>Attention!</h3>
                                <p>Are you sure you want to delete this milestone?</p>
                                <Grid
                                    container
                                    spacing={24}
                                    alignItems="flex-end"
                                    className="buttons"
                                >
                                    <Grid item xs={12} className={classes.alignRight}>
                                        <Button
                                            variant="contained"
                                            className="button-submit"
                                            type="submit"
                                            color="primary"
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className="button-cancel"
                                            onClick={this.handleClose}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                )}

                <Grid container alignItems="center" className={classes.pagehead}>
                    <Grid item xs={12} md={7}>
                        <Typography
                            gutterBottom
                            variant="h2"
                            component="h2"
                            className={classes.h2}
                        >
                            Programme indicators
                        </Typography>
                    </Grid>
                    {(asEditor || asGuest) && (
                        <Hidden only={['xs', 'sm']}>


                            <Grid item xs={12} md={5} style={{display: "flex", justifyContent: "flex-end"}}>
                                <Link to={`/indicators/create/`}>
                                    <div className={classes.indexCreate}>
                                        <GIZButton color="primary" icon="Add" isRound>
                                            {I18n[lang].indicators.create}
                                        </GIZButton>
                                    </div>
                                </Link>
                            </Grid>

                        </Hidden>
                    )}
                </Grid>
                <div className={classes.tableWrapper}>
                    <Hidden mdUp>
                        <List>
                            {items &&
                                items.map &&
                                items.map((item) => {
                                    return (
                                        <ListItem>
                                            <Card classes={{root: classes.tableCard}}>
                                                <CardContent>
                                                    <table className={classes.cardTable}>

                                                        <tr>
                                                            <td className={classes.cardTableHeader}>
                                                                {I18n[lang].indicators.code}
                                                            </td>
                                                            <td className={classes.cardTableValue}>
                                                                        <span>
                                                                           {asEditor && item.fake ? "Dummy " : ""}
                                                                            {item.code}
                                                                        </span>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className={classes.cardTableHeader}>
                                                                {I18n[lang].indicators.indicatorText}
                                                            </td>
                                                            <td className={classes.cardTableValue}>
                                                                        <span>
                                                                             <div className="details-text">
                                                                                    {" "}
                                                                                 {item.jhiIndicator}{" "}
                                                                                </div>
                                                                        </span>
                                                            </td>
                                                        </tr>


                                                        <tr>
                                                            <td className={classes.cardTableHeader}>
                                                                {I18n[lang].indicators.status}
                                                            </td>
                                                            <td className={classes.cardTableValue}>
                                                                        <span>
                                                                          {item.status && (
                                                                              <div className={classes.selector}>
                                                                                  {stateLabel(item.status)}
                                                                              </div>
                                                                          )}
                                                                        </span>
                                                            </td>
                                                        </tr>


                                                    </table>
                                                </CardContent>

                                            </Card>
                                        </ListItem>)
                                })}
                        </List>
                    </Hidden>

                    <Hidden only={['xs', 'sm']}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {columnTitles.map((item, index) => {
                                        if (item.id === "updatedAt" || item.id === "status") {
                                            return (
                                                <TableCell key={index}>
                                                    <TableSortLabel
                                                        active={orderBy === item.id}
                                                        direction={order}
                                                        onClick={this.handleSort(item.id)}
                                                    >
                                                        {/*<Hidden lgUp>{I18n[lang].indicators[item.titleShort ?? item.title]}</Hidden>*/}
                                                        {/*<Hidden lgDown>{I18n[lang].indicators[item.title]}</Hidden>*/}

                                                        {I18n[lang].indicators[item.title]}
                                                    </TableSortLabel>
                                                </TableCell>
                                            );
                                        } else {
                                            return (
                                                <TableCell key={index}>
                                                    <Hidden lgUp>{I18n[lang].indicators[item.titleShort ?? item.title]}</Hidden>
                                                    <Hidden only={['xs', 'sm', 'md']}>{I18n[lang].indicators[item.title]}</Hidden>

                                                </TableCell>
                                            );
                                        }
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items ? items.map((item) => {
                                    return (
                                        <TableRow
                                            key={item.id}
                                            className={item.activated ? "" : "deactivated"}
                                        >
                                            <TableCell className="arrow-container">
                                                {asEditor && item.fake ? "Dummy " : ""}
                                                {item.code}
                                            </TableCell>
                                            <TableCell className="link-to">
                                                {(asEditor || asGuest) && (
                                                    <div style={{position: "relative"}}>
                                                        <a
                                                            className="arrow arrow-up"
                                                            onClick={this.changeIndicatorPosition(item, "up")}
                                                        ></a>
                                                        <a
                                                            className="arrow arrow-down"
                                                            onClick={this.changeIndicatorPosition(item, "down")}
                                                        ></a>
                                                    </div>
                                                )}
                                                {item.activated &&
                                                (!item.readOnly || item.restrictedView) ? (
                                                    <Link
                                                        to={`/indicator/${item.id}`}
                                                        className="details-text"
                                                    >
                                                        {item.jhiIndicator}
                                                    </Link>
                                                ) : (
                                                    <div className="details-text">
                                                        {" "}
                                                        {item.jhiIndicator}{" "}
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell>{item.frequency}</TableCell>
                                            <TableCell>{item.updatedAt}</TableCell>
                                            <TableCell>{item.currentValues.join(", ")}</TableCell>
                                            <TableCell>{item.targetValues.join(", ")}</TableCell>
                                            <TableCell className={classNames(classes.actions, "act")}>
                                                {item.status && (
                                                    <div className={classes.selector}>
                                                        {stateLabel(item.status)}
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell className={classNames(classes.actions, "act")}>
                                                <div className="buttons">
                                                    {(asEditor ||
                                                        (!item.readOnly && !item.restrictedView)) && (
                                                        <Link
                                                            to={`/indicators/edit/${item.id}`}
                                                            title="Edit Indicator"
                                                            className="edit"
                                                        ></Link>
                                                    )}
                                                    {(asEditor || asGuest) && (
                                                        <a
                                                            className={
                                                                item.activated ? "deactivate" : "activate"
                                                            }
                                                            title={`${
                                                                item.activated ? "Deactivate" : "Activate"
                                                            } Indicator`}
                                                            onClick={this.handleIndicatorActivation(item)}
                                                        ></a>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                                    : (<h3>Please reload</h3>)}
                            </TableBody>
                        </Table>
                    </Hidden>
                </div>
                <Route path="/indicators/edit/:id" render={this.editItem}/>
                <Route path="/indicators/create" render={this.editItem}/>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(Indicator));
