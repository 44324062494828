import React from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../../withRoot";
import fetchData from "../../../utils/fetch";
import fetchMultiPartData from "../../../utils/fetchMultiPart";
import { Redirect } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { I18n } from "../../../i18n/";
import classNames from "classnames";
import { metricValue, metricArray } from "../utils";
import { API } from "../../../config";
import FileUploadProgress from "../../../components/file-upload-progress";

const styles = (theme) => ({
  root: {
    textAlign: "left",
    width: "100%",
    margin: "30px 3%",
    "& .df": {
      display: "flex",
    },
    "& .mlr12": {
      "& a": {
        marginLeft: "12px",
        marginRight: "12px",
      },
    },
    "&.close": {
      color: "#b4c3d1",
      "& svg": {
        fill: "#b4c3d1",
      },
    },
  },
  close: {
    top: "-20px !important",
    right: "-25px !important",
    cursor: "pointer !important",
    position: "relative !important",
    float: "right !important",
    "& svg": {
      color: "#b4c3d1",
    },
  },
  blockInfo: {
    display: "flex",
    "& .blockInfo__item": {
      flex: "0 0 20%",
      padding: "12px 50px",
      [theme.breakpoints.down(1700)]: {
        padding: "12px 25px",
      },
      [theme.breakpoints.down(1440)]: {
        padding: "12px 10px",
      },
    },
    "& .blockInfo__item:first-child": {
      padding: "12px 50px 12px 0",
      [theme.breakpoints.down(1700)]: {
        padding: "12px 25px 12px 0",
      },
      [theme.breakpoints.down(1440)]: {
        padding: "12px 10px 12px 0",
      },
    },
    "& .blockInfo__item:last-child": {
      padding: "12px 0 12px 50px",
      [theme.breakpoints.down(1700)]: {
        padding: "12px 0 12px 25px",
      },
      [theme.breakpoints.down(1440)]: {
        padding: "12px 0 12px 10px",
      },
    },
    "& .measurement": {
      "& > div": {
        paddingBottom: "5px",
      },
    },
  },
  subtitle: {
    fontWeight: "300",
    color: "#6495ed",
    letterSpacing: "0.4px",
    marginRight: "18px",
  },
  overFlowHid: {
    overflow: "hidden",
    "& textarea": {
      overflow: "hidden",
    },
    "& > div": {
      paddingBottom: "5px !important",
    },
  },
  modal: {
    position: "initial",
    margin: "0 auto",
    marginTop: "4%",
    width: "96%",
    maxHeight: "850px",
    backgroundColor: "#f7f9fb",
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    height: "90vh",
    overflowY: "auto",
    borderRadius: "10px",
    "&:focus": {
      outline: 0,
    },
    "& h2": {
      textAlign: "center",
      fontSize: "37px",
      textTransform: "uppercase",
      color: "#88ADF0",
      marginBottom: "40px !important",
      letterSpacing: "1.1px",
    },
    "& label": {
      fontSize: "19pt",
      marginBottom: "20px",
    },
    "& .text-area": {
      marginBottom: "40px",
      "& > div": {
        padding: "10px 0px 10px 15px",
      },
      "& textarea": {
        overflow: "auto",
        "-ms-overflow-style": "none !important",
      },
      "& label": {
        transform: "translate(0, 0) scale(1)",
        marginBottom: "20px",
        fontSize: "19pt",
        fontWeight: "300",
        color: "#6495ed",
        letterSpacing: "0.4px",
      },
    },
  },
  buttons: {
    textAlign: "right",
    "& .button-submit": {
      marginRight: "0",
      height: "47px",
    },
  },
  flexCCC: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  textField: {
    width: "100%",
    marginBottom: "40px",
    backgroundColor: "#E1EDFB",
  },
  buttonCancel: {
    marginRight: "20px",
  },
  block: {
    "& .line": {
      minHeight: "64px",
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
      "& .text-field": {
        minWidth: "85px",
        maxWidth: "90px",
        marginRight: "10px",

        "& input": {
          maxWidth: "40px",
          textAlign: "center",
          padding: "17px 25px",
        },
      },
      "& span": {
        color: "#191970",
        fontSize: "12px",
      },
    },
    "& .btn": {
      display: "block",
      width: "45px",
      height: "45px",
      borderRadius: "6px",
      background: "#c3bdd2 no-repeat center center",
      "&:hover": {
        background: "#958DAE no-repeat center center",
      },
      // marginTop: '30px',
      "&.btn-word": {
        backgroundImage: "url(/images/icons/icon-word.png)",
        border: "3px solid #fe8368",
        position: "relative",
        marginLeft: "12px",
        marginRight: "12px",
        "& img": {
          position: "relative",
          top: "-9px",
          left: "30px",
        },
        "& i": {
          display: "block",
          color: "#ffffff",
          borderRadius: "50%",
          background: "#fe8368",
          position: "absolute",
          top: "-7px",
          right: "-7px",
          fontSize: "14px",
          fontWeight: "900",
          textAlign: "center",
          lineHeight: "14px",
          width: "14px",
        },
      },
      "&.btn-upload": {
        width: "45px",
        height: "45px",
        margin: "0",
        background: "#77d2b7 no-repeat center center / 50px 50px",
        transform: "rotate(180deg)",
        backgroundImage: "url(/images/icons/icon-download.svg)",
        cursor: "pointer",
        marginLeft: "12px",
        marginRight: "12px",
        "&:hover": {
          background: "#9bd9c8 no-repeat center center / 50px 50px",
          backgroundImage: "url(/images/icons/icon-download.svg)",
        },
      },
    },
  },
});

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class KeyData extends React.Component {
  state = {
    open: true,
    item: null,
    files: [],
    fileUploadModal: false,
    fileUploadStatus: "",
  };

  componentDidMount() {
    const indicator = this.props.indicator;
    const item = indicator.keyDatum;
    metricArray(indicator).map((el, i) => {
      const num = i + 1;
      item[`baselineValue${num}`] = metricValue(
        indicator,
        item[`baselineValue${num}`],
        i
      );
      item[`targetValue${num}`] = metricValue(
        indicator,
        item[`targetValue${num}`],
        i
      );
    });
    this.setState({ item: item });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    this.setState({ fileUploadModal: true, fileUploadStatus: "success" });
    this.uploadFiles();
  };

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/indicator/${this.props.indicator.id}`} />;
    }
  };

  saveItem = async (values) => {
    this.setState({ fileUploadModal: true });
    const indicator = this.props.indicator;
    var data = new FormData();

    data.set("indicatorId", indicator.id);
    data.set("id", values.id);

    [
      "measurement",
      "explanatoryNotes",
      "indicatorDefinition",
      "externalSupport",
      "measurementFrequency",
    ].forEach((x) => {
      data.set(x, values[x] || "");
    });

    [
      "targetValue1",
      "targetValue2",
      "targetValue3",
      "targetValue4",
      "baselineValue1",
      "baselineValue2",
      "baselineValue3",
      "baselineValue4",
    ].forEach(function (x) {
      let value = values[x];
      data.set(x, value === undefined || value === "N/A" ? null : value);
    });

    //const filesCount = this.fileInput.files.length;
    //for(let i = 0; i < filesCount; i++){
    //  data.append('files[]', this.fileInput.files[i])
    //}

    await fetchMultiPartData(
      "put",
      `/measurements/key_data/${values.id}`,
      data
    );

    this.props.handleItemSave();
  };

  onSubmit = async (values) => {
    this.setState({ fileUploadStatus: "loading" });
    this.saveItem(values);
    this.setState({ fileUploadStatus: "success" });
    await sleep(3000).then(() => this.setState({ open: false }));
  };

  handleRemoveFile = (file) => {
    const lang = this.props.lang;
    return async (event) => {
      if (
        window.confirm(
          `${I18n[lang].common.deleteConfirm}${I18n[
            lang
          ].common.file.toLowerCase()} ${file.name}?`
        )
      ) {
        event.currentTarget.remove();
        await fetchData(
          "delete",
          `/measurements/key_data/attachments/${file.id}`
        );
      }
    };
  };

  handleRemoveNewFile = (e, nameFiles) => {
    var removeByAttr = function (arr, attr, value) {
      var i = arr.length;
      while (i--) {
        if (arr[i] && arguments.length > 2 && arr[i][attr] === value) {
          arr.splice(i, 1);
        }
      }
      return arr;
    };
    this.setState({ files: removeByAttr(this.state.files, "name", nameFiles) });
  };

  onFilesAdded = (evt) => {
    const files = evt.target.files;
    const array = [];
    for (var i = 0; i < files.length; i++) {
      array.push(files.item(i));
    }
    this.setState((prevState) => ({
      files: prevState.files.concat(array),
    }));

    this.setState({
      fileUploadModal: false,
      files: this.state.files.concat(array),
    });
  };

  async uploadFiles() {
    const promises = [];
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file));
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      this.setState({ fileUploadStatus: "failed" });
    }
  }

  sendRequest = (file) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      const indicatorID = this.state.item.id;

      req.upload.addEventListener("load", (event) => {
        this.setState({ fileUploadStatus: "loading" });
        resolve(req.response);
      });

      req.upload.addEventListener("error", (event) => {
        this.setState({ fileUploadStatus: "failed" });
        reject(req.response);
      });

      req.onreadystatechange = () => {
        this.setState({ fileUploadStatus: "success" });
        if (req.readyState === 4) {
          const response = JSON.parse(req.response);
          let currentItem = this.state.item;
          currentItem.files = response.files;
          this.setState({ item: currentItem });
        }
      };

      const formData = new FormData();
      formData.append("files[]", file, file.name);
      req.open("PUT", `${API}/measurements/key_data/${indicatorID}`);
      req.setRequestHeader("Authorization", localStorage.getItem("TOKEN"));
      req.send(formData);
      this.props.handleItemSave();
    });
  };

  render() {
    const { classes, indicator, lang } = this.props;
    const { item } = this.state;
    if (!item) {
      return "";
    }
    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <a
              className={classNames("close", classes.close)}
              onClick={this.handleClose}
            >
              <Close />
            </a>
            <Typography gutterBottom variant="h2" component="h2">
              ENTER OR EDIT KEY DATA
            </Typography>

            <Typography component="div" className={classes.data}>
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item sm={12}>
                        <div className={classes.blockInfo}>
                          <div className="blockInfo__item">
                            <div className={classes.block}>
                              <InputLabel className={classes.subtitle}>
                                Baseline values
                              </InputLabel>

                              {metricArray(indicator).map((el, i) => {
                                const field = `baselineValue${i + 1}`;
                                return (
                                  <div className="line" key={i}>
                                    <Field name={field}>
                                      {({ input }) => (
                                        <TextField
                                          margin="normal"
                                          className="text-field"
                                          variant="outlined"
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                    <span>
                                      {
                                        indicator[
                                          `measurementDescription${i + 1}`
                                        ]
                                      }
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="blockInfo__item">
                            <Field name="measurement">
                              {({ input }) => (
                                <TextField
                                  label="Measurement"
                                  multiline
                                  rows="6"
                                  rowsMax="20"
                                  className="text-area measurement"
                                  variant="outlined"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>

                          <div className="blockInfo__item">
                            <div className={classes.block}>
                              <InputLabel className={classes.subtitle}>
                                Target value
                              </InputLabel>
                              {metricArray(indicator).map((el, i) => {
                                const field = `targetValue${i + 1}`;
                                return (
                                  <div className="line" key={i}>
                                    <Field name={field}>
                                      {({ input }) => (
                                        <TextField
                                          margin="normal"
                                          className="text-field"
                                          variant="outlined"
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                    <span>
                                      {
                                        indicator[
                                          `measurementDescription${i + 1}`
                                        ]
                                      }
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="blockInfo__item">
                            <Field
                              name="explanatoryNotes"
                              className="explanatory-notes"
                            >
                              {({ input }) => (
                                <TextField
                                  label="Explanatory notes"
                                  multiline
                                  rows="13"
                                  rowsMax="20"
                                  className={classNames(
                                    "text-area",
                                    classes.overFlowHid
                                  )}
                                  variant="outlined"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                          <div className="blockInfo__item">
                            <Field name="measurementFrequency">
                              {({ input }) => (
                                <TextField
                                  label="Frequency of measurement"
                                  margin="normal"
                                  multiline
                                  rows="1"
                                  rowsMax="20"
                                  className="text-area"
                                  variant="outlined"
                                  style={{ marginTop: 0 }}
                                  {...input}
                                />
                              )}
                            </Field>
                            <Field name="externalSupport">
                              {({ input }) => (
                                <TextField
                                  label="External support"
                                  multiline
                                  rows="4"
                                  rowsMax="20"
                                  className="text-area"
                                  variant="outlined"
                                  {...input}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={24} alignItems="flex-start">
                      <Grid item sm={12}>
                        <div
                          className={classNames(
                            classes.block,
                            classes.flexCCC,
                            "df",
                            "mlr12"
                          )}
                        >
                          <InputLabel className={classes.subtitle}>
                            Upload files
                          </InputLabel>

                          {this.state.item &&
                            this.state.item.files.map((file, i) => {
                              return (
                                <a
                                  href={undefined}
                                  key={i}
                                  title={`Delete '${file.name}'`}
                                  className="btn btn-word"
                                  onClick={this.handleRemoveFile(file)}
                                >
                                  <img
                                    src="/images/icons/icon-delete.png"
                                    alt="delete"
                                  />
                                </a>
                              );
                            })}
                          {this.state.files.length > 0 &&
                            this.state.files.map((file, i) => {
                              return (
                                <a
                                  href={undefined}
                                  key={i}
                                  title={`New file '${file.name}'`}
                                  className="btn btn-word"
                                  onClick={(e) =>
                                    this.handleRemoveNewFile(e, file.name)
                                  }
                                >
                                  <img
                                    src="/images/icons/icon-delete.png"
                                    alt="delete"
                                  />
                                </a>
                              );
                            })}
                          <label
                            className="btn btn-upload"
                            htmlFor="fileInput"
                          />
                          <input
                            type="file"
                            name="fileInput"
                            id="fileInput"
                            //ref={(reference) => this.fileInput = reference }
                            multiple
                            style={{ display: "none" }}
                            onChange={this.onFilesAdded}
                          />
                        </div>
                      </Grid>

                      <Grid item sm={12}>
                        <Field name="indicatorDefinition">
                          {({ input }) => (
                            <TextField
                              label="Indicator definition"
                              multiline
                              rows="4"
                              rowsMax="20"
                              className="text-area"
                              variant="outlined"
                              {...input}
                            />
                          )}
                        </Field>
                      </Grid>
                    </Grid>

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.buttons}>
                        <Button
                          variant="contained"
                          className="button-cancel"
                          onClick={this.handleClose}
                        >
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>

                        <Button
                          variant="contained"
                          className="button-submit"
                          type="submit"
                          color="primary"
                          onClick={this.handleSubmit}
                        >
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
        {this.state.fileUploadModal && (
          <FileUploadProgress status={this.state.fileUploadStatus} />
        )}
      </>
    );
  }
}

export default withRoot(withStyles(styles)(KeyData));
