import metaFetch from './metaFetch';

/**
 * @deprecated use DataFetcher instead
 */
export default async function fetchData(method, endpoint, body) {
  return metaFetch(endpoint, {
    method,
    body: body && JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
      'Authorization': localStorage.getItem('TOKEN')
    }
  }, (result, json) => {
    const numberOfResults = result.headers.get('X-total');
    const searchHeaders = {
      participantsCount: result.headers.get('X-Participants-Count'),
      phonesCount: result.headers.get('X-Distinct-Phones-Count'),
      namesCount: result.headers.get('X-Distinct-Names-Count')
    };
    return [json, parseInt(numberOfResults), searchHeaders];
  })
}
