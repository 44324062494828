export default {
  total_events: {
    label: 'Total events',
    type: 'number'
  },
  event_formats: {
    label: 'Event Formats',
    type: 'distribution',
    entitiesPath: '/formats'
  },
  total_participations: {
    label: 'Total participations',
    type: 'number',
    participants: true
  },
  total_participants: {
    label: 'Total participants',
    type: 'number',
    participants: true
  },
  employer_types: {
    label: 'Employer type',
    type: 'distribution',
    entitiesPath: '/employer_types',
    participants: true
  },
  employer_levels: {
    label: 'Employer level',
    type: 'distribution',
    entitiesPath: '/employer_levels',
    participants: true
  },
  event_results: {
    label: 'Results by events',
    type: 'distribution',
    entitiesPath: '/categories/results'
  },
  participant_results: {
    label: 'Results by event participants',
    type: 'distribution',
    entitiesPath: '/categories/results',
    participants: true
  },
  so1d: {
    label: 'SO1d – Adjustment of internal procedures',
    type: 'number'
  },
  r2a: {
    label: 'R2a – Introduction of taxes/fees',
    type: 'number'
  },
  r3b: {
    label: 'R3b – Good practices',
    type: 'entity',
    entityPath: '/good-practices/view'
  },
  r6a: {
    label: 'R6a – Dialogue Events with Civil Society',
    type: 'number'
  },
  r6b: {
    label: 'R6b – Satisfaction % of dialogue events',
    type: 'number'
  },
  startup: {
    label: 'Start-up package',
    type: 'number'
  },
  leader_steps: {
    label: 'Steps for Leaders',
    type: 'distribution',
    entitiesPath: '/thematic_modules?without_package=true'
  },
  special_steps: {
    label: 'Steps for Specialist',
    type: 'distribution',
    entitiesPath: '/thematic_modules?without_package=true'
  },
  sector_decentr_ah_outreach: {
    label: 'AH outreach',
    legendLabel: '[Sector Decentralization] AH outreach',
    type: 'distribution',
    entitiesPath: '/target_groups/education_or_healthcare'
  },
  sector_decentr_events: {
    label: 'Events',
    legendLabel: '[Sector Decentralization] Events',
    type: 'distribution',
    entitiesPath: '/target_groups/education_or_healthcare'
  },
  sector_decentr_participants: {
    label: 'Participants',
    legendLabel: '[Sector Decentralization] Participants',
    type: 'distribution',
    entitiesPath: '/employer_types/education_or_healthcare',
    participants: true
  }
}
