import metaFetch from './metaFetch';
import FileSaver from 'file-saver';
import 'isomorphic-fetch';

/**
 * @deprecated use DataFetcher instead
 */
export default async function fetchFile(endpoint) {
  return metaFetch(endpoint, {method: 'GET', responseType: 'blob'},
    (result, blob) => {
      let filename = result.headers.get('content-disposition');
      if (filename){
        let m = filename.match(/filename="(.*)";/)
        if (m){
          FileSaver.saveAs(blob, m[1])
        }
      }

      return filename;
    }
  )
}
