import React from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
  checkbox: {
    '& .checkbox': {
      width: '20px',
      height: '20px',
      borderRadius: '5px',
      backgroundColor: '#E1EDFB',
      '&--checked': {
        backgroundImage: 'url(/images/icons/icon-check-mark.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      }
    }
  },
});


class CheckboxWithLabel extends React.Component {
  state = {
    isActivated: false
  }

  componentDidMount() {
    this.setState({ isActivated: (this.props.initialCheck || false) });
  }

  handleCheckBoxChange = () => {
    this.setState({ isActivated: !this.state.isActivated })
  }

  render() {
    const {
      classes,
      label,
      value,
      input
    } = this.props;
    const { isActivated } = this.state;

    if (!value) { value = 'true' }

    return (
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            className={classes.checkbox}
            value={value}
            checked={isActivated}
            onClick={this.handleCheckBoxChange}
            icon={<div className="checkbox"></div>}
            checkedIcon={<div className="checkbox checkbox--checked"></div>}
            {...input}
          />
        }
      />
    );
  }
}

export default withStyles(styles)(CheckboxWithLabel);
