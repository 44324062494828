import "isomorphic-fetch";
//const API = require(document.location.port.length ? '../config.local' : '../config').API;
import { API } from "../config";
import * as Sentry from "@sentry/react";

/**
 * @deprecated use DataFetcher instead
 */
export default async function metaFetch(endpoint, fetchOpts, onSuccess) {
  try {
    if (!fetchOpts) fetchOpts = {};
    if (!fetchOpts.headers) fetchOpts.headers = {};

    fetchOpts.headers["Authorization"] = localStorage.getItem("TOKEN");

    let requestUrl = endpoint.match(/^http/) ? endpoint : `${API}${endpoint}`;
    const response = await fetch(requestUrl, fetchOpts);

    const result = await response;
    if (result.status === 401) {
      window.location.href = "/login";
    }

    if (result.status === 403) {
      alert("Access denied");
      return [null, 0];
    }

    if (result.status === 500) {
      Sentry.withScope((scope) => {
        scope.setExtra("response", result);
        Sentry.captureException("API result 500");
      });
      alert("Server-side error");
      return [null, 0];
    }
    if (onSuccess) {
      let content = null;
      const contentType = result.headers.get("content-type");
      if (contentType === "application/json") {
        content = await result.json();
      } else if (result.blob) {
        content = await result.blob();
        if (result.status === 303) {
          alert(
            "Generating report will take signinficant time. We will send the file to your email."
          );
        }
      }
      return onSuccess(result, content);
    }
    return result;
  } catch (error) {
    console.error(error);
  }
}
