import {Breakpoints} from "../../config";

export const styles = theme => ({
    root: {
        textAlign: 'left',
        padding: '30px 0 0 0',
        background: '#f7f9fb',
        width: '100%',
        margin: '0',

        '& div': {
            boxShadow: 'none',
        },
        '& h2': {
            color: '#fdbb2d',
            letterSpacing: '2px',
            margin: '0 0 10px 38px !important',
            [theme.breakpoints.down(Breakpoints.md)]: {
                marginBottom: "10px!important",
                marginLeft: "20px!important",
                marginTop:"33px!important",
            },
        },
        '& .block-measurement .btn .btn-word': {
            background: '#958DAE no-repeat center center !important'
        }
    },
    cardTableHeader: {
        fontWeight: 900,
        verticalAlign: "top",
    },
    cardTableValue: {
        "& a":
            {
                textDecoration: "none",
                color: "#191970",
            }
    },
    btnCreate: {
        width: '190px',
        height: '45px',
        marginTop: '-15px',
        marginRight: '1.3vw !important',
        padding: '4px 25px 6px 0px !important',
        minWidth: '240px',

        '& span': {
            letterSpacing: '0.4px',
            fontWeight: '400'
        },
    },
    pagehead: {
        width: '100%',
        margin: '-13px 0 0 -2px',
        [theme.breakpoints.down(Breakpoints.md)]: {
            marginTop: "40px",
            marginBottom: "0"
        },
    },
    tableWrapper: {
        overflowX: 'hidden',
    },
    table: {
        maxWidth: '100%',
        textAlign: 'center',

        '& tbody tr': {
            verticalAlign: 'middle'
        },
        '& tbody td': {
            padding: '12px',
            textAlign: 'left',
            verticalAlign: "top",
            color: '#778899',
            fontSize: '16px',

            '&:last-child': {
                '&.act': {
                    padding: '10px',
                }
            },
            '&:first-child': {
                paddingLeft: '38px',
                color: '#191970',
            },
            '&:nth-child(2)': {
                width: '100%',
                textAlign: 'left',
                paddingLeft: '2.9vw',
                '& a': {
                    lineHeight: '18px',
                }
            },
            '&.act': {
                padding: '10px',
            },
            '&.link-to': {
                display: 'flex'
            }
        },

        '& thead th': {
            textAlign: 'center',
            padding: '4px 12px',
            fontSize: 16,
            letterSpacing: '0.4px',
            fontWeight: "500",
        },
        '& thead th:first-child': {
            paddingLeft: '38px',
        },
        '& .arrow-container': {
            position: 'relative'
        },
        '& .arrow': {
            display: 'block',
            position: 'absolute',
            right: '-30px',
            width: '22px',
            height: '10px',
            background: 'url(/images/icons/icon-arrow-green.svg) no-repeat center center / cover',
            cursor: 'pointer',
            '&.arrow-down': {
                transform: 'rotate(180deg)',
                bottom: '0',
                top: 'auto'
            },
            '&.arrow-up': {
                top: '0'
            }
        },
        '& .deactivated': {
            backgroundColor: 'rgba(167, 169, 172, 0.4) !important',
            '& td': {
                color: 'rgba(0, 0, 0, 0.5)',
            },
            '& .arrow': {
                background: 'url(/images/icons/icon-arrow-grey.svg) no-repeat center center / cover',
            },
            '& .selected-value': {
                backgroundColor: '#A7A9AC'
            },

            '& .buttons': {
                backgroundColor: '#A7A9AC',
                '& .reload, & .download, & .upload': {
                    backgroundColor: '#A7A9AC',
                },
                '& .edit': {
                    backgroundColor: '#A7A9AC',
                }
            }
        },
        '& .details-text': {
            color: '#191970',
            paddingLeft: '32px',
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    alignRight: {
        textAlign: 'right',
    },
    textField: {
        width: '100%',
        marginBottom: '20px'
    },
    selector: {
        position: 'relative',
        '& .on': {
            backgroundColor: '#77D2B7'
        },
        '& .on:hover': {
            backgroundColor: '#9bd9c8'
        },
        '& .minor': {
            backgroundColor: '#DDD330'
        },
        '& .minor:hover': {
            backgroundColor: '#f8d95d'
        },
        '& .major': {
            backgroundColor: '#fcba2b'
        },
        '& .major:hover': {
            backgroundColor: '#f8d95d'
        },
        '& .off': {
            backgroundColor: '#FE8368'
        },
        '& .off:hover': {
            backgroundColor: '#f67a5f'
        },
        '& .not': {
            backgroundColor: '#A7A9AC'
        },
        '& .not:hover': {
            backgroundColor: '#A7A9AC'
        },
        '& .selected-value': {
            fontSize: '14px',
            color: '#fff',
            height: '40px',
            width: '123px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '6px',
            cursor: 'pointer',
            [theme.breakpoints.down(Breakpoints.md)]: {
                height: '32px',
                width: '100px',
            },
            '&:hover + .list': {
                display: 'block'
            }
        },
        '& .list': {
            display: 'none',
            position: 'absolute',
            borderRadius: '6px',
            top: '47px',
            overflow: 'hidden',
            zIndex: '2',
            '& a': {
                fontSize: '11px',
                color: '#fff',
                height: '47px',
                width: '123px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            },
            '&:hover': {
                display: 'block'
            }
        }
    },
    actions: {
        '& .buttons': {
            borderRadius: '6px',
            overflow: 'hidden',
            display: 'inline-flex',
            justifyContent: 'center',
            '& a': {
                height: '47px',
                width: '61px',
                color: '#fff',
                fontSize: '11px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                [theme.breakpoints.down(1700)]: {
                    height: '40px',
                    width: '50px',
                },
                '&.edit': {
                    background: '#6495ED url(/images/icons/outline_edit_white_36dp.png) no-repeat center center / 16px 16px',
                },
                '&.edit:hover': {
                    background: '#5486e0 url(/images/icons/outline_edit_white_36dp.png) no-repeat center center / 16px 16px',
                },
                '&.deactivate': {
                    background: '#A7A9AC url(/images/icons/icon-delete.svg) no-repeat center center / 26px 26px',
                },
                '&.reload': {
                    background: '#ADD8E6 url(/images/icons/icon-reload.svg) no-repeat center center / 52px 52px',
                },
                '&.download': {
                    background: '#77D2B7 url(/images/icons/icon-download.svg) no-repeat center center / 52px 52px',
                },
                '&.upload': {
                    transform: 'rotate(180deg)',
                    background: '#add8e6 url(/images/icons/icon-download.svg) no-repeat center center / 52px 52px',
                },
                '&.activate': {
                    borderRadius: '6px',
                    background: '#FDBB2D url(/images/icons/icon-activate.svg) no-repeat center center / 20px 20px',
                }
            }
        }
    },
    modal: {
        '& .container': {
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(119, 136, 153, 0.6)',
            zIndex: '999',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .modal': {
            position: 'relative',
            width: '760px',
            height: '440px',
            borderRadius: '10px',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column'
        },
        '& .close': {
            fontSize: '16px',
            color: '#999',
            display: 'block',
            position: 'absolute',
            top: '15px',
            right: '15px'
        },
        '& h3': {
            fontSize: '37px',
            color: '#FE8368',
            textTransform: 'uppercase',
            textAlign: 'center',
            margin: '20px 0 0'
        },
        '& p': {
            fontSize: '30px',
            fontWeight: '100',
            color: '#FE8368',
            textAlign: 'center',
            marginTop: '75px'
        },
        '& .buttons': {
            flexGrow: '1',
            marginBottom: '35px'
        }
    },
    indexCreate: {
        textAlign: "right",
        paddingRight: "26px",
        [theme.breakpoints.down(Breakpoints.md)]: {
            paddingRight: "0",
            marginBottom: "12px",
            marginRight: "16px"
        },
        "& a button.btn-create": {
            boxShadow: "none",
            padding: "10px 15px",
            borderRadius: "2rem",
            background: "#6495ed",
            marginRight: "0",
            "&:hover": {
                background: "#5486e0",
            },
            "& svg": {
                width: "1.2em",
                height: "1.2em",
                marginRight: "5px",
            },
        },

    },
});
