import ItemService from "./ItemService";
import {Endpoints} from "../config.js";
import {each} from "lodash";

class IndicatorRegionsService extends ItemService {
    constructor(onUpdateState, lang, user) {
        super(Endpoints.IndicatorRegions.path, onUpdateState, lang, user);
    }

    UpdateStateFromItem(state, item) {
        state = super.UpdateStateFromItem(state, item);

        state = this.GetTotalsForRegions(state, item);
        return state;
    }

    GetTotalsForRegions(state, data) {
        const regions = data.regions.map((item) => ({
            name: this._isEn ? item.Name_en : item.Name_ua,
            value: item.QuantitativeValue,
            ...item,
        }));

        regions.sort((a, b) =>  a.name > b.name && 1 || -1);

        return {...state, regions};
    }

    async Action(e, state) {
        state = this.UpdateRegionValue(e, state)
        state = this.SetStateChanged(state);
        return state;
    }

    UpdateRegionValue(e, state) {
        const targetRegionIndex = state.regions.findIndex(x => x.Id == e.regionId);

        if(targetRegionIndex !== -1)
        {
            state.regions[targetRegionIndex].value = e.value;
            return {...state,regions:state.regions};
        }
        {
            return {...state}
        }
    }

    MapStateToModel(state) {
        const data =
            {
                id: state.id,
                regions: state.regions.map((r) => (
                    {
                        id: r.Id,
                        quantitativeValue: r.value
                    }))
            };

        return data;
    }
}

export default IndicatorRegionsService;
