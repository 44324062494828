import React from "react";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";

const styles = (theme) => ({
  root: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: "99999",
  },
  progress: {},
});

class Loader extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Loader));
