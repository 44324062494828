import { relative } from "path";
import { Breakpoints } from "../../config";
      
export const styles = theme => ({
  root: {
    textAlign: 'left',
    padding: '30px 3%',
    background: '#f7f9fb',
    width: '100%',
    margin: '0',
    position: 'relative',
    '&.hidden':{
      overflow: 'hidden',
    },
    '& .pl-135': {
      paddingLeft: '135px',
    },
    '& .pl-410': {
      paddingLeft: '410px',
    },
    '& .pr-52': {
      paddingRight: '52px',
    },
    '& .pl-0': {
      paddingLeft: '0',
    },
    '& div': {
      boxShadow: 'none',
    },
    '&.p0': {
      padding: '0',
    },
    '& .p0': {
      padding: '0',
    },
    '&.df': {
      display: 'flex',
    },
    '& .jce': {
      justifyContent: 'flex-end',
    },
    '& h3': {
      fontSize: '3vh !important',
      lineHeight: '1 !important',
      letterSpacing: '2pt',
      color: '#fdbb2d'
    },
    '& h1.sidebar-header': {
      backgroundColor: '#6495ed',
    },
    '& .sidebar-tabs>ul>li.active': {
      backgroundColor: '#6495ed',
    },
    '& .sidebar-tabs>ul>li>a': {
      display: 'flex',
      color: 'inherit',
      textDecoration: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '40px',
    },
    '& .sidebar.collapsed':{
      width: '44px',
    },
    '& .sidebar-left .sidebar-close':{
      right: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .leaflet-touch .leaflet-bar a':{
      lineHeight: '1.1',
    },
    '& .leaflet-popup-content':{
      margin: '0',
      width: '100% !important',
      fontWeight: '600',
    },
    '& .employer-type':{
      color: '#6495ed',
      letterSpacing: '0.6px',
      textTransform: 'uppercase',
      display: 'inline'
    },
    '& .leaflet-popup-content-wrapper':{
      background: 'white',
      color: '#191970',
      letterSpacing: '1px',
      border: '1px solid grey',
      padding: '13px 19px',
      width: '228px',
      width: '228px',
      '& h4':{
        fontSize: '18px',
        fontWeight: '800',
        letterSpacing: '0.9px',
        color: '#fdbb2d',
        margin: '-6px 0 6px',
        fontFamily: 'Montserrat',
      },
      '& b':{
        color: '#6495ed',
        letterSpacing: '0.6px',
        fontSize: '12px',
      },
      '& div a':{
        color: '#6495ed',
        textDecoration: 'underline',
        '&:hover':{
          color: '#191970',
        }
      },
    },
    '& .leaflet-popup-tip-container':{
      position: 'relative',
      borderTop: '0px solid white',
      zIndex: '1',
      top: '-1px',
    },
    '& .leaflet-popup-tip':{
      border: '1px solid grey',
    },
    '& .color-filter':{
      paddingRight: '2.1vw',

      [theme.breakpoints.down('1440px')]: {
        padding: '0 12px',
      },
    },
    '& .leaflet-container a.leaflet-popup-close-button':{
      top: '0px',
      right: '2px',
    },
    '& .leaflet-right .leaflet-control':{
      color: 'white',
      marginRight: '2.1vw',
      borderRadius: '6px',
      background: '#6495ed',
      border: '0',
      '& h4':{
        color: 'white',
      },
      '& button':{
        background: '#6495ed',
        border: '0',
        height: '31px',
        width: '71px',
        color: 'white',
        fontSize: '12px',
        borderRadius: '6px',
        '&:hover':{
          background: '#5486e0',
        },
      },
    },
    '& .leaflet-control-easyPrint':{
      position: 'relative',
      left: '125px',
      top: '-70px',
      border: '0 !important',
      borderRadius: '6px'
    },
    '& .leaflet-control-easyPrint-button-export':{
      backgroundImage: 'url(./images/icons/print-icon.png)',
      backgroundColor:'#a0abb7',
      width: '42px !important',
      height: '32px !important',
      borderRadius: '6px !important',
      '&:hover':{
        backgroundColor: '#778899',
      }
    },
    '& .CurrentSize':{
      backgroundImage: 'url(./images/icons/current-size-print.png) !important',
      backgroundColor:'#a0abb7',
      width: '42px !important',
      height: '32px !important',
      backgroundSize: 'auto',
      '&:hover': {
        backgroundColor: '#778899',
      }
    },
    '& .A4Landscape':{
      backgroundImage: 'url(./images/icons/a4-print.png) !important',
      backgroundColor:'#a0abb7',
      width: '42px !important',
      height: '32px !important',
      transform: 'none !important',
      '&:hover': {
        backgroundColor: '#778899',
      }
    },
    '& .easyPrintHolder':{
      marginTop: '-32px',
      marginBottom: '-8px',
      marginLeft: '27px',
      paddingLeft: '12px',
      borderRadius: '0 6px 6px 0',
      overflow: 'hidden',
      border: '0',
      maxHeight: '32px'
    },
    '& .clearDate':{
      top: '0px',
      right: '-3px',
      width: '20px',
      height: '20px',
      position: 'absolute',
      background: 'white',
      borderRadius: '50%',
      fill: '#9bbaf2',
    }
  },
  headerLinks: {
    marginTop: '10px',
    marginBottom: '10px',
    '& a': {
      marginRight: '34px'
    }
  },
  headerCheckbox: {
     marginBottom: '10px'
  },
  map: {
    width: '100%',
    height: 'calc(73vh + 9px)',
    minHeight: '750px',
    '& .leaflet-top .leaflet-control': {
      //marginTop: '225px'
    },
    '&.sidebar-collapsed .leaflet-left': {
      left: '0',
      position: 'relative',
    },
    '& .leaflet-left': {
      left: '295px'
    },
    '& .marker-cluster-custom': {
      background: 'transparent',
      borderLeft: '2px solid #191970',
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#222',
      lineHeight: '37px',
      textAlign: 'start',
      width: 'auto !important',
      height: '75px !important',
      lineHeight: '0',
      paddingTop: '0',
      marginTop: '-80px !important',
      marginLeft: '-1px !important',
    },
    '& .marker-cluster-custom .text-mark': {
      color: 'white',
      height: '20px',
      maxWidth: '45px',
      minWidth: '45px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '0 10px 10px 0'
    },
    '& .marker-cluster-custom .text-mark .red-bullit': {
      color: '#ed1c24',
      fontSize: '28px',
      paddingRight: '2px'
    },
    '& .marker-cluster-pie g.arc': {
      fillOpacity: '0.5',
    },
    '& .marker-cluster-pie-label': {
      fontSize: '14px',
      fontWeight: 'bold',
      fontFamily: 'sans-serif'
    },
    '& .marker-cluster-pie-label-bullit': {
      fontSize: '28px',
      fill: '#ed1c24'
    }
  },
  info: {
    padding: '6px 8px',
    font: '14px/16px Arial, Helvetica, sans-serif',
    background: 'white',
    //boxShadow: '0 0 15px rgba(0,0,0,0.2) !important',
    borderRadius: '5px',
    '& h4': {
      margin: '0 0 5px',
      color: '#777',
    },
  },
  legend: {
    lineHeight: '20px',
    color: '#191970 !important',
    display: 'none',
    paddingRight: '30px',
    background: 'rgba(255,255,255) !important',
    width: '228px',
    maxHeight: '550px',
    overflowY: 'auto',
    padding: '16px 20px',
    boxShadow: 'inset 0 0 0 1px #778899 !important',

    '& h4':{
      color: '#6495ed !important',
    },
    '& i': {
      width: '18px',
      height: '18px',
      float: 'left',
      marginRight: '8px',
      borderRadius: '50%',
      minWidth: '18px'
    },
    '& .close': {
      cursor: 'pointer',
      position: 'absolute',
      right: '5px',
      top: '5px',
      fontSize: '21px'
    }
  },
  containedCustom: {
    backgroundColor: '#6495ed',
    color: 'white',
    borderRadius: '23px',
    padding: '10px 25px 10px 20px',
    marginBottom: '10px',
    minWidth: '197px',
    minHeight: '46px',
    '&>span':{
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '& img':{
      marginRight: '15px',
    },
    '&:hover': {
      backgroundColor: '#5486e0',
    },
  },
  containedCustomActive: {
    borderRadius: '23px',
    border: '1px solid #6495ed',
    backgroundColor: '#f7f9fb',
    color: '#6495ed',
    padding: '10px 25px 10px 20px',
    marginBottom: '10px',
    minWidth: '197px',
    minHeight: '46px',
    '&>span':{
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '& img':{
      marginRight: '15px',
    },
    '&:hover': {
      boxShadow: '0px 1px 5px #6495ed !important',
      border: '1px solid #6495ed !important',
      backgroundColor: '#f7f9fb !important',
    },
  },
  outlinedCustom: {
    backgroundColor: '#fdbb2d',
    border: '1px solid #fdbb2d',
    color: 'white',
    borderRadius: '23px',
    padding: '10px 30px 10px 20px',
    marginBottom: '10px',
    minWidth: '197px',
    minHeight: '46px',
    '&>span': {
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '&:hover': {
      border: '1px solid #fdbb2d',
      backgroundColor: '#fdbb2d',
    },
    '& img': {
      marginRight: '15px',
    },
  },
  outlinedCustomActive: {
    border: '1px solid #fdbb2d',
    backgroundColor: '#f7f9fb',
    color: '#fdbb2d',
    borderRadius: '23px',
    padding: '10px 30px 10px 20px',
    marginBottom: '10px',
    minWidth: '197px',
    minHeight: '46px',
    '&>span': {
      justifyContent: 'space-between',
      fontSize: '14px',
    },
    '&:hover': {
      boxShadow: '0px 1px 5px #fdbb2d !important',
      border: '1px solid #fdbb2d !important',
      backgroundColor: '#f7f9fb !important',
    },
    '& img': {
      marginRight: '15px',
    },
  },
  date: {
    color: '#6495ed',
    fontSize: '1.6rem',
    fontWeight: '200',
    letterSpacing: '1.2px',
    marginRight: '20px',
    marginTop: '8px',
    marginLeft: '-104px',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.5rem',
    },
    '@media (min-width: 1024px) and (max-width: "md")': {
      fontSize: '2rem',
    }
  },
  dFlex: {
    display: 'flex',
  },
  dateBlock: {
    paddingTop: '5px',

    '& .text-field':{
      // marginBottom: '0',
      // position: 'relative',
      // top: '-45px',
      // left: '44px',
      width: '90px',
      top: '5px',
      left: '-5px',

      [theme.breakpoints.down(Breakpoints.md)]: {
        width: '75px',
      },

    },
    '& div div':{
      '&::before':{
        display: 'none'
      },
      '& input': {
        height: '25px',
        paddingLeft: '15px',
        border: '0 !important',

        [theme.breakpoints.down(Breakpoints.md)]: {
          paddingRight: "0 !important",
          paddingLeft: "10px !important",
        },
      },
    },
  },
  dataIcon: {
    background: '#add8e6',
    width: 51,
    height: 45,
    borderRadius: 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '5px',
    zIndex: '2',
    position: 'relative',
  },
  dataPick: {
    width: 'calc(100% - 230px)',
    '& input': {
      top: '-45px',
      left: '51px',
      width: '84px',
      height: '24px',
      position: 'absolute',
    },
  },
  calendar: {
    minWidth: '120px',
    maxHeight: '55px',
    marginRight: '15px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.up(1600)]: {
      minWidth: '140px',
    },
  },
  tooltips:{
    fontSize: '555px',
    background: 'red'
  },
  checkbox: {
    '& .checkbox': {
      width: '20px',
      height: '20px',
      borderRadius: '5px',
      backgroundColor: '#E1EDFB',

      '&--checked': {
        backgroundImage: 'url(/images/icons/icon-check-mark.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center'
      }
    }
  },
  filter: {
    display: 'flex',
    marginTop: '5px',
    '& label': {
      flexGrow: 1,
      color: 'rgb(25, 25, 112)',
      fontSize: '12px'
    }
  },
  controlLabel: {
    alignItems: 'flex-start',
    '& span': {
      paddingTop: '0',
      paddingBottom: '0',
      color: '#191970',
      fontSize: '12px',
      letterSpacing: '0.1em'
    },
    '& svg path': {
      fill: '#ADD8EF',
      fillOpacity: 1,
    },
    '& + .count': {
      color: '#778899',
      fontSize: '12px',
      letterSpacing: '0.1em',
      fontWeight: '900'
    }
  },
})
