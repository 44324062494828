import React, { useContext } from "react";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import IndexTable from "../../components/table";
import Person from "./item";
import {
  TrainerService,
  FacilitatorService,
} from "../../services/PersonService";
import { StoreContext } from "../../stores/context";
import { I18n } from "../../i18n";

const Persons = (props) => {
  const { path } = { ...props };

  // person uses common texts for translation
  const i18nProps = {
    i18n: props.i18n,
    i18nCommon: I18n[props.lang].common,
  };

  return (
    <IndexTable
      {...props}
      {...i18nProps}
      path={path}
      columns={[
        { id: "id", title: "ID" },
        { id: "name" },
        { id: "nameTranscription" },
        { id: "gender" },
        { id: "email" },
        { id: "cellPhone" }
      ]}
      onEditItem={(itemId, item, handleItemSave) => {
        return (
          <Person
            serviceClass={props.serviceClass}
            i18n={props.i18n}
            item={item}
            itemId={itemId}
            indexPath={path}
            handleItemSave={handleItemSave}
            isEditable={true}
            {...i18nProps}
            {...props}
          />
        );
      }}
      onViewItem={(itemId, item) => {
        return (
          <Person
            serviceClass={props.serviceClass}
            i18n={props.i18n}
            item={item}
            itemId={itemId}
            indexPath={path}
            isEditable={false}
            {...i18nProps}
            {...props}
          />
        );
      }}
    />
  );
};

Persons.propTypes = {
  lang: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  serviceClass: PropTypes.any.isRequired,
};

export const Trainers = observer((props) => {
  const { langStore } = useContext(StoreContext);
  return (
    <Persons
      lang={langStore.lang}
      i18n={langStore.trainers}
      classes={langStore.trainers}
      path="trainers"
        {...props}
      serviceClass={TrainerService}
    />
  );
});

export const Facilitators = observer((props) => {
  const { langStore } = useContext(StoreContext);
  return (
    <Persons
      lang={langStore.lang}
      i18n={langStore.facilitators}
      path="facilitators"
      serviceClass={FacilitatorService}
      {...props}
    />
  );
});
