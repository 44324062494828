import ItemService from "./ItemService";
import {Settings} from "../config.js";
import { each, sortBy } from "lodash";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { capitalizeName } from "../utils/capitalize";
import {COMMUNITY_BY_COORDS, PARTICIPANT_EP, PREREG_PARTICIPANTS_EP} from "../utils/DataFetcher";

class ParticipantService extends ItemService {
  constructor(onUpdateState, lang) {
    super(PARTICIPANT_EP, onUpdateState, lang);
  }

  _dummyPhoneNumber = "+380688888888";

  NavigateAfterSave(state, response) {
    state = { ...state };

    if (!state.messages) {
      state.messages = [];
    }

    state.eventId
      ? state.messages.push(this.GetRedirect(`/events/view/${state.eventId}`))
      : state.messages.push(this.GetRedirect(`/participants/view/${state.id}`));

    return state;
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    const params = this.GetApiPutParams();

    // skips serverside doublette check
    data.append("force_new", true);

    for (let p of params) {
      if (!state.anonymous &&  p.name === "name" && state.name) {
        data.append(p.name, capitalizeName(state.name));
      } else if (p.name === "nameTranscription" && state.nameTranscription) {
        data.append(p.name, capitalizeName(state.nameTranscription));
      } else if (p.name === "employerLocationCoordinates" && state.location) {
        // api saves lat lng twisted
        data.append(
          "employerLocationCoordinates",
          `POINT(${state.location[1]} ${state.location[0]})`
        );
      } else {
        this.MapProperty(data, state, p);
      }
    }

    if (state.isTransient) {
      data.append("eventId", state.eventId);
    }

    if (state.preregId) {
      data.append("prelimParticipantId", state.preregId);
    }

    if(state.anonymous)
    {
      data.append("name","Anonymous");
      data.append("nameTranscription","Anonymous");
      data.append("gender","n/a");
      data.append("employerTypeId", Settings.EmployerTypeForAnonymous);
      data.append("employerLevelId", Settings.EmployerLevelForAnonymous);
    }

    return data;
  }

  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    state = {
      showCellPhone: false,
      events: [],
      usersList: [],
      communityList: [],
      initialLocation: [50.4501, 30.5234],
      zoom: 12,
      showConfirmDisconnect: false,
      showDialog: false,
      anonymous: true,
      employerLevelId: Settings.EmployerLevelForAnonymous,
      employerTypeId: Settings.EmployerTypeForAnonymous,
      ...state,
    };

    return state;
  }

  async getPreregParticipant(id) {
    const {
      nameEn,
      nameUa,
      cellPhone,
      gender,
      email = "",
      position = "",
      employerName = "",
      employerTypeId,
      employerLevelId,
      employerLocation,
      contactAddress,
      regionId,
    } = await this.Fetch("GET", `${this.url}${PREREG_PARTICIPANTS_EP}/${id}`);



    const data = {
      anonymous: false,
      name: this._isEn ? nameEn : nameUa,
      nameTranscription: this._isEn ? nameUa : nameEn,
      showCellPhone: true,
      cellPhone,
      gender: gender === "OTHER" ? "n/a" : gender,
      email,
      position,
      employerName,
      employerLocation,
      employerLocationCoordinatesString: this.convertPointToCoordinatesString(employerLocation),
      contactAddress,
    };

    if (employerTypeId) {
      data.employerTypeId = employerTypeId;
    }
    if (employerLevelId) {
      data.employerLevelId = employerLevelId;
    }
    if (regionId) {
      data.regionId = regionId;
    }

    return data;
  }

  async onAfterSave(state) {
    if (state.preregId) {
      await this.Fetch(
        "DELETE",
        `${this.url}${PREREG_PARTICIPANTS_EP}/${state.preregId}`
      );
    }
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    // api returns and keeps lat lng twisted
    state = this.SetLocation(state, {
      latLng: item.employerLocationCoordinates
        ? [
            item.employerLocationCoordinates.coordinates[1],
            item.employerLocationCoordinates.coordinates[0],
          ]
        : undefined,
    });

    state.canDelete = !state.isTransient
        && (!item.shortEvents || item.shortEvents.length === 0);

    state = {
      ...state,
      showCellPhone:
        item.cellPhone && item.cellPhone !== this._dummyPhoneNumber,
      name: capitalizeName(item.name),
      nameTranscript: capitalizeName(item.nameTranscription),
      events: item.shortEvents
        ? sortBy(item.shortEvents, [(ev) => -ev.id])
        : [],
      anonymous: state.isTransient || (item.name === "Anonymous" && item.nameTranscription === "Anonymous")
    };



    return state;
  }

  async Action(e, state) {
    switch (e.fieldName) {
      case "employerLocationCoordinates":
        state = this.SetLocation(state, e.value);
        state = await this.SetCommunityName(state, e.value);
        break;
      case "employerLevelId":
        state = this.SetEmployerLevel(state, e.value);
        break;
      case "name":
        state = this.SetName(state, e.value);
        break;
      case "disconnectItemFromEvent":
        state = this.SetDisconnectItemFromEvent(state, e.value);
        break;
      case "rejectDisconnect":
        state = this.SetRejectDisconnect(state);
        break;
      case "confirmDisconnect":
        state = await this.SetConfirmDisconnect(state, e.value);
        break;
      case "checkParticipant":
        state = await this.CheckParticipant(state);
        break;
      case "acceptMatchDialog":
        state = await this.AcceptMatch(state);
        break;
      case "cancelMatchDialog":
        state = this.CancelMatch(state);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  GetApiPutParams() {
    return [
      {
        in: "formData",
        name: "name",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "nameTranscription",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "gender",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "email",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "cellPhone",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerName",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerLocation",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerLocationCoordinates",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "hromadaAmalgamated",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "contactAddress",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "position",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "regionId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "communityId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "employerTypeId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "employerLevelId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
    ];
  }

  async DisconnectParticipant(participantId, eventId) {
    let url = `${this.url}/participants/disconnect/${participantId}/${eventId}`;
    await this.Fetch("delete", url);
  }

  async ConnectParticipant(participantId, eventId, params) {
    let url = `${this.url}/participants/connect/${participantId}/${eventId}?${params}`;
    const result = await this.Fetch("post", url);
    return result;
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    if(!state.anonymous)
    {
      this.validationRequired(result, state, "name");
      this.validationRequired(result, state, "nameTranscription");
      this.validationRequired(result, state, "gender");

      if (
          !state.cellPhone ||
          state.cellPhone === "" ||
          state.cellPhone === this._dummyPhoneNumber
      ) {
        this.validationRequired(result, state, "email");
      }
      this.validationRequired(result, state, "contactAddress");
      if (state.showCellPhone) {
        if (
            state.cellPhone === undefined ||
            state.cellPhone === "" ||
            state.cellPhone.indexOf("_") > -1
        ) {
          result.cellPhone = "Required";
        }
      }

      this.validationRequired(result, state, "employerName");
    }

    this.validationSelectionRequired(result, state, "regionId");
    this.validationSelectionRequired(result, state, "employerTypeId");
    this.validationSelectionRequired(result, state, "employerLevelId");
    this.validationRequired(result, state, "employerLocation");
    this.validationRequired(result, state, "employerLocationCoordinatesString");
    this.validateGeoData(result, state, "employerLocationCoordinatesString");
    return result;
  }

  async AcceptMatch(state) {
    let result = { ...state, loading: true };
    this.onUpdateState(result);

    let item = await this.GetItem(state.selectedMatch);

    try {
      item = await this.ConnectParticipant(
        state.selectedMatch,
        state.eventId,
        state.checkedExistedParams
      );
    } catch (error) {
      const message = this.GetError("Could not connect participant to event!");
      result = {
        ...result,
        showDialog: false,
        isCheckDialog: false,
        loading: false,
        selectedMatch: undefined,
        messages: [message],
      };
      return result;
    }

    result = this.UpdateStateFromItem(result, item);

    result = {
      ...result,
      showDialog: false,
      isCheckDialog: false,
      loading: false,
      selectedMatch: undefined,
    };
    return result;
  }

  CancelMatch(state) {
    const result = {
      ...state,
      showDialog: false,
      isCheckDialog: false,
      loading: false,
    };
    return result;
  }

  async CheckParticipant(state) {
    let result = { ...state, loading: true };
    this.onUpdateState(result);

    const {
      email,
      name,
      nameTranscript,
      cellPhone,
    } = state;

    const matchComponents = [
      { field: "name", value: name },
      { field: "nameTranscription", value: nameTranscript },
      { field: 'email', value: email },
      { field: "cellPhone", value: cellPhone },
    ].filter((x) => (x.value || "").length);

    let params = [];
    each(matchComponents, (component) => {
      let { value, field } = component;
      if (field === "cellPhone") {
        value = value.replace(/_/g, "");
        field = "cell_phone";
      }
      params.push(`${field}=${value}`);
    });
    params = params.join("&");

    let matchedParticipants = await this.Fetch(
      "get",
      `${this.url}/participants/check?${params}`
    );
    const matchFound = matchedParticipants?.length > 0;

    result = {
      ...result,
      isCheckDialog: true,
      showDialog: true,
      checkedExistedParams: params,
      selectedMatch: matchFound ? matchedParticipants[0].id : undefined,
      usersList: matchedParticipants,
      loading: false,
    };

    return result;
  }

  SetDisconnectItemFromEvent(state, value) {
    const result = {
      ...state,
      showConfirmDisconnect: true,
      disconnectEvent: value,
    };
    return result;
  }

  SetRejectDisconnect(state) {
    const result = {
      ...state,
      showConfirmDisconnect: false,
      disconnectEvent: undefined,
    };
    return result;
  }

  async SetConfirmDisconnect(state) {
    //start loader, close dialog
    let result = {...state, showConfirmDisconnect: false, loading: true};
    this.onUpdateState(result);

    await this.DisconnectParticipant(state.id, state.disconnectEvent.id);
    if(state.events && state.events.length){
      result.events = state.events.filter(e => e.id !== state.disconnectEvent.id);
    }

    return {
      ...result,
      loading: false,
      disconnectEvent: undefined,
    };
  }

  SetLocation(state, value) {
    let location, employerLocationCoordinatesString;

    if (value?.latLng?.length === 2) {
      location = value.latLng;
      employerLocationCoordinatesString = `${value.latLng[0]} ${value.latLng[1]}`;
    } else {
      location = state.initialLocation;
      employerLocationCoordinatesString = "";
    }

    let result = { ...state, location, employerLocationCoordinatesString };

    if (value.addressParts) {
      var cityParts = [];
      var cityTypes = [
        "locality",
        "administrative_area_level_2",
        "administrative_area_level_1",
      ];
      each(value.addressParts, (part) => {
        if (cityTypes.indexOf(part.types[0]) < 0) {
          return;
        }
        cityParts.push(part.long_name);
      });

      result = {
        ...result,
        contactAddress: result.contactAddress || value.address?.query,
        employerLocation: cityParts.join(", "),
      };
    }

    return result;
  }

  async SetCommunityName(state, value) {
    let community,  communityNameUa, communityNameEn;
    let result;
    if (value?.latLng?.length === 2) {
      // lat lon twisted in api: lon lat
      const location = `${value.latLng[1]},${value.latLng[0]}`;
      result = await this.Fetch("POST", `${this.url}${COMMUNITY_BY_COORDS}`, {coordinates: location});
    }

    if(result?.community)
    {
      communityNameUa = result.community.name_ua;
      communityNameEn = result.community.name_en;
    }

    return {...state, communityNameEn: communityNameEn, communityNameUa: communityNameUa}
  }

  convertPointToCoordinatesString(point) {
    try {
          const [lng, lat] = point
            .replace("POINT(", "")
            .replace(")", "")
            .split(" ");
          return `${lat} ${lng}`;
    } catch (ex) {
      return "";
    }
  }

  //impure!
  SetEmployerLevel(state, value) {
    const isRegionDisabled = value === 2 || value === 1;
    let result = { ...state };
    const naRegion = { id: 30, value: 30, label: "N/A" };

    if (isRegionDisabled === true && state.isRegionDisabled === false) {
      if (state.regionsList) {
        const regionsList = [...state.regionsList];
        regionsList.unshift(naRegion);

        result = { ...result, regionsList, regionId: naRegion.id };
      }
    } else if (isRegionDisabled === false && state.isRegionDisabled === true) {
      let regionsList = [...state.regionsList];
      regionsList = regionsList.filter((x) => x.id !== naRegion.id);

      result = { ...result, regionsList, regionId: undefined };
    }

    result = { ...result, employerLevelId: value, isRegionDisabled };
    return result;
  }

  SetName(state, value) {
    let translit = cyrillicToTranslit({ preset: "uk" }).transform(value);
    translit = capitalizeName(translit);

    let result = { ...state, name: value, nameTranscription: translit };

    return result;
  }

  MessageSaveFailed(state, response, data) {
    state = { ...state };

    if (!state.messages) {
      state.messages = [];
    }

    if (response.status === 300) {
      let duplicateName = data[0].name;
      state.messages.push(
        this.GetInfoDialog(
          `Please correct name to: ${duplicateName} and select the 'Check' button in the Cell Phone field.`,
          "Duplicate found, participant not saved!"
        )
      );
      return state;
    } else if (data.error) {
      state.messages.push(this.GetError(data.error, ""));
      return state;
    } else {
      super.MessageSaveFailed(state, response);
    }
  }
}

export default ParticipantService;
