import React from "react";
import {observer} from "mobx-react";
import {I18n} from "../../i18n";
import ParticipantService from "../../services/ParticipantService.js";
import Edit from "../../components/dialog/edit.js";
import AddressControl from "../../components/AddressControl.js";
import {
    FormTextField,
    FormSelectField,
    FormRadioField,
    FormCheckboxField,
    FormPhoneField,
    ReadOnlyField,
} from "../../components/dialog/formField";
import {
    InputAdornment,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    DialogContent,
    IconButton,
} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {Map, TileLayer, Marker} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import {Search} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import {StoreContext} from "../../stores/context";
import {GIZButton, GIZSwitch, ResponsiveTable} from "../../components";


class Item extends React.Component {
    static contextType = StoreContext;

    handleGetData = async (service, editorState) => {
        const params = new URLSearchParams(document.location.search);
        const eventId = params.get("eventId");
        const preregId = params.get("preregId");

        let [
            regionsList,
            employerTypesList,
            employerLevelsList,
            preregParcitipant,
        ] = await Promise.all([
            service.GetRegions(),
            service.GetSelectOptions(
                "employer_types",
                editorState.employerTypeId
            ),
            service.GetSelectOptions(
                "employer_levels",
                editorState.employerLevelId
            ),
            preregId ? service.getPreregParticipant(preregId) : null,
        ]);

        if (!this.context.userStore.isFirstPhase) {
            employerLevelsList = employerLevelsList.filter(
                (level) => level.id !== 5 && level.id !== 6
            );
        }

        return {
            eventId,
            preregId,
            regionsList,
            employerTypesList,
            employerLevelsList,
            ...preregParcitipant,
        };
    }

    // there are state modifications which depend on loaded lists
    // those may be executed here
    onAfterGetData(service, state) {
        let newState = service.SetEmployerLevel(state, state.employerLevelId);

        return newState;
    }

    renderEventLink = (event, item) => {
        const name = `${this.props.lang === "Eng" ? event.nameEn : event.nameUa}`;
        if (
            (item.specialRightEventIds &&
                item.specialRightEventIds.indexOf(event.id) > 0) ||
            (!item.forbiddenEventIds ||
                item.forbiddenEventIds.indexOf(event.id) === -1)
        ) {
            return <a target="_blank" href={`/events/view/${event.id}`}>{name}</a>
        } else {
            return name;
        }
    };

    editAccessToEvent = (event, item) => {
        return item.forbiddenEventIds.indexOf(event.id) < 0;
    };

    renderCheckButton = (action, formState) => {
        const {cellPhone} = formState;

        const {classes} = this.props;

        const isValid =
            typeof cellPhone === "string" &&
            cellPhone.length > 1 &&
            !~cellPhone.indexOf("_");

        return {
            endAdornment: isValid ? (
                <InputAdornment position="end" className={classes.searchInField}>
                    <IconButton
                        onClick={() => action({fieldName: "checkParticipant"})}
                        className={classes.btnSearch}
                    >
                        <Search/>
                        <span>check</span>
                        {/* <span>{I18n[lang].participants.checked}</span> */}
                    </IconButton>
                </InputAdornment>
            ) : null,
        };
    };

    render = () => {
        const i18n = I18n[this.props.lang].participants;
        const {...rest} = {i18n, ...this.props};
        const {classes} = {...this.props};
        const lang = this.props.lang;
        const {langStore} = this.context;

        return (
            <Edit
                i18n={i18n}
                handleGetData={this.handleGetData}
                onAfterGetData={this.onAfterGetData}
                serviceClass={ParticipantService}
                title={!this.props.item ? I18n[lang].events.addParticipant : undefined}
                {...rest}
                render={(fieldProps) => (
                    <>
                        <Grid container className={classes.layoutColumn}>
                            {/*personal*/}
                            <Grid item xs={12} md={6}>
                                <Paper className={classes.paper}>
                                    <Grid container className={classes.layoutColumn}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3">
                                                {I18n[lang].participants.personalDetails}
                                            </Typography>
                                            <div style={{height:"10px"}}></div>
                                            <GIZSwitch
                                                label="Anonymous"
                                                checked={fieldProps.formState.anonymous}
                                                onChange={(e) =>
                                                    fieldProps.action({
                                                        fieldName: "anonymous",
                                                        value: e.target.checked,
                                                    })
                                                }
                                                className={classes.switch}
                                            />
                                        </Grid>
                                        {!fieldProps.formState.anonymous && (
                                            <><Grid
                                                item
                                                container
                                                direction="column"
                                                xs={12} md={6}
                                                spacing={8}
                                            >
                                                <Grid item>
                                                    <FormTextField fieldName="name" {...fieldProps} />
                                                </Grid>
                                                <Grid item>
                                                    <FormRadioField
                                                        // style="{marginTop:-100px}"
                                                        fieldName="gender"
                                                        selectList={[
                                                            {value: "MALE", label: "Male"},
                                                            {value: "FEMALE", label: "Female"},
                                                            {value: "N/A", label: "n/a"},
                                                        ]}
                                                        {...fieldProps}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <FormTextField
                                                        fieldName="nameTranscription"
                                                        {...fieldProps}
                                                    />
                                                </Grid>
                                            </Grid>

                                                <Grid
                                                    item
                                                    container
                                                    direction="column"
                                                    xs={12} md={6}
                                                    spacing={8}
                                                >
                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="email"
                                                            {...fieldProps}
                                                            label={I18n[lang].common.email}
                                                        />
                                                    </Grid>
                                                    {fieldProps.isEditable && (
                                                        <Grid item>
                                                            <FormCheckboxField
                                                                fieldName="showCellPhone"
                                                                label="Show Cell Phone"
                                                                labelClassName={classes.phoneCell}
                                                                {...fieldProps}
                                                            />
                                                        </Grid>
                                                    )}
                                                    <Grid item>
                                                        {fieldProps.formState.showCellPhone && (
                                                            <FormPhoneField
                                                                fieldName="cellPhone"
                                                                hideLabel={true}
                                                                {...fieldProps}
                                                                renderInputProps={
                                                                    fieldProps.formState.isTransient
                                                                        ? this.renderCheckButton
                                                                        : undefined
                                                                }
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid></>
                                        )}

                                    </Grid>
                                </Paper>
                            </Grid>


                            {/*employer:*/}
                            <Grid item xs={12} md={6}>
                                <Paper className={classes.paper}>
                                    <Grid container className={classes.layoutColumn}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h3">
                                                {I18n[lang].participants.employer}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextField
                                                fieldName="position"
                                                label={I18n[lang].common.position}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormSelectField
                                                fieldName="employerLevelId"
                                                label={I18n[lang].common.employerLevelEn}
                                                selectList={fieldProps.formState.employerLevelsList}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextField
                                                fieldName="employerName"
                                                label={I18n[lang].common.employerName}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextField
                                                fieldName="contactAddress"
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormSelectField
                                                fieldName="employerTypeId"
                                                label={I18n[lang].common.employerTypeEn}
                                                selectList={fieldProps.formState.employerTypesList}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormSelectField
                                                fieldName="regionId"
                                                label={I18n[lang].common.oblastEn}
                                                selectList={fieldProps.formState.regionsList}
                                                isDisabled={fieldProps.formState.isRegionDisabled}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormTextField
                                                fieldName="employerLocationCoordinatesString"
                                                isDisabled={false}
                                                label={I18n[lang].participants.placeOfEventCoordinates}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {fieldProps.formState.communityNameEn && (
                                            <ReadOnlyField
                                                label={langStore.participants.community}
                                                value={langStore.isEng ? fieldProps.formState.communityNameEn : fieldProps.formState.communityNameUa}/>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Map
                                                className={classNames(classes.map, {
                                                    "error-field": fieldProps.isValid(
                                                        "employerLocationCoordinates"
                                                    ),
                                                })}
                                                center={fieldProps.formState.location}
                                                zoom={fieldProps.formState.zoom}
                                                dragging={true}
                                            >
                                                {this.props.isEditable && (
                                                    <AddressControl
                                                        className={classes.mapSearch}
                                                        onCoordinatesChange={(
                                                            latLng,
                                                            addressParts,
                                                            address
                                                        ) =>
                                                            fieldProps.action({
                                                                fieldName: "employerLocationCoordinates",
                                                                value: {latLng, addressParts, address},
                                                            })
                                                        }
                                                    />
                                                )}

                                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                                                <Marker
                                                    position={fieldProps.formState.location}
                                                ></Marker>
                                            </Map>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/*events*/}
                            <Grid item xs={12} className={classes.layoutColumn}>
                                {!fieldProps.formState.isTransient && (


                                    <div>
                                        <Typography variant="h3">
                                            {I18n[lang].participants.events}
                                        </Typography>
                                        <ResponsiveTable lang={this.props.lang} i18n={I18n[this.props.lang].events}
                                                         classes={classes}
                                                         items={fieldProps.formState.events}
                                                         columns={[
                                                             {id: "id"},
                                                             {
                                                                 id: this.props.lang === "Eng" ? "nameEn" : "nameUa",
                                                                 title: "name",
                                                                 displayFunc: (event) => this.renderEventLink(event, fieldProps.formState)
                                                             },
                                                         ]}
                                                         renderActions={(event) => {
                                                             if (this.editAccessToEvent(event, fieldProps.formState) && fieldProps.isEditable) {
                                                                 return (
                                                                         <Button
                                                                             size="small"
                                                                             variant="contained"
                                                                             className={classNames("button-cancel", [
                                                                                 classes.buttonRemoveSmall,
                                                                             ])}
                                                                             onClick={() =>
                                                                                 fieldProps.action({
                                                                                     fieldName: "disconnectItemFromEvent",
                                                                                     value: event,
                                                                                 })
                                                                             }
                                                                             color="primary"
                                                                         >
                                                                             <i></i>
                                                                             {I18n[lang].events.deleteParticipant}
                                                                         </Button>
                                                                 )
                                                             }

                                                         }}
                                        />

                                    </div>

                                )}
                            </Grid>

                        </Grid>


                        <Dialog
                            open={fieldProps.formState.showDialog}
                            onClose={(e) =>
                                fieldProps.action({fieldName: "cancelMatchDialog"})
                            }
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {fieldProps.formState.usersList?.length
                                    ? I18n[lang].participants.matchFoundTitle
                                    : I18n[lang].participants.noMatchFoundTitle}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {fieldProps.formState.usersList.length ? (
                                        <div>
                                            <p>
                                                There is an existing event participant in the system:
                                            </p>
                                            <div>
                                                {fieldProps.formState.usersList?.map((user, index) => {
                                                    return (
                                                        <div className={classes.listItem} key={index}>
                                                            <div>
                                                                <input
                                                                    type="radio"
                                                                    id={user.id}
                                                                    name="existedUser"
                                                                    value={user.id}
                                                                    defaultChecked={
                                                                        fieldProps.formState.selectedMatch ===
                                                                        user.id
                                                                    }
                                                                    onChange={(e) =>
                                                                        fieldProps.action({
                                                                            fieldName: "selectedMatch",
                                                                            value: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                                <label for={user.id}>
                                                                    <a href={`/participants/view/${user.id}`}
                                                                       target="_blank">
                                                                        {user.name}
                                                                        {user.cellPhone ? ", " + user.cellPhone : ""}
                                                                        {user.email ? ", " + user.email : ""}
                                                                        {user.position ? ", " + user.position : ""}
                                                                    </a>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                        ;
                                                })}
                                            </div>
                                            <p>
                                                Do you want to access this profile and be able to update
                                                information based on data in most recently collected
                                                participant lists?
                                            </p>
                                        </div>
                                    ) : (
                                        <p>{I18n[lang].participants.noMatchFoundContent}</p>
                                    )}
                                </DialogContentText>
                            </DialogContent>

                            {fieldProps.formState.usersList.length ? (
                                <DialogActions>
                                    <Button
                                        onClick={(e) =>
                                            fieldProps.action({fieldName: "acceptMatchDialog"})
                                        }
                                        variant="contained"
                                        color="primary"
                                        autoFocus
                                    >
                                        Yes, add this participant
                                    </Button>
                                    <Button
                                        onClick={(e) =>
                                            fieldProps.action({fieldName: "cancelMatchDialog"})
                                        }
                                        variant="contained"
                                        color="primary"
                                    >
                                        No, create new participant in system
                                    </Button>
                                </DialogActions>
                            ) : (
                                <DialogActions>
                                    <Button
                                        onClick={(e) =>
                                            fieldProps.action({fieldName: "cancelMatchDialog"})
                                        }
                                        variant="contained"
                                        color="primary"
                                    >
                                        {I18n[lang].common.close}
                                    </Button>
                                </DialogActions>
                            )}
                        </Dialog>
                    </>
                )}
            />
        );
    };
}

export default observer(Item);
