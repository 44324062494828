import React from 'react';
import withRoot from '../../withRoot';
import { each, isNumber } from 'lodash'
import { default as layersMeta } from './layers.json';
import * as markerUtils from "./marker-utils";

import L from 'leaflet';
import { Marker } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

require('react-leaflet-markercluster/dist/styles.min.css');

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class CustomMarkers extends React.Component {

  state = {
    showMarker: true,
  }

  getMarkersPositions = () => {
    const markers = []
    const { areasData, layersData, yearFilter } = this.props;

    each(layersData, (data, layerId) => {
      each(data, (count, areaId) => {
        areaId = parseInt(areaId)
        if (markers.find(x => x.unitId == areaId)) { return }
        if (!areasData.features) { return }

        const feature = areasData.features.find(feature => feature.properties.id == areaId)
        if (!feature || !feature.properties.visualCenterLatLong) { return }
        if (yearFilter && !yearFilter[parseInt(feature.properties.year)]) { return }

        const [ lat, long ] = feature.properties.visualCenterLatLong
        const dop = Math.round(Math.random() * 10000) / 10000000000

        markers.push({
          unitId: areaId,
          position: [ long, lat + dop ]
        });
      })
    })
    return markers;
  }

  markerBaseLayer = (areaId) => {
    return this.props.baseLayer.getLayer(1000000+areaId)
  }

  onMarkerClick = (event) => {
    const layer = this.markerBaseLayer(event.target.options.unitId)

    layer.fireEvent('click');
    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.showMarker !== nextState.showMarker) {
      return true;
    }

    // To forse rerender MarkerCluster component
    if (this.props.buttonsLabelsColors !== nextProps.buttonsLabelsColors ||
        this.props.withGenderProportion !== nextProps.withGenderProportion ||
        this.props.asColorGradient !== nextProps.asColorGradient ||
        this.props.layersVersion != nextProps.layersVersion) {
      this.setState({ showMarker: false }, () => this.setState({ showMarker: true }));
      return true;
    }
    return false;
  }

  render(){
    const {
      selectedLayers,
      selectedSubLayers,
      layersFilterObjects,
      layersData,
      withGenderProportion,
      asColorGradient,
      buttonsLabelsColors,
      singleLayerMode,
      asHromada
    } = this.props;

    if (!selectedLayers.length || !Object.keys(layersData).length) { return ''; }

    const markers = this.getMarkersPositions(layersData);
    if (!markers.length) { return ''; }

    const [clusterIcon, markerIcon] = markerUtils.iconFuncs(selectedLayers, selectedSubLayers, layersMeta, layersData, layersFilterObjects, buttonsLabelsColors, { withGenderProportion, asColorGradient, singleLayerMode, asHromada });

    return (
      <React.Fragment>
        {
          this.state.showMarker
            ? (
              <MarkerClusterGroup
                showCoverageOnHover={false}
                spiderfyDistanceMultiplier={1}
                disableClusteringAtZoom={this.props.disableClusteringAtZoom}
                maxClusterRadius={60}
                iconCreateFunction={clusterIcon}
              >
                {
                  markers.map((marker, index) => {
                    return (
                      <Marker
                        unitId={marker.unitId}
                        position={marker.position}
                        icon={markerIcon(marker)}
                        onClick={this.onMarkerClick}
                        key={index}
                      />
                    )
                  })
                }
              </MarkerClusterGroup>
            )
          : null
        }
      </React.Fragment>
    )
  }
}

export default withRoot(CustomMarkers);
