import ItemService from "./ItemService";
import { Endpoints } from "../config.js";
import { each } from "lodash";
import downloadFile from "../utils/fetchFile";

class CommunityService extends ItemService {
  constructor(onUpdateState, lang, user) {
    super(Endpoints.Community.path, onUpdateState, lang, user);
  }

  GetInitialIndexState(currentPhaseId) {
    let state = super.GetInitialIndexState(currentPhaseId);
    state = {
      ...state,
      sortableColumns: ["id", "region", "initiatives", "support"],
    };

    return state;
  }

  GetInitialState(userRoles, currentPhaseId) {
    let state = super.GetInitialState(userRoles, currentPhaseId);

    const eventsTableTitles = [
      { id: "id", label: "id" },
      { id: "startDate", label: "date" },
      { id: this._isEn ? "nameEn" : "nameUa", label: "eventTitle" },
      { id: this._isEn ? "format.en" : "format.ua", label: "eventFormat" },
      { id: "trainingPackageTypes", label: "support" },
    ];

    if (state.isFirstPhase) {
      eventsTableTitles.push({
        id: "regionalInitiative",
        label: "regionalInitiative",
      });
    }

    state = {
      europeActions: [],
      grants: [],
      projects: [],
      eventsOrder: "desc",
      eventsOrderBy: "",
      participantsOrder: "desc",
      participantsOrderBy: "",
      eventsTableTitles,
      participantsTableTitles: [
        {
          id: this._isEn ? "nameTranscription" : "name",
          label: "participantName",
        },
        { id: "gender", label: "gender" },
        { id: "position", label: "position" },
        {
          id: this._isEn ? "employerTypeEn" : "employerTypeUa",
          label: "employerType",
        },
        { id: "employerName", label: "employerName" },
      ],

      doughnutOptions: this.doughnutOptions,
      genderDistributionChart: this.emptyDoughnutState,
      employerTypeDistributionChart: this.emptyDoughnutState,
      eventFormatDistributionChart: this.emptyDoughnutState,

      ...state,
    };

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    state = {
      ...state,
      grants: item.csoGrants,
      projects: item.donorProjects,
      ahCentre: this._lang === "Ua" ? item.center : item.centerEn,
      centreUa: item.center,
      centreEn: item.centerEn,
      region: this._lang === "Ua" ? item.region?.ua : item.region?.en,
      created: this.FormDate(item.created),
      createdDateTime: item.created,
      population: item.population?.toLocaleString(),
      mapLink: this.MapLink(item.visualCenterLatLong),
      name: item[this._isEn ? "nameEn" : "nameUa"],
      isUrban: item.nameEn?.includes("urban"),
    };

    state = this.GetGenderDistChart(state, item);
    state = this.GetDistChart(state, item, "employerTypeDistribution");
    state = this.GetDistChart(state, item, "eventFormatDistribution");

    return state;
  }

  // if day partis smaller 10 than id reduces by 1
  FormDate = (date) => {
    date = new Date(date);
    const dateMonth = [date.getUTCDate(), date.getMonth() + 1].map((int) =>
      int < 10 ? "0" + int : "" + int
    );
    return dateMonth[0] + "." + dateMonth[1] + "." + date.getUTCFullYear();
  };

  MapLink = (visualCenterLatLong) => {
    if (!visualCenterLatLong) {
      return "";
    }
    const [y, x] = visualCenterLatLong;
    const link = JSON.stringify([x, y]);
    return link;
  };

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    switch (e.fieldName) {
      case "setEdit":
        state = { ...state, editMode: true };
        break;
      case "setSave":
        state = await this.SaveDetails(state);
        break;
      case "downloadPdf":
        state = await this.DownloadPdf(state);
        break;
      case "setDetailSorting":
        state = this.SetDetailSorting(state, e.data.orderBy, e.data.entity);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        state = this.SetStateChanged(state);
        break;
    }

    return state;
  }

  async SaveDetails(state) {
    state = { ...state, loading: true };
    this.onUpdateState(state);

    const { europeActions, grants, projects, practiceId} = state;
    const data = new FormData();

    if (state.nameEn) {
      data.set("nameEn", state.nameEn);
    }
    if (state.nameUa) {
      data.set("nameUa", state.nameUa);
    }
    if (state.centreEn) {
      data.set("centerEn", state.centreEn);
    }
    if (state.centreUa) {
      data.set("center", state.centreUa);
    }
    if (state.councilSize) {
      data.set("councilSize", state.councilSize);
    }
    if (state.createdDateTime) {
      data.set("created", state.createdDateTime);
    }
    if (state.population) {
      data.set("population", state.population);
    }
    if (state.square) {
      data.set("square", state.square);
    }
    if (state.koatuu) {
      data.set("koatuu", state.koatuu);
    }
    if (state.admin3pCode) {
      data.set("admin_3p_code", state.admin3pCode);
    }

    state = {...state, ahCentre: this._lang === "Ua" ? state.centerUa : state.centerEn, name: this._isEn ? state.nameEn : state.nameUa};

    if (state.isFirstPhase) {
      const actionIds = [];
      each(europeActions, (option) => {
        actionIds.push(parseInt(option.id));
      });
      data.set("europeActionIds", "[" + actionIds + "]");

      const grantIds = [];
      each(grants, (option) => {
        grantIds.push(parseInt(option.id));
      });
      data.set("csoGrantIds", "[" + grantIds + "]");
    }

    const projectIds = [];
    each(projects, (option) => {
      projectIds.push(parseInt(option.id));
    });
    data.set("donorProjectIds", "[" + projectIds + "]");

    if (practiceId) {
      data.set("goodPracticeId", practiceId);
    }
    const result = await this.Save(state.id, data);

    if (result.ok) {
      const returnedItem = await result.json();
      state = {...state, returnedItem};
      state = this.MessageSaveSucceeded(state);
      return { ...state, loading: false, editMode: false, hasChanges: false, created:this.FormDate(state.createdDateTime) };
    } else {
      state = this.MessageSaveFailed(state, result);
      return { ...state, loading: false };
    }
  }

  async DownloadPdf(state) {
    state = { ...state, loading: true };
    this.onUpdateState(state);

    const withLang = this._lang ? "?lang=" + this._lang : "";

    await downloadFile(`/communities/${state.id}/print_info.pdf${withLang}`);

    return { ...state, loading: false };
    //}
  }

  GetGenderDistChart(state, item) {
    let result = { ...state };

    let data = { ...this.emptyDoughnutState };

    const backgroundColor = [
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 99, 132, 0.2)",
      "rgba(88, 84, 215, 0.2)",
    ];

    const borderColor = [
      "rgba(54, 162, 235, 1)",
      "rgba(255,99,132,1)",
      "rgba(88,84,215,1)",
    ];

    if (item.genderDistribution) {
      data.labels = ["Male", "Female", "Unknown"];

      data.labels.forEach((label, i) => {
        const dist = item.genderDistribution.find(
          (x) => x[0].toLowerCase() === label.toLowerCase()
        );
        data.datasets[0].data[i] = dist ? dist[1] : 0;
      });

      data.datasets[0].backgroundColor = backgroundColor;
      data.datasets[0].borderColor = borderColor;
    }

    return { ...result, genderDistributionChart: data };
  }

  SearchUrlFragment() {
    return "";
  }
}
export default CommunityService;
