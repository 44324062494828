import ItemService from "./ItemService";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import {Endpoints} from "../config.js";
import {capitalizeName} from "../utils/capitalize";
import {sortBy} from "lodash";

export class PersonService extends ItemService {
  GetInitialState(userRoles) {
    let state = super.GetInitialState(userRoles);

    state = {
      ...state,
      showCellPhone: false,
      events: [],
      surveyRating: 0,
      genderOptions: [
        { value: "MALE", label: "MALE" },
        { value: "FEMALE", label: "FEMALE" },
        { value: "UNKNOWN", label: "UNKNOWN" },
      ],
      showConfirmDisconnect: false,
    };
    return state;
  }

  SetDisconnectItemFromEvent(state, value) {
    return {
      ...state,
      showConfirmDisconnect: true,
      disconnectEvent: value,
    };
  }

  SetRejectDisconnect(state) {
    return {
      ...state,
      showConfirmDisconnect: false,
      disconnectEvent: undefined,
    };
  }

  async SetConfirmDisconnect(state) {
    //start loader, close dialog
    let result = {...state, showConfirmDisconnect: false, loading: true};
    this.onUpdateState(result);

    await this.DisconnectPerson(state.id, state.disconnectEvent.id);
    if(state.shortEvents && state.shortEvents.length){
      result.events = state.shortEvents.filter(e => e.id !== state.disconnectEvent.id)
    }

    return {
      ...result,
      loading: false,
      disconnectEvent: undefined,
    };
  }

  async Action(e, state) {
    // base class is not called and contains only default implementation
    // could be improved ...

    console.log("FieldChanged", e);

    switch (e.fieldName) {
      case "disconnectItemFromEvent":
        state = this.SetDisconnectItemFromEvent(state, e.value);
        break;
      case "rejectDisconnect":
        state = this.SetRejectDisconnect(state);
        break;
      case "confirmDisconnect":
        state = await this.SetConfirmDisconnect(state, e.value);
        break;
      case "name":
        state = this.SetName(state, e.value);
        break;
      default:
        // never modify state, always clone
        state = { ...state };
        state[e.fieldName] = e.value;
        break;
    }

    state = this.SetStateChanged(state);

    return state;
  }

  UpdateStateFromItem(state, item) {
    state = super.UpdateStateFromItem(state, item);

    state = {
      ...state,
      showCellPhone:
        item.cellPhone && item.cellPhone !== this._dummyPhoneNumber,
      name: item.name ? capitalizeName(item.name) : '',
      nameTranscript: item.nameTranscription ? capitalizeName(item.nameTranscription) : '',
      events: item.shortEvents
        ? sortBy(item.shortEvents, [(ev) => -ev.id])
        : [],
    };

    return state;
  }

  SetName(state, value) {
    let translit = cyrillicToTranslit({ preset: "uk" }).transform(value);
    translit = capitalizeName(translit);

    return {...state, name: value, nameTranscription: translit};
  }

  ValidateItem(state) {
    let result = super.ValidateItem(state);

    this.validationRequired(result, state, "name");
    this.validationRequired(result, state, "nameTranscription");
    this.validationRequired(result, state, "email");
    this.validationSelectionRequired(result, state, "gender");
    //this.validationRequired(result, state, "cellPhone");
    this.validationSelectionRequired(result, state, "employerTypeId");
    this.validationSelectionRequired(result, state, "regionId");
    this.validationRequired(result, state, "employerName");
    this.validationRequired(result, state, "employerAddress");
    if (state.showCellPhone) {
      if (
        state.cellPhone === undefined ||
        state.cellPhone === "" ||
        state.cellPhone.indexOf("_") > -1
      ) {
        result.cellPhone = "Required";
      }
    }

    return result;
  }

  MapStateToModel(state) {
    let data = super.MapStateToModelMultiPart(state);

    const params = this.GetApiPutParams();

    for (let p of params) {
      if (p.name === "name_transcription" && state.nameTranscription) {
        data.append(p.name, state.nameTranscription);
      }
      else if(p.name === "cellPhone")
      {
        if(!state.cellPhone)
        {
          data.append(p.name, "");
        }
        else
        {
          data.append(p.name, state.cellPhone);
        }
      }
      //else if (p.name === "gender") {
      //   if (state.gender?.value) {
      //     data.append(p.name, state.gender.value);
      //   }
      else {
        this.MapProperty(data, state, p);
      }
    }

    return data;
  }

  GetApiPutParams() {
    return [
      {
        in: "formData",
        name: "name",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "name_transcription",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "gender",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "email",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "cellPhone",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "info",
        type: "string",
        required: false,
      },
      {
        in: "path",
        name: "id",
        type: "integer",
        format: "int32",
        required: true,
      },
      {
        in: "formData",
        name: "position",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerName",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerAddress",
        type: "string",
        required: false,
      },
      {
        in: "formData",
        name: "employerTypeId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "regionId",
        type: "integer",
        format: "int32",
        required: false,
      },
      {
        in: "formData",
        name: "document",
        type: "file",
        required: false,
      },
    ];
  }
}

export class TrainerService extends PersonService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Trainer.path, onUpdateState, lang);
  }

  async DisconnectPerson(trainerId, eventId) {
    let url = `${this.url}/trainers/${trainerId}/disconnect/${eventId}`;
    await this.Fetch("delete", url);
  }
}

export class FacilitatorService extends PersonService {
  constructor(onUpdateState, lang) {
    super(Endpoints.Facilitator.path, onUpdateState, lang);
  }

  async DisconnectPerson(facilitatorId, eventId) {
    let url = `${this.url}/facilitators/${facilitatorId}/disconnect/${eventId}`;
    await this.Fetch("delete", url);
  }
}
