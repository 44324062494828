import React from "react";
import classNames from "classnames";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";

export const styles = {
  root: {
    margin: "10px auto",
    padding: "0 20px",
    boxShadow: "none",
    borderRadius: "4px",
  },
  success: {
    border: "1px solid #8bc34a",
    backgroundColor: "#a2cf6e",
  },
  error: {
    color: "#a94442",
    border: "1px solid #ebcccc",
    backgroundColor: "#f2dede",
  },
  info: {
    color: "#fff",
    backgroundColor: "#add8e6",
  },
};

const GIZAlert = (props) => {
  const { classes, className, variant, children, ...rest } = props;

  return (
    <SnackbarContent
      className={classNames(classes.root, classes[variant], className)}
      aria-describedby="client-snackbar"
      message={<span id="client-snackbar">{children}</span>}
      {...rest}
    />
  );
};

export default withStyles(styles)(GIZAlert);
