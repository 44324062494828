import React from 'react';
import {
  Button,
  TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { Form, Field } from 'react-final-form';
import fetchResetPassword from '../../utils/fetchResetPassword';
import 'url-search-params-polyfill';
import GIZPublicHeader from "../../components/GIZPublicHeader";

const styles = theme => ({
  root: {

    minHeight: 'calc(100vh - 80px)'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '400px',
    margin: '0 auto',
    justifyContent: 'stretch'
  },
  formContainer: {
    padding: '30px 0',
    background: '#f7f9fb'
  },
  title: {
    color: '#666',
    fontSize: '30px',
    textAlign: 'left'
  },
  textField: {
    width: '100%',
    marginBottom: '20px'
  },
  button: {
    marginBottom: '20px'
  },
  message: {
    fontSize: '14px',
    backgroundColor: '#fcf8e3',
    borderColor: '#faf2cc',
    color: '#8a6d3b',
    padding: '10px 20px',
    width: '100%',
    textAlign: 'left'
  },
  error: {
    width: '100%',
    color: '#a94442',
    border: '1px solid #ebcccc',
    borderRadius: '4px',
    backgroundColor: '#f2dede',
    padding: '10px 20px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  }

})

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class ResetPassword extends React.Component {

    state = {
      error: false,
      errorMessage: 'Passwords should be equal.'
    }

    auth = async (values) => {
      const data = await fetchResetPassword('post', '/account/reset_password/finish', values);
      if (data.authenticationException) {
        this.setState({ error: true, errorMessage: data.authenticationException });
      } else {
        window.location.href = '/login';
      }
    }

    onSubmit = async values => {
      if (values.newPassword !== values.confirmPassword) {
        this.setState({ error: true });
        return false;
      }
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('reset_password_token');

      values.reset_password_token = token;
      await sleep(300)
      this.auth(values);
    }

    render() {
      const { classes } = this.props;
      const { error, errorMessage } = this.state;
      return (
        <div className={classes.root}>
          <GIZPublicHeader />
          <div className={classes.formContainer}>
            <Form
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} className={classes.form}>

                  <h3 className={classes.title}>Enter password</h3>

                  {error && (
                    <div className={classes.error}>
                      {errorMessage}
                    </div>
                  )}

                  <Field name="password">
                    {({ input }) => <TextField type="password" label="Password" margin="normal" className={classes.textField} variant="outlined" {...input} />}
                  </Field>

                  <Field name="password_confirmation">
                    {({ input }) => <TextField type="password" label="Confirm Password" margin="normal" className={classes.textField} variant="outlined" {...input} />}
                  </Field>

                  <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" type="submit" size="large" color="primary">
                      Reset Password
                    </Button>
                  </div>

                </form>
              )}
            />
          </div>


        </div>
      )
    }
}

export default withRoot(withStyles(styles)(ResetPassword));
