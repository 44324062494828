import { makeAutoObservable } from "mobx";
import { LANGUAGES, langKeyToLang, langToLangKey } from "../utils/lang";
import { I18n } from "../i18n";

export class LangStore {
  lang = LANGUAGES.UKRAINIAN;

  constructor() {
    makeAutoObservable(this, {});
  }

  get langKey() {
    return langToLangKey(this.lang);
  }

  get isEng() {
    return this.lang === LANGUAGES.ENGLISH;
  }

  get isUkr() {
    return this.lang === LANGUAGES.UKRAINIAN;
  }

  get common() {
    return I18n[this.lang].common;
  }

  get header() {
    return I18n[this.lang].header;
  }

  get events() {
    return I18n[this.lang].events;
  }

  get experts() {
    return I18n[this.lang].experts;
  }

  get participants() {
    return I18n[this.lang].participants;
  }

  get trainers() {
    return I18n[this.lang].trainers;
  }

  get facilitators() {
    return I18n[this.lang].facilitators;
  }

  get preregistration() {
    return I18n[this.lang].preregistration;
  }

  changeLang(lang) {
    this.lang = lang;
  }

  changeLangFromKey(langKey) {
    this.lang = langKeyToLang(langKey);
  }
}
