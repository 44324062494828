import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";

let openSnackbarFn;

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  handleSnackbarRequestClose = () => {
    this.setState({
      open: false,
      message: "",
    });
  };

  openSnackbar = ({ message }) => {
    this.setState({ open: true, message });
  };

  render() {
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
        TransitionComponent={Fade}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={message}
        autoHideDuration={5000}
        onClose={this.handleSnackbarRequestClose}
        open={this.state.open}
        ContentProps={{
          "aria-describedby": "snackbar-message-id",
        }}
      />
    );
  }
}

export function openSnackbar({ message }) {
  openSnackbarFn({ message });
}

export default Notifier;
