import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel
} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import fetchData from '../../../utils/fetch';
import fetchMultiPartData from '../../../utils/fetchMultiPart';
import {Redirect} from 'react-router-dom';
import {Form, Field} from 'react-final-form';
import {I18n} from '../../../i18n/';
import classNames from 'classnames';
import { stateLabels, stateLabel, milestoneDate, metricValue, metricArray } from '../utils';
import 'polyfill-array-includes';
import FileUploadProgress from '../../../components/file-upload-progress';
import {Breakpoints} from "../../../config";

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '100%',
    margin: '30px 3%',
    '& .pr15':{
      paddingRight: '15px',
    },

  },
  taC:{
    textAlign: 'center',
  },
  greyColor:{
    '& > div > div > textarea':{
      color: '#778899 !important',
      fontSize: '12px !important',
      fontWeight: '400 !important',
      letterSpacing: '0.6px !important',
    }
  },
  data:{
    justifyContent: 'space-evenly',
    '& div.dataItem':{
      '& p.pr15':{
        paddingRight: '15px',
      },
      '&:nth-child(3)':{
        paddingLeft: 35,
        [theme.breakpoints.down(Breakpoints.md)]: {
          paddingLeft: 0,
        },
      },
      '&:nth-child(4)':{
        paddingRight: 76,
        [theme.breakpoints.down(Breakpoints.md)]: {
          paddingRight: 0,
        },
      },
      '&:nth-child(5)':{
        marginLeft: -80,
        marginBottom: 15,
        [theme.breakpoints.down(Breakpoints.md)]: {
          marginLeft: 0,
        },
      },
      '&:nth-child(6)':{
        paddingRight: 0,
        [theme.breakpoints.down(Breakpoints.md)]: {
          paddingRight: 0,
        },
      },
    },
  },
  modal: {
    position: 'relative',
    width: '96%',
    padding: '32px 0',
    borderRadius: '10px',
    margin: '22vh auto',
    minHeight: '490px',
    maxHeight: '90vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: '#f7f9fb',
    [theme.breakpoints.down(1440)]: {
      padding: '15px',
    },
    '&:focus':{
      outline: '5px solid transparent',
    },
    '& label': {
      fontSize: '19pt',
      paddingBottom: '0',
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '37px',
      textTransform: 'uppercase',
      color: '#88ADF0',
      marginBottom: '50px !important',
      letterSpacing: '1.5px',
    },
    '& form': {
      padding: '0 10px',
    },
    '& .text-area': {
      marginBottom: '40px',
      '& label': {
        transform: 'translate(0, 0) scale(1)',
        marginBottom: '10px',
        fontSize: '19pt',
      }
    },
    '& p': {
      color: '#778899',
      fontSize: '12px',
      marginTop: '7px',
    },
    '& pre': {
      font: 'inherit',
      color: '#778899',
      whiteSpace: 'pre-wrap',
      textAlign: 'justify'
    }
  },
  buttons: {
    textAlign: 'right',
    right: '40px',
    position: 'relative',
    bottom: '20px',
    width: 'calc(100% - 40px)',
    [theme.breakpoints.down(1440)]: {
      position: 'inherit',
    },
    '& .button-submit': {
      marginRight: '0',
      maxHeight: '47px',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '40px',
    backgroundColor: '#E1EDFB'
  },
  buttonCancel: {
    marginRight: '20px'
  },
  block: {
    '& .line': {
      display: 'flex',
      alignItems: 'center',
      '& .text-field': {
        marginRight: '10px',
        '& input':{
          textAlign: 'center',
        },
      },
      '& span': {
        color: '#778899',
        fontSize: '12px'
      },
      '&> div.text-field':{
        width: 84,
        maxWidth: '84px',
        minWidth: '84px',
      },

    },
    '& .upload-label': {
      textAlign: 'center',
      marginBottom: 15,
    },
    '& .btn': {
      display: 'block',
      width: '45px',
      height: '45px',
      borderRadius: '6px',
      background: '#c3bdd2 no-repeat center center',
      '&:hover': {
        background: '#958DAE no-repeat center center'
      },
      marginBottom: '10px',
      '&.btn-word': {
        backgroundImage: 'url(/images/icons/icon-word.png)',
        border: '3px solid #fe8368',
        position: 'relative',
        marginLeft: '5px',
        marginRight: '5px',
        '& img': {
          position: 'relative',
          top: '-9px',
          left: '30px',
        },
        '& i': {
          display: 'block',
          color: '#ffffff',
          borderRadius: '50%',
          background: '#fe8368',
          position: 'absolute',
          top: '-7px',
          right: '-7px',
          fontSize: '14px',
          fontWeight: '900',
          textAlign: 'center',
          lineHeight: '14px',
          width: '14px',
        }
      },
      '&.btn-upload': {
        background: '#77d2b7 no-repeat center center / 50px 50px',
        transform: 'rotate(180deg)',
        backgroundImage: 'url(/images/icons/icon-download.svg)',
        cursor: 'pointer',
        '&:hover': {
          background: '#9bd9c8 no-repeat center center / 50px 50px',
          backgroundImage: 'url(/images/icons/icon-download.svg)',
        },
      },
    },
  },
  arrowIcon: {
    background: '#B4C3D1',
    height: '47px',
    width: '47px',
    display: 'block',
    position: 'absolute',
    borderRadius: '0 6px 6px 0',
    right: '0',
    left: '77px',
    top: '0',
    '& span': {
      background: 'url(/images/icons/arrow-down-white.svg) no-repeat center center',
      backgroundSize: '17px 17px',
      height: '47px',
      width: '47px',
      display: 'block',
      position: 'absolute',
      right: '0',
      top: '0',
    }
  },
  selector: {
    position: 'relative',
    marginTop: '10px',
    '& .on': {
      backgroundColor: '#77D2B7'
    },
    '& .minor': {
      backgroundColor: '#DDD330'
    },
    '& .major': {
      backgroundColor: '#FCBA2B'
    },
    '& .off': {
      backgroundColor: '#FE8368'
    },
    '& .not': {
      backgroundColor: '#A7A9AC'
    },
    '&:hover': {
      '& .list': {
        display: 'block'
      },
    },
    '& .selected-value': {
      fontSize: '11px',
      position: 'relative',
      color: '#778899',
      height: '47px',
      width: '123px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      background: '#e1edfb',
      padding: '0 47px 0 7px',
      borderRadius: '6px',
      fontSize: '12px',
      letterSpacing: '1.2px',
    },
    '& .list': {
      display: 'none',
      position: 'absolute',
      borderRadius: '6px',
      top: '47px',
      overflow: 'hidden',
      zIndex: '2',
      '& a': {
        textDecoration: 'none',
        fontSize: '11px',
        color: '#fff',
        height: '47px',
        width: '123px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
      },
      '&:hover': {
        display: 'block'
      }
    }
  },
  wrapBtn: {
    marginBottom: '0',
    marginTop: '55px',
  },
  wrapFiles:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class MilestoneData extends React.Component {
  state = {
    open: true,
    item: null,
    valuesEditEnabled: false,
    files: [],
    fileUploadModal: false,
    fileUploadStatus: ''
  }

  componentDidMount() {
    const milestone = this.props.item;
    const indicator = this.props.indicator;
    const user = this.props.user;
    metricArray(indicator).map((el, i) => {
      const field = `quantitiveValue${i + 1}`;
      milestone[field] = metricValue(indicator, milestone[field], i)
    })

    this.setState({item: milestone, valuesEditEnabled: (!indicator.isImportable && !indicator.isAutomated || user.isAdmin || indicator.fake)})
  }

  handleClose = () => {
    this.setState({open: false});
  }

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/indicator/${this.props.indicator.id}`}/>
    }
  }

  changeStatus = (newStatus) => {
    this.setState({item: {...this.state.item, status: newStatus}})
  }

  saveItem = async (values) => {
    this.setState({ fileUploadModal: 'true', fileUploadStatus: 'success' });
    const item = this.state.item;
    var data = new FormData();
    data.set('indicatorId', this.props.indicator.id)
    data.set('status', item.status);

    let fields = [ 'id', 'qualitiveMeasurement' ]

    if (this.state.valuesEditEnabled){
      fields = fields.concat(['quantitiveValue1', 'quantitiveValue2',
      'quantitiveValue3', 'quantitiveValue4'])
    }
    fields.forEach(function (x) {
      let value = values[x]
      data.set(x, value === undefined || value === 'N/A' ? null : value)
    })


    if (this.state.files) {
      const filesCount = this.state.files.length;
      for (let i = 0; i < filesCount; i++) {
        data.append('files[]', this.state.files[i])
      }
    }

    await fetchMultiPartData('put', `/measurements/milestones/${values.id}`, data);

    this.setState({open: false});

    this.props.handleItemSave();
  }

  handleFileUpload = (e) => {
    this.setState({ fileUploadModal: true, fileUploadStatus: 'loading' })
    const files = e.target.files;
    const array = [];

    for (var i = 0; i < files.length; i++) {
      array.push(files.item(i));
    }

    this.setState({ fileUploadModal: false, files: this.state.files.concat(array) });

  }

  onSubmit = async values => {
    await sleep(300)
    this.saveItem(values);
  }

  handleRemoveFile = file => {
    const lang = this.props.lang;
    return async (event) => {
      if (window.confirm(`${I18n[lang].common.deleteConfirm}${I18n[lang].common.file.toLowerCase()} ${file.name}?`)) {
        event.currentTarget.remove()
        await fetchData('delete', `/measurements/milestones/attachments/${file.id}`);
      }
    }
  }

  render() {
    const {classes, lang, indicator} = this.props;
    const {item, valuesEditEnabled, files } = this.state;
    return (
      <>
        {this.renderRedirect()}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <a className="close" onClick={this.handleClose}>
              <Close/>
            </a>
            <Typography gutterBottom variant="h2" component="h2">
              ENTER OR EDIT MILESTONE DATA
            </Typography>

            <Typography component="div">
              <Form
                onSubmit={this.onSubmit}
                initialValues={item}
                render={({handleSubmit, form, submitting, pristine, values}) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={24} alignItems='flex-start' className={classes.data} >

                      <Grid item className='dataItem' xs={1} lg={1} >
                        <InputLabel className={classes.taC} >Date</InputLabel>
                        <p className={classNames('pr15', classes.taC)} >{milestoneDate(item)}</p>
                      </Grid>

                      <Grid item className='dataItem' xs={2} lg={1} >
                        <InputLabel>Status</InputLabel>
                        <div className={classes.selector}>
                          {stateLabel(item.status)}
                          <i className={classes.arrowIcon}><span></span></i>
                          <div className="list">
                            {Object.keys(stateLabels).map((x, i) => {
                              const [klass, title] = stateLabels[x];
                              return (
                                <a
                                  key={i}
                                  className={klass}
                                  href="#"
                                  onClick={
                                    (event) => {
                                      event.preventDefault();
                                      this.changeStatus(x)
                                    }}
                                >
                                {title}
                                </a>
                              )
                            })}
                          </div>
                        </div>
                      </Grid>

                      <Grid item className='dataItem' xs={3} lg={2} >
                        <InputLabel>Milestone</InputLabel>
                        <pre>{item.description}</pre>
                      </Grid>

                      {indicator.isAutomated && (
                      <Grid item className='dataItem' xs={2} lg={2} >
                        <div className={classes.block}>
                          <InputLabel>Auto measurement</InputLabel>
                          {metricArray(indicator).map((el, i) => {
                            const field = `autoQuantitiveValue${i + 1}`;
                            return (
                              <div className="line" key={i}>
                                <span>{metricValue(indicator, item[field], i)}</span>
                              </div>
                            )
                          })}
                        </div>
                      </Grid>
                      )}

                      <Grid item className='dataItem' xs={4} lg={2} >
                        <div className={classes.block}>
                          <InputLabel>Quantitative measurement</InputLabel>
                          {metricArray(indicator).map((el, i) => {
                            const field = `quantitiveValue${i + 1}`;
                            return (
                              <div className="line" key={i}>
                                <Field name={field}>
                                  {({input}) =>
                                    <TextField
                                      margin="normal"
                                      className={classNames('text-field')}
                                      variant="outlined"
                                      disabled={!valuesEditEnabled}
                                      {...input}
                                    />
                                  }
                                </Field>
                                <span>{indicator[`measurementDescription${i + 1}`]}</span>
                              </div>
                            )
                          })}
                        </div>
                      </Grid>

                      <Grid item className='dataItem' xs={10} lg={3} >
                        <Field name="qualitiveMeasurement">
                          {({input}) =>
                            <TextField
                              label='Qualitative measurement'
                              multiline
                              rows="6"
                              rowsMax="9"
                              className={classNames('text-area', {[classes.greyColor] : (item.qualitiveMeasurement === null)})}
                              variant="outlined"
                              {...input}
                            />}
                        </Field>
                      </Grid>

                      <Grid item className='dataItem' xs={2} lg={1} >
                        <div className={classNames(classes.block, classes.wrapFiles)}>
                          <InputLabel className="upload-label">Upload files</InputLabel>
                          <a 
                            className='btn btn-upload' 
                            onClick={event => {
                              event.preventDefault();
                              this.fileInput.click()
                            }}
                          />
                          <input 
                            onChange={this.handleFileUpload} 
                            type="file" 
                            name="fileInput" ref={
                              input => {
                                if (!input) return;
                                this.fileInput = input;
                              }
                          }
                            multiple="multiple"
                            style={{'display': 'none'}}
                          />

                          {item && item.files.map((file, i) => {
                            return (
                              <a title={`Delete '${file.name}'`}
                                className="btn btn-word"
                                onClick={this.handleRemoveFile(file)}
                                key={i}
                              >
                                <img src="/images/icons/icon-delete.png" alt="delete" />
                              </a>
                            )
                          })}
                          {files && files.map((file, i) => {
                            return (
                              <a
                                title={`Delete '${file.name}'`}
                                className="btn btn-word"
                                onClick={this.handleRemoveFile(file)}
                                key={i}
                              >
                                <img src="/images/icons/icon-delete.png" alt="delete" />
                              </a>
                            )
                          })}
                        </div>
                      </Grid>

                    </Grid>

                    <Grid container spacing={24} alignItems='center' className={classes.wrapBtn}>
                      <Grid item xs={12} className={classes.buttons}>
                        <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                          <i></i>
                          {I18n[lang].common.cancel}
                        </Button>
                        <Button variant="contained" className='button-submit' type="submit" color="primary">
                          <i></i>
                          {I18n[lang].common.save}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </Typography>
          </div>
        </Modal>
        {this.state.fileUploadModal && (
          <FileUploadProgress status={this.state.fileUploadStatus} />
        )}
      </>
    );
  }
}

export default withRoot(withStyles(styles)(MilestoneData));
