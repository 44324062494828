export const styles = theme => ({
  root: {
    textAlign: 'left',
    padding: '0 2.3vw',
    background: '#f7f9fb',
    width: '100%',
    margin: '0',
    display: 'flex !important',
    '& div': {
      boxShadow: 'none',
    },
    '& h3': {
      fontSize: '36px',
      lineHeight: '1 !important',
      marginBottom: '15px',
      letterSpacing: '2pt',
      color: '#fdbb2d',
      marginTop: '39px'
    },
    '& h4': {
      fontSize: '24px',
      fontWeight: '900',
      letterSpacing: '1.2px',
      textAlign: 'left',
      color: '#191970',
      marginBottom: '9px',
      marginTop: '0',
    },
    '& .resultId': {
      marginRight: '5px'
    },
  },
  SO: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  RES: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  subTitle: {
    fontSize: '11px',
    fontWeight: '900',
    color: '#191970',
    lineHeight: '2'
  },
  indicatorsTable: {
    minWidth: 'auto',
    cursor: 'pointer'
  },
  indicatorState: {
    position: 'relative',
    '& .on': {
      backgroundColor: '#77D2B7'
    },
    '& .minor': {
      backgroundColor: '#DDD330'
    },
    '& .major': {
      backgroundColor: '#FCBA2B'
    },
    '& .off': {
      backgroundColor: '#FE8368'
    },
    '& .not': {
      backgroundColor: '#A7A9AC'
    },
    '& .selected-value': {
      fontSize: '11px',
      color: '#fff',
      height: '26px',
      width: '109px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '6px',
      '&:hover + .list': {
        display: 'block'
      }
    },
  }


})
