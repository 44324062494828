import React from 'react';
import {
  Button,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import fetchResetPassword from '../../utils/fetchResetPassword';
import GIZPublicHeader from "../../components/GIZPublicHeader";

const styles = theme => ({
  root: {
    padding: '0',
    minHeight: 'calc(100vh - 80px)'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '400px',
    margin: '0 auto',
    justifyContent: 'stretch'
  },
  formContainer: {
    padding: '30px 0',
    background: '#f7f9fb'
  },
  title: {
    color: '#666',
    fontSize: '30px',
    textAlign: 'left'
  },
  textField: {
    width: '100%',
    marginTop: '20px',
    background: '#e1edfb',
    '&.error-field fieldset': {
      border: '1px solid #fe8368',
      borderLeftWidth: '3px'
    }
  },
  button: {
    marginBottom: '20px'
  },
  message: {
    fontSize: '14px',
    backgroundColor: '#fcf8e3',
    borderColor: '#faf2cc',
    color: '#8a6d3b',
    padding: '10px 20px',
    width: '100%',
    textAlign: 'left'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const required = value => (value ? undefined : "Required");

class ForgotPassword extends React.Component {
    state = {
        emailSubmitted: false,
        message: 'Enter the email address you used to register'
    }

    auth = async (values) => {
        const data = await fetchResetPassword('post', '/account/reset_password/init', values);
        this.setState({emailSubmitted: true, message: 'Email with reset password instructions was sent to you.' });
    }

    onSubmit = async values => {
        await sleep(300)
        this.auth(values);
    }

    render() {
      const { classes } = this.props;
      const { message, emailSubmitted } = this.state;
      return (
        <div className={classes.root}>
            <GIZPublicHeader />
            <div className={classes.formContainer}>
              <Form
                onSubmit={this.onSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>

                    <h3 className={classes.title}>Reset your password</h3>

                    <p className={classes.message}>{message}</p>

                    <Field name="email" validate={required}>
                      {({ input, meta }) =>
                      <TextField
                        label="Your email"
                        margin="normal"
                        className={classNames(classes.textField, {'error-field': meta.error && meta.touched} ) }
                        variant="outlined"
                        {...input}
                      />}
                    </Field>

                    <div className={classes.buttonContainer}>
                      <Button className={classes.button} variant="contained" type="submit" size="large" color="primary">
                        Reset Password
                      </Button>
                    </div>

                  </form>
                )}
              />
            </div>

        </div>
      )
    }
}

export default withRoot(withStyles(styles)(ForgotPassword));
