import React from "react";
import {
  Grid,
  Typography,
  InputLabel,
  TextField,
  Button,
} from "@material-ui/core";
import { Mail } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchMultiPartData from "../../utils/fetchMultiPart";
import { Form, Field } from "react-final-form";
import Select from "react-select";
import makeAnimated from "react-select/lib/animated";
import classNames from "classnames";
import Loader from "../../components/loader";
import { selectStyles } from "../../style/select-styles";

const styles = (theme) => ({
  root: {
    minHeight: "calc(100vh - 210px)",
  },
  textField: {
    width: "100%",
    marginBottom: "20px",
    background: "#e1edfb",
    "&.error-field fieldset": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
  formControl: {
    width: "100%",
    marginBottom: "20px",
    background: "#e1edfb",
  },
  textArea: {
    width: "100%",
    marginBottom: "20px",
    background: "#e1edfb",
    "& textarea": {
      resize: "vertical",
      overflow: "hidden",
      minHeight: "150px",
    },
    "&.error-field fieldset": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
  },
  select: {
    marginBottom: "20px",
    width: "100%",
    marginTop: "10px",
    "&> div": {
      background: "#e1edfb",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    "&.error-field > div": {
      border: "1px solid #fe8368",
      borderLeftWidth: "3px",
    },
    "& .css-15k3avv": {
      zIndex: "9999",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "400px",
    margin: "0 auto",
    justifyContent: "stretch",
  },
  formContainer: {
    padding: "30px 0",
  },
  alignRight: {
    textAlign: "right",
  },
  success: {
    width: "100%",
    color: "#a94442",
    border: "1px solid #8bc34a",
    borderRadius: "4px",
    backgroundColor: "#a2cf6e",
    padding: "10px 20px",
    width: "400px",
    margin: "0 auto 50px",
  },
  error: {
    width: "100%",
    color: "#a94442",
    border: "1px solid #ebcccc",
    borderRadius: "4px",
    backgroundColor: "#f2dede",
    padding: "10px 20px",
  },
  inputFile: {
    textAlign: "left",
    "& label": {
      display: "block",
      marginBottom: "10px",
      "&.error-field": {
        backgroundColor: "red",
      },
    },
    "& input": {
      display: "block",
      width: "0.1px",
      height: "0.1px",
      opacity: "0",
      overflow: "hidden",
      position: "absolute",
      zIndex: "-1",
    },
    "& input + label": {
      fontSize: "0.8rem",
      fontWeight: "700",
      color: "#fff",
      backgroundColor: "#add8e6",
      display: "inline-block",
      marginBottom: "0",
      padding: "5px 20px",
      borderRadius: "15px",
      cursor: "pointer",
    },
    "& div": {
      marginTop: "4px",
      fontSize: "12px",
      color: "gray",
      textAlign: "left",
    },
    "& ol": {
      paddingLeft: "10px",
    },
    "& h4": {
      marginBottom: "0",
    },
  },
});

const fileTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const required = (value) => (value ? undefined : "Required");

const categories = [
  { value: "technical", label: "Technical" },
  { value: "organizational", label: "Organizational" },
  { value: "other", label: "Other" },
];

class Support extends React.Component {
  state = {
    errorCategory: false,
    successMessage: false,
    category: {},
    message: "",
    isTechnical: false,
    selectedCategory: {},
    errorScreenshot: false,
    errorScreenshotType: false,
    screenshotFileName: "",
    files: {},
  };

  saveItem = async (values) => {
    let formErrorState = false;

    let data = new FormData();

    for (let name in values) {
      if (name == "screenshot") continue;
      data.append(name, values[name]);
    }

    const user = this.props.user;

    if (user && user.authorities.indexOf("ROLE_GUEST") >= 0) {
      data.append("category", "feedback");
    } else if (!this.state.selectedCategory.value) {
      this.setState({ errorCategory: true });
      formErrorState = true;
    } else {
      data.append("category", this.state.selectedCategory.value);
    }

    if (this.state.isTechnical) {
      const fileInfo = this.state.files.screenshots;
      [...fileInfo.input.files].forEach((file) => {
        data.append("screenshots[]", file);
      });

      if (!fileInfo || !fileInfo.input.files) {
        formErrorState = true;
      }
    }

    if (formErrorState) {
      this.setState({ loading: false });
      return false;
    }

    await fetchMultiPartData("post", "/support", data);

    this.setState({ loading: false, successMessage: true });
  };

  onSubmit = async (values) => {
    this.setState({ loading: true });
    await sleep(300);
    this.saveItem(values);
  };

  handleDropdownChange = (option) => {
    this.setState({ [option]: false });
  };

  handleCategory = (selectedOption) => {
    this.setState({
      isTechnical: !!(selectedOption.value === "technical"),
      selectedCategory: selectedOption,
    });
  };

  handleFiles = (name, multi) => {
    var fileInput = document.querySelector("#screenshot");
    return () => {
      if (fileInput && fileInput.files[0]) {
        const files = this.state.files;
        if (!files.screenshots) {
          files.screenshots = { input: fileInput };
        }
        files.screenshots.names = [...fileInput.files].map((file) => file.name);
        this.setState({ files: files });
      }
    };
  };

  handleFileName = () => {
    var file = document.querySelector(`#screenshot`);
    if (file && file.files[0]) {
      this.setState({ [`screenshotFileName`]: file.files[0].name });
    }
  };

  render() {
    const { classes, lang, user } = this.props;
    const {
      category,
      message,
      errorCategory,
      successMessage,
      isTechnical,
      selectedCategory,
      screenshotFileName,
      errorScreenshot,
      errorScreenshotType,
      files,
    } = this.state;

    const isGuest = user && user.authorities.indexOf("ROLE_GUEST") >= 0;

    const fileInfo = files.screenshots;
    return (
      <div className={classes.root}>
        {this.state.loading && <Loader />}

        <Typography
          gutterBottom
          variant="h2"
          component="h2"
          style={{ color: "#fdbb2d" }}
        >
          {isGuest ? "Feedback" : "Contact Support"}
        </Typography>

        {successMessage && (
          <div className={classes.success}>Your message was sent.</div>
        )}

        {!successMessage && (
          <Typography className={classes.data}>
            <div className={classes.formContainer}>
              <Form
                onSubmit={this.onSubmit}
                render={({
                  handleSubmit,
                  form,
                  submitting,
                  pristine,
                  values,
                }) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    {!isGuest && (
                      <Field name="category">
                        {({ input, meta }) => (
                          <>
                            <InputLabel>Category</InputLabel>
                            <Select
                              styles={selectStyles()}
                              className={classNames(classes.select, {
                                "error-field": errorCategory,
                              })}
                              closeMenuOnSelect={true}
                              components={makeAnimated()}
                              options={categories}
                              onMenuOpen={() =>
                                this.handleDropdownChange("errorCategory")
                              }
                              onChange={this.handleCategory}
                              value={selectedCategory}
                            />
                          </>
                        )}
                      </Field>
                    )}

                    <Field name="message" validate={required}>
                      {({ input, meta }) => (
                        <TextField
                          label="Message"
                          margin="normal"
                          className={classNames(classes.textArea, {
                            "error-field": meta.error && meta.touched,
                          })}
                          variant="outlined"
                          multiline
                          rows="4"
                          rowsMax="20"
                          {...input}
                        />
                      )}
                    </Field>

                    {isTechnical && (
                      <Field name="screenshot">
                        {() => (
                          <div class={classes.inputFile}>
                            <input
                              type="file"
                              id="screenshot"
                              name="screenshot"
                              onChange={this.handleFiles()}
                              multiple="multiple"
                              accept=".jpg,.jpeg,.png,.gif"
                            />
                            <label for="screenshot">Add screenshot(s)</label>
                            {fileInfo && fileInfo.names && (
                              <>
                                <br />
                                <span>{fileInfo.names.join(", ")}</span>
                              </>
                            )}
                            {!(fileInfo && fileInfo.names) && (
                              <div className={classes.error}>
                                Screenshot required
                              </div>
                            )}
                            {lang === "Eng" ? (
                              <div>
                                <h4>To Take Quick Screenshots:</h4>
                                <ol>
                                  <li>
                                    Press the PrtScn button to copy the
                                    screenshot to the clipboard. Open an image
                                    editing tool (such as Paint), paste the
                                    screenshot in the editor and save the file
                                    from there.
                                  </li>
                                  <li>
                                    Press the Windows+PrtScn buttons on your
                                    keyboard to save the screenshoot. The
                                    screenshot will be saved to the Pictures ->
                                    Screenshots folder.
                                  </li>
                                  <li>Use the built-in Snipping Tool.</li>
                                </ol>
                                <h4>To add multiple screenshots:</h4>
                                <p>
                                  Press CTRL or SHIFT when selecting multiple
                                  images
                                </p>
                              </div>
                            ) : (
                              <div>
                                <h4>Як зробити скріншот:{lang}</h4>
                                <ol>
                                  <li>
                                    Натисніть клавішу PrtScr, щоб зберегти
                                    скріншот у буфер обміну. Відкрийте графічний
                                    редактор, наприклад Paint, і натисніть
                                    комбінацію Ctrl+V. Скріншот відкриється в
                                    графічному редакторі, і його можна буде
                                    зберегти.
                                  </li>
                                  <li>
                                    Натисніть комбінацію Windows+PrtScr.
                                    Зображення буде збережене у папці “Знімки
                                    екрану”/”Screenshots”
                                  </li>
                                  <li>
                                    Використайте програму "Ножиці"/”Snipping
                                    Tool”
                                  </li>
                                </ol>
                                <h4>Як додати декілька скріншотів:</h4>
                                <p>
                                  Утримуйте клавішу CTRL або SHIFT коли
                                  вибираєте декілька зображень
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                      </Field>
                    )}

                    <Grid container spacing={24} alignItems="center">
                      <Grid item xs={12} className={classes.alignRight}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                        >
                          <Mail />
                          &nbsp; Send
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </div>
          </Typography>
        )}
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Support));
