
const colors = {

  yellow: "#ECBC2D",
  red: "#FE7153",
}

export const config = {
  fontFamily:"Montserrat, san-serif",

  pageHeader: {
    fontSize: "40px",
    fontWeight: "700!important",
    color: colors.yellow,
  },
  input: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#191970",
    background: "#e1edfb",
    borderRadius: "5px",
    letterSpacing: "0.5px",
    padding: "5.5px 7px",
  },
  readOnly: {
    fontSize: "16px",
    color: "#191970",
  },
  label: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#88adf0",
    background: "#e1edfb",
    letterSpacing: "0.7px",
  },

  table: {
    header: {
      fontSize: "16px",
      fontWeight: "500",
      color: "#6495ed",
    },
    data: {
      fontSize: "16px",
      color: "#191970",
      fontWeight: "400",
    },

  },
  error: {
    color: "#fe8368 !important",
  },

  breakpoints: {
    small: 1440,
  },

  small: {
    pageHeader: {
      fontSize: "24px",
      fontWeight: "700!important",
    },
    subTitle: {
      fontSize: "21px!important",
      fontWeight: "400!important",
    },
    label: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#122042",
    },
    readOnly: {
      fontSize: "16px",
    },
    data: {
      fontWeight: 400,
      fontSize: "16px",
      color: "#122042",
    },
    input: {
      fontWeight: 400,
      padding: "8px",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#122042",
    },
  },
};
