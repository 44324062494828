import React from "react";
import { render } from "react-dom";
import { configure } from "mobx";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { RootStore } from "./stores/RootStore";
import { StoreProvider } from "./stores/context";
import { variables } from "./utils/variables";
import Index from "./pages/index";

import "isomorphic-fetch";

window.__app__ = {
  tag: process.env.REACT_APP_CI_COMMIT_TAG,
  commit: process.env.REACT_APP_CI_COMMIT_SHORT_SHA,
};

Sentry.init({
  environment: variables.environment,
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

configure({
  enforceActions: "always",
});

const history = createBrowserHistory();
const store = new RootStore(history);

render(
  <Sentry.ErrorBoundary fallback={"An error has occured"}>
    <StoreProvider value={store}>
      <Index history={history} />
    </StoreProvider>
  </Sentry.ErrorBoundary>,
  document.querySelector("#root")
);
