import { config } from "./config";
import {Breakpoints} from "../config";

const small = ["sm", "xs"];
const isSmall = (width) =>(small.includes(width))

export const selectStyles = (width = "md") => ({
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    fontFamily: config.fontFamily,
    fontSize:  isSmall(width) ? config.small.input.fontSize : config.input.fontSize,
    color:  isSmall(width) ? config.small.input.color : config.input.color,
  }),
  container: (provided) => ({
    ...provided,
    borderColor: "transparent",
    borderRadius: config.input.borderRadius,
    borderTopLeftRadius: config.input.borderRadius,
    borderBottomLeftRadius: config.input.borderRadius,
    fontFamily: config.input.fontFamily,
    fontSize:  isSmall(width) ? config.small.input.fontSize : config.input.fontSize,
    color: isSmall(width) ? config.small.input.color : config.input.color,

    "&.errorSelect": {
      borderLeftStyle: "solid",
      borderLeftWidth: 7,
      borderColor: " #fe8368",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontFamily: config.fontFamily,
    fontSize: isSmall(width) ?config.small.input.fontSize : config.input.fontSize,
    color:  isSmall(width) ? config.small.input.color : config.input.color,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: config.input.fontFamily,
    fontSize: isSmall(width) ? config.small.input.fontSize : config.input.fontSize,
    color:  isSmall(width) ?config.small.input.color : config.input.color,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    whiteSpace:"normal"
  }),

  control: (provided) => ({
    ...provided,
    background: config.input.background,
    minHeight: "inherit",
    borderTopLeftRadius: config.input.borderRadius,
    borderBottomLeftRadius: config.input.borderRadius,
    fontFamily: config.input.fontFamily,
    fontSize: isSmall(width) ? config.small.input.fontSize : config.input.fontSize,
    color: isSmall(width) ? config.small.input.color : config.input.color,
    borderStyle: "none",
    "&:hover": {
      boxShadow: "inset 0 0 0px 2px #6495ed",
    },
    "& >div:first-child":
        {
          paddingTop:"4px",
          paddingBottom:"4px",
        },

  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  dropdownIndicator: (provided) => ({
    borderTopRightRadius: config.input.borderRadius,
    borderBottomRightRadius: config.input.borderRadius,
    height: "100%",
    width: isSmall(width) ?  "40px" : "28px",
    backgroundColor: "#B4C3D1",
    background:
      "#b4c3d1 url(/images/icons/icon-arrBot.png) no-repeat center center / 20px 20px",
    color: "transparent",
  }),
  option:
      (provided, state) => ({
        ...provided,
        background: state.isFocused || state.isSelected  ? "#e1edfb" :  "#ffffff",
        ':active': "#e1edfb",
        color:  config.small.input.color,
      })
});
