import React from "react";

import {
    Paper,
    Grid,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Hidden
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {I18n} from "../../i18n/";
import {isEmpty} from "lodash";
import {Doughnut} from "react-chartjs-2";
import {Link} from "react-router-dom";
import classNames from "classnames";
import styles from "../../style/styles.js";
import "./chart.scss";
import Edit from "../../components/dialog/edit.js";
import CommunityService from "../../services/CommunityService";
import {stableSort, getSorting} from "../../utils/tables.js";

import {
    FormTextField,
    FormMultiSelectField,
    FormLabel, FormDateField, FormNumberField,
} from "../../components/dialog/formField";
import {ResponsiveTable} from "../../components";

class Item extends React.Component {
    async handleGetData(service, editorState) {
        const europeActionsList = await service.GetAllSelectOptions(
            "europe_actions"
        );

        const grantsList = await service.GetAllSelectOptions(
            "cso_grants",
            undefined,
            (item) => ({
                label: item.projectTitle,
            })
        );
        const projectsList = await service.GetAllSelectOptions(
            "donor_projects",
            undefined,
            (item) => ({
                label: `${item.title} (${item.donor})`,
            })
        );

        const state = {
            europeActionsList,
            grantsList,
            projectsList,
        };

        return state;
    }

    render() {
        const {classes, lang} = this.props;

        const i18n = I18n[this.props.lang].events;
        const {...rest} = {i18n, ...this.props};

        return (
            <Edit
                i18n={i18n}
                handleGetData={this.handleGetData}
                onAfterGetData={this.onAfterGetData}
                serviceClass={CommunityService}
                {...rest}
                hideSaveButton={true}
                controllButtons={() => <></>}
                header={(fieldProps) => (
                    <Grid
                        container

                        alignItems="center"
                        direction="row"
                        justify="space-between"
                    >
                        <Grid item xs={12} md={6}>
                            <Typography
                                gutterBottom
                                variant="h2"
                                component="h2"
                                className={classes.noCapitalize}
                            >
                                {fieldProps.formState.name}
                            </Typography>
                        </Grid>
                        <Hidden only={['xs', 'sm']}>
                            <Grid item>
                                <Link
                                    to="#"
                                    className={classNames(classes.button, {
                                        disabled: !fieldProps.formState.print_info,
                                    })}
                                    onClick={() => fieldProps.action({fieldName: "downloadPdf"})}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        className={classNames(
                                            classes.buttonGrey,
                                            classes.buttonDialogHeader
                                        )}
                                    >
                                        <i/>
                                        {I18n[lang].common.print}
                                    </Button>
                                </Link>
                            </Grid>
                        </Hidden>
                    </Grid>
                )}
                render={(fieldProps) => (

                    <Grid container direction="row" >
                        <Grid xs={12} item>
                            {/* 1st Row */}
                            <Grid
                                container
                                className={classes.layoutColumn}
                                direction="row"
                                alignItems="stretch"
                            >
                                <Grid item xs={12} md={5}>
                                    <Paper className={classes.paper}>
                                        <Grid container className={classes.layoutColumn}>
                                            <Grid item>
                                                <Typography variant="h3">
                                                    {I18n[lang].communities.generalInformation}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                direction="row"

                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={12} sm={6}
                                                    direction="column"
                                                    className={classes.layoutColumn}
                                                >

                                                    {fieldProps.formState.editMode && (
                                                        <>
                                                            <Grid item>
                                                                <FormTextField
                                                                    fieldName="nameUa"
                                                                    {...fieldProps}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>

                                                            <Grid item>
                                                                <FormTextField
                                                                    fieldName="nameEn"
                                                                    {...fieldProps}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>
                                                        </>
                                                        )}
                                                    {!fieldProps.formState.editMode && (
                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="ahCentre"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>)}

                                                    {fieldProps.formState.editMode && (
                                                        <>
                                                            <Grid item>
                                                                <FormTextField
                                                                    fieldName="centreUa"
                                                                    {...fieldProps}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>

                                                            <Grid item>
                                                                <FormTextField
                                                                    fieldName="centreEn"
                                                                    {...fieldProps}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>
                                                        </>
                                                    )}

                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="region"
                                                            {...fieldProps}
                                                            isEditable={false}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormNumberField
                                                            fieldName="councilSize"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>

                                                    {fieldProps.formState.editMode && (
                                                    <Grid item>
                                                        <FormDateField
                                                            label={
                                                                fieldProps.formState.isUrban
                                                                    ? fieldProps.i18n.establishment
                                                                    : fieldProps.i18n.created
                                                            }
                                                            fieldName="createdDateTime"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>)}
                                                    {!fieldProps.formState.editMode && (
                                                        <Grid item>
                                                            <FormTextField
                                                                label={
                                                                    fieldProps.formState.isUrban
                                                                        ? fieldProps.i18n.establishment
                                                                        : fieldProps.i18n.created
                                                                }
                                                                fieldName="created"
                                                                {...fieldProps}
                                                                isEditable={false}
                                                            />
                                                        </Grid>)}
                                                </Grid>

                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    direction="column"
                                                    className={classes.layoutColumn}
                                                >
                                                    <Grid item>
                                                        <FormNumberField
                                                            fieldName="population"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="square"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="koatuu"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormTextField
                                                            fieldName="admin3pCode"
                                                            {...fieldProps}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>
                                                    {fieldProps.formState.mapLink && (
                                                        <Hidden only={['xs', 'sm']}>
                                                            <Grid item>
                                                                <div className={classes.infoMap}>
                                                                    <Link
                                                                        to={`/map?coordinates=${fieldProps.formState.mapLink}`}
                                                                    >
                                                                        {fieldProps.i18n.showOnMap}
                                                                    </Link>
                                                                </div>
                                                            </Grid>
                                                        </Hidden>
                                                    )}

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} md={7}>
                                    <Paper className={classes.paper}>
                                        <Grid container direction="column">
                                            {/* HEADER: */}
                                            <Grid item>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="space-between"
                                                    className={classes.layoutColumn}
                                                >
                                                    <Grid item>
                                                        <Typography variant="h3">
                                                            {fieldProps.i18n.supportHromada}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item>
                                                        {fieldProps.formState.isAdmin && (
                                                            <>
                                                                {fieldProps.formState.editMode ? (
                                                                    <Link to="#">
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            className={classNames("btn", [
                                                                                classes.adjustTwoRight,
                                                                            ])}
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                fieldProps.action({
                                                                                    fieldName: "setSave",
                                                                                })
                                                                            }
                                                                        >
                                                                            <img
                                                                                src="/images/icons/button-save.svg"
                                                                                alt="save"
                                                                                width="17"
                                                                            />
                                                                            {fieldProps.i18nCommon.save}
                                                                        </Button>
                                                                    </Link>
                                                                ) : (
                                                                    <Link to="#">
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            className={classNames(
                                                                                "btn",
                                                                                "btn-edit",
                                                                                [classes.adjustTwoRight]
                                                                            )}
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                fieldProps.action({
                                                                                    fieldName: "setEdit",
                                                                                })
                                                                            }
                                                                        >
                                                                            <img
                                                                                src="/images/icons/icon-action-edit.svg"
                                                                                alt="edit"
                                                                                width="17"
                                                                            />
                                                                            <div style={{height: "auto"}}>
                                                                                {fieldProps.i18nCommon.edit}
                                                                            </div>
                                                                        </Button>
                                                                    </Link>
                                                                )}
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            {/* CONTENT */}
                                            <Grid item container direction="column">
                                                {fieldProps.formState.isFirstPhase && (
                                                    <>
                                                        <Grid item container direction="row">
                                                            <Grid item xs={4} md={3} alignContent="center">
                                                                <FormLabel
                                                                    className={classes.labelInGrid}
                                                                    fieldName="europeActions"
                                                                    label={fieldProps.i18n.regionalInitiative}
                                                                    withColon={true}
                                                                    {...fieldProps}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} md={6}>
                                                                <FormMultiSelectField
                                                                    fieldName="europeActions"
                                                                    {...fieldProps}
                                                                    hideLabel={true}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>
                                                        </Grid>

                                                        <Grid item container direction="row">
                                                            <Grid item xs={4} md={3}>
                                                                <FormLabel
                                                                    withColon={true}
                                                                    className={classes.labelInGrid}
                                                                    fieldName="grants"
                                                                    label={I18n[lang].csoGrants.csoGrants}
                                                                    {...fieldProps}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <FormMultiSelectField
                                                                    fieldName="grants"
                                                                    {...fieldProps}
                                                                    hideLabel={true}
                                                                    isEditable={fieldProps.formState.editMode}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item container direction="row">
                                                    <Grid item xs={12} md={3}>
                                                        <FormLabel
                                                            withColon={true}
                                                            className={classes.labelInGrid}
                                                            fieldName="goodPractice"
                                                            label={I18n[lang].goodPractice.goodPractice}
                                                            {...fieldProps}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        {fieldProps.formState.editMode ? (
                                                            <FormTextField
                                                                fieldName="goodPractice"
                                                                {...fieldProps}
                                                                isEditable={true}
                                                                hideLabel={true}
                                                            />
                                                        ) : (
                                                            <Link
                                                                to={`/good-practices/view/${fieldProps.formState.selectedPracticeId}`}
                                                            >
                                                                {fieldProps.formState.goodPracticeTitle}
                                                            </Link>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormLabel
                                                            withColon={true}
                                                            className={classes.labelInGrid}
                                                            fieldName="initiatives"
                                                            {...fieldProps}
                                                        />
                                                        {fieldProps.formState.selectedPracticeId && (
                                                            <Typography variant="body2">
                                                                fieldProps.formState.goodPracticeInitiatives
                                                            </Typography>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid item container direction="row">
                                                    <Grid item xs={12} md={3}>
                                                        <FormLabel
                                                            withColon={true}
                                                            className={classes.labelInGrid}
                                                            fieldName="projects"
                                                            label={fieldProps.i18n.otherDonorSupport}
                                                            {...fieldProps}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <FormMultiSelectField
                                                            fieldName="projects"
                                                            {...fieldProps}
                                                            hideLabel={true}
                                                            isEditable={fieldProps.formState.editMode}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {/* 2nd Row */}

                            <Grid container direction="row" className={classes.layoutColumn}>
                                <Hidden only={['xs', 'sm']}>
                                    {fieldProps.formState.isFirstPhase && (
                                        <Grid item xs={12} md={5}>
                                            <Paper className={classes.paper}>
                                                <Typography variant="h3">
                                                    {I18n[lang].communities.uleadIndicators}
                                                </Typography>

                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    direction="row"
                                                    justify="space-between"
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        direction="column"
                                                        xs={6}
                                                        justify="space-between"
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4">S01d:</Typography>
                                                            <Typography variant="body2">
                                <span
                                    className={classNames(
                                        classes.indicatorsCircle,
                                        {
                                            [classes.indicatorsCircleRed]:
                                            fieldProps.formState.resultSo1dActual,
                                        }
                                    )}
                                />
                                                                &nbsp;{fieldProps.i18n.so1dInfo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <div className="chart">
                                                                <div className="chart-container chart-container--red">
                                                                    <div className="pie-wrapper">
                                                                        <div
                                                                            className="arc"
                                                                            data-value={Math.round(
                                                                                (fieldProps.formState.resultSo1dActual *
                                                                                    100) /
                                                                                fieldProps.formState.resultSo1dMax
                                                                            )}
                                                                            title={
                                                                                isEmpty(fieldProps.formState.resultSo1d)
                                                                                    ? null
                                                                                    : fieldProps.formState.resultSo1d
                                                                                        .map((x) => `${x[0]} (${x[1]})`)
                                                                                        .join(", ")
                                                                            }
                                                                        />
                                                                        <span className="score">
                                      {fieldProps.formState.resultSo1dActual ||
                                          "N/A"}
                                    </span>
                                                                    </div>
                                                                    <span className="label label--from">0</span>
                                                                    <span className="label label--to">
                                    {fieldProps.formState.resultSo1dMax}
                                  </span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item
                                                        direction="column"
                                                        xs={6}
                                                        justify="space-between"
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4">R2a:</Typography>
                                                            <Typography variant="body2">
                                <span
                                    className={classNames(
                                        classes.indicatorsCircle,
                                        {
                                            [classes.indicatorsCircleYellow]:
                                            fieldProps.formState.resultR2aActual,
                                        }
                                    )}
                                />
                                                                &nbsp;{fieldProps.i18n.r2aInfo}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid item>
                                                                <div className="chart">
                                                                    <div className="chart-container">
                                                                        <div className="pie-wrapper">
                                                                            <div
                                                                                className="arc"
                                                                                data-value={Math.round(
                                                                                    (fieldProps.formState
                                                                                            .resultR2aActual *
                                                                                        100) /
                                                                                    fieldProps.formState.resultR2aMax
                                                                                )}
                                                                            ></div>
                                                                            <span className="score">
                                        {fieldProps.formState.resultR2aActual ||
                                            "N/A"}
                                      </span>
                                                                        </div>
                                                                        <span className="label label--from">0</span>
                                                                        <span className="label label--to">
                                      {fieldProps.formState.resultR2aMax}
                                    </span>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} md={fieldProps.formState.isFirstPhase ? 7 : 12}>
                                        <Paper className={classes.paper}>
                                            <Typography variant="h3">
                                                {I18n[lang].communities.uleadStats}
                                            </Typography>

                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                direction="column"
                                                justify="space-around"

                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    direction="row"
                                                    justify="space-around"
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        direction="column"
                                                        justify="space-around"
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4" align="center">
                                                                {fieldProps.i18n.totalParticipantsCount}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1" align="center">
                                                                {fieldProps.formState.participantsCount}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        direction="column"
                                                        justify="space-around"
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4" align="center">
                                                                {fieldProps.i18n.totalEventsNumber}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="body1" align="center">
                                                                {fieldProps.formState.relatedEvents?.length}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {fieldProps.formState.isFirstPhase && (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={3}
                                                            direction="column"
                                                            justify="space-around"
                                                        >
                                                            <Grid item>
                                                                <Typography variant="h4" align="center">
                                                                    {fieldProps.i18n.tspEventsCount}:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body1" align="center">
                                                                    {fieldProps.formState.tspEventsCount}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                    {fieldProps.formState.isFirstPhase && (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={3}
                                                            direction="column"
                                                            justify="space-around"
                                                        >
                                                            <Grid item>
                                                                <Typography variant="h4" align="center">
                                                                    {fieldProps.i18n.regionalEventsCount}:
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant="body1" align="center">
                                                                    {
                                                                        fieldProps.formState
                                                                            .regionalIntitiativeEventsCount
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>

                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                    direction="row"
                                                    justify="space-around"
                                                >
                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        direction="column"
                                                        justify="space-around"
                                                        spacing={8}
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4" align="center">
                                                                {fieldProps.i18n.genderBalance}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Doughnut
                                                                data={
                                                                    fieldProps.formState.genderDistributionChart
                                                                }
                                                                width={260}
                                                                height={80}
                                                                options={fieldProps.formState.doughnutOptions}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        direction="column"
                                                        justify="space-around"
                                                        spacing={8}
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4" align="center">
                                                                {fieldProps.i18n.employerTypeCount}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Doughnut
                                                                data={
                                                                    fieldProps.formState
                                                                        .employerTypeDistributionChart
                                                                }
                                                                width={260}
                                                                height={80}
                                                                options={fieldProps.formState.doughnutOptions}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        item
                                                        xs={3}
                                                        direction="column"
                                                        justify="space-around"
                                                        spacing={8}
                                                    >
                                                        <Grid item>
                                                            <Typography variant="h4" align="center">
                                                                {fieldProps.i18n.formatEventsCount}:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Doughnut
                                                                data={
                                                                    fieldProps.formState
                                                                        .eventFormatDistributionChart
                                                                }
                                                                width={260}
                                                                height={80}
                                                                options={fieldProps.formState.doughnutOptions}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="h3">
                                        {I18n[lang].events.events}
                                    </Typography>
                                    <ResponsiveTable lang={this.props.lang} i18n={I18n[this.props.lang].communities}
                                                     classes={classes}
                                                     items={fieldProps.formState.relatedEvents}
                                                     columns={[{id: "id"},
                                                         {id: "startDate", title: "date"},
                                                         {id: "nameEn", title: "eventTitle",
                                                             displayFunc: (row) => (<a
                                                                 target="_blank"
                                                                 rel="noreferrer"
                                                                 href={`/events/view/${row.id}`}
                                                             >
                                                                 {lang == "Eng"
                                                                     ? row.nameEn
                                                                     : row.nameUa}
                                                             </a>)},
                                                         {
                                                             id: this._isEn ? "format.en" : "format.ua",
                                                             title: "eventFormat"
                                                         },
                                                         {
                                                             id: "trainingPackageTypes",
                                                             title: "support",
                                                             displayFunc: (row) => {
                                                                 const tsp =
                                                                     row.trainingPackageTypes &&
                                                                     row.trainingPackageTypes[0];
                                                                 const tmodule =
                                                                     row.thematicModules && row.thematicModules[0];

                                                             }
                                                         },]}
                                    />


                                </Grid>
                                {!fieldProps.formState.isGuest && (
                                    <Grid item xs={12}>
                                        <Typography variant="h3">
                                            {I18n[lang].participants.participants}
                                        </Typography>
                                        <ResponsiveTable lang={this.props.lang} i18n={I18n[this.props.lang].communities}
                                                         classes={classes}
                                                         items={fieldProps.formState.relatedParticipants}
                                                         columns={[
                                                             {
                                                                 id: this._isEn ? "nameTranscription" : "name",
                                                                 title: "participantName",
                                                                 displayFunc: (row) => (<a
                                                                     target="_blank"
                                                                     rel="noreferrer"
                                                                     href={`/participants/view/${row.id}`}
                                                                 >
                                                                     {lang == "Eng"
                                                                         ? row.nameTranscription
                                                                         : row.name}
                                                                 </a>)
                                                             },
                                                             {id: "gender"},
                                                             {id: "position", title: "eventTitle"},
                                                             {
                                                                 id: this._isEn ? "employerTypeEn" : "employerTypeUa",
                                                                 title: "employerType"
                                                             },
                                                             {id: "employerName"}]}
                                        />

                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                )}
            />
        );
    }
}

export default withStyles(styles)(Item);
