export const styles = theme => ({
    root: {
        textAlign: 'left',
        padding: '30px 1.6%',
        background: '#f7f9fb',
        width: '100%',
        margin: '0',
        display: 'flex !important',
        '& div': {
            boxShadow: 'none',
        },

        '& h3': {
            fontSize: '3vh !important',
            lineHeight: '1 !important',
            letterSpacing: '2pt',
            color: '#fdbb2d'
        },
        '& .transparent .text-field input': {
            color: '#e1edfa'
        },
        '& .radion-btn': {
            display: 'flex',
            marginBottom: '5rem'
        },
        '& .radion-btn__wrap': {
            padding: '5px',
            margin: '0 5px',
            fontSize: '1rem',
            fontFamily: 'Montserrat, sans- serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flex: '1 1 33%',
            borderRadius: '6px',
            border: '1px solid rgba(0, 0, 0, 0.26)',
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.26)',

            '&:hover': {
                color: '#5486e0',
                border: '1px solid rgba(84, 134, 224, 0.5)',
            }
        },
        '& .activeItem': {
            flex: '1 1 33%',
            cursor: 'pointer',
            padding: '5px',
            margin: '0 5px',
            fontSize: '1rem',
            fontFamily: 'Montserrat, sans- serif',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: '6px',
            background: '#5486e0',
            border: '1px solid rgba(84, 134, 224, 0.5)',
            color: 'white'
        },
        '& .exportDisplay h1': {
            width:"100%",
            textAlign:"center"
        },
    },
    headerLinks: {
        marginTop: '25px',
        '& a': {
            marginRight: '7px'
        }
    },
    textField: {
        marginRight: '5px'
    },
    dateField: {
        //width: '47%',
        '& label': {
            fontSize: '12px',
            color: 'rgb(119, 136, 153)',
            fontWeight: '500',
            lineHeight: '2.25',
            transform: 'translate(0, -17px) scale(1)'
        },
        '& > div:before': {
            display: 'none'
        },
        '& input': {
            background: 'rgb(225, 237, 251)',
            borderRadius: '4px',
            fontSize: '12px',
            minHeight: '25px',
            marginBottom: '5px'
        }
    },
    select: {
        marginBottom: '20px',
        '&> div:first-child': {
            background: '#e1edfb',
            borderColor: 'rgba(0, 0, 0, 0.23)',
            borderRadius: '6px',
            padding: '0',
            minHeight: '40px'
        },
        '& .css-xp4uvy': {
            color: '#191970'
        },
        '& .css-12jo7m5': {
            color: '#191970'
        },
        '&.error-field > div': {
            borderLeft: '1px solid #fe8368',
            borderLeftWidth: '6px',
            borderRadius: '0.5rem 0.25rem 0.25rem 0.5rem',
        },
        '& .css-15k3avv': {
            zIndex: '9999',
        },
        '& .groupStyles': {
            color: '#fff',
            padding: '3px 10px',
            fontSize: '14px'
        },
        '&.startup-field .css-15k3avv': {
            backgroundColor: 'rgb(253, 187, 45)'
        },
        '& .css-vj8t7z': {
            border: '0',
            backgroundColor: '#E1EDFB',
            borderRadius: '6px',
        },
        '& .css-d8oujb': {
            display: 'none'
        },
        '& .css-1ep9fjw': {
            backgroundColor: '#B4C3D1',
            padding: '15px 6px',
            borderRadius: '0 6px 6px 0',
            display: 'none',
            '&:last-child': {
                display: 'flex',
            },
            '& svg': {
                background: 'url(/images/icons/icon-arrow-bottom.png) no-repeat center center / 15px 15px',
                '& path': {
                    display: 'none'
                }
            }
        },
        '& .css-1ep9fjw:nth-child(4)': {
            display: 'none',
        }
    },
    filters: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > div:first-child': {
            width: '315px',
        },
        '& > div': {
            marginLeft: '20px',
            width: '200px',
        }
    },
    dateBlock: {
        '& .text-field': {
            marginBottom: '0',
            position: 'relative',
            top: '-45px',
            left: '44px',
            width: '90px',

            [theme.breakpoints.down(1600)]: {
                width: '75px',
            },

        },
        '& div div': {
            '&::before': {
                display: 'none'
            },
            '& input': {
                height: '25px',
                paddingLeft: '15px',
                border: '0 !important',

                [theme.breakpoints.down(1600)]: {
                    paddingRight: "0 !important",
                    paddingLeft: "10px !important",
                },
            },
        },
    },
    dataIcon: {
        background: '#add8e6',
        width: 51,
        height: 45,
        borderRadius: 7,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '2',
        position: 'relative',

        '& img.date-icon': {
            fill: '#e6feff',
            width: '28px'
        }
    },
    dataPick: {
        width: 'calc(100% - 230px)',
        '& input': {
            top: '-45px',
            left: '51px',
            width: '84px',
            height: '24px',
            position: 'absolute',
        },
    },
    calendar: {
        minWidth: '145px',
        maxHeight: '55px',
        display: 'flex',
    },
    dateWrap: {
        maxHeight: '45px',
        marginBottom: '10px',
        overflow: 'hidden',
        minWidth: '125px',
        marginRight: '10px',
        borderRadius: '6px',
        position: 'relative',

        '& .clearDate': {
            top: '0px',
            right: '-3px',
            width: '20px',
            height: '20px',
            position: 'absolute',
            background: 'white',
            borderRadius: '50%',
            fill: '#9bbaf2',
        }
    },
    buttonGrey: {
        marginLeft: "20px",
        marginTop: "18px",
        backgroundColor: "#789",
        justifyContent: "space-between",
        "&:hover": {
            backgroundColor: "#789",
            opacity: "0.9",
        },
        "& span svg": {
            width: "20px",
        },
        "& i": {
            display: "inline-block",
            width: "16px",
            height: "16px",
            marginRight: "10px",
            background: "url(/images/icons/print-icon.png) no-repeat 0 0 / 16px 16px",
        },
    },

})
