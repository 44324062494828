import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import Item from "./item";
import { I18n } from "../../i18n";
import { ExpertService } from "../../services/ExpertService";

class Expert extends React.Component {
    render() {
        const path = "experts";

        // switch int18 by phase

        // category uses common texts for translation
        const i18nProps = {
            i18n:I18n[this.props.lang].experts,
            i18nCommon: I18n[this.props.lang].common,
        };

        return (
            <IndexTable
                {...this.props}
                {...i18nProps}
                serviceClass={ExpertService}
                path={path}
                columns={[
                    { id: "id", title: "ID" },
                    { id: "name" },
                    { id: "gender" },
                    { id: "email" }
                ]}
                onEditItem={(itemId, item, handleItemSave, props) => {
                    return (
                        <Item
                            item={item}
                            itemId={itemId}
                            indexPath={path}
                            handleItemSave={handleItemSave}
                            isEditable={true}
                            {...i18nProps}
                            {...this.props}
                        />
                    );
                }}
                onViewItem={(itemId, item, props) => {
                    return (
                        <Item
                            item={item}
                            itemId={itemId}
                            indexPath={path}
                            isEditable={false}
                            {...i18nProps}
                            {...this.props}
                        />
                    );
                }}
            />
        );
    }
}

export default Expert;
