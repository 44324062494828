import L from 'leaflet';
import * as d3 from "d3";
import { each, reverse, sum, isEmpty, clone } from 'lodash';
import Color from 'color';

const colors = [
  '#D3FFD0', '#189433', '#FFE74C', '#FF5964', '#38618C',
  '#FF7F11', '#BEB7A4', '#280B4F', '#E5A5CB', '#7F055E',
];
const colorsEventFormats = [
'#fabcae',
'#B1AFCD',
'#0C90AD',
'#d1efa1',
'#5d97de',
'#92E8E0',
'#7bd9f1',
'#bee5e6',
'#fbe084',
'#eecadb',
'#FFA7A1',
];
const colorsEmployerType = [
'#FD595D',
'#92E0E8',
'#626cdb',
'#ffd995',
'#B7A1FF',
'#2D77FC',
'#21A7FC',
'#49dcfc',
'#3cfaea',
'#83D4E5',
'#4BADCD',
'#72B3D8',
'#aebbd1',
'#60a89e',
'#b9eebe',
'#FDBB2D',
'#B07D10',
'#FC9E49',
'#D0B3D8',
];
const colorsResultsByEvents = [
'#C0C7A2',
'#F7CDD3',
'#CC747C',
'#ecebaa',
'#34adb5',
'#3464b5',
'#F78582',
'#99cccc',
'#6666cc',
'#b27fc0',
'#56cfdd',
'#EFFFA1',
];
const colorsResultsByEventsparticipants = [
'#D8EEE7',
'#8BD0B4',
'#736eab',
'#DD4B4F',
'#FFBF89',
'#E8E072',
'#91E0F4',
'#E89AAD',
'#9AAC82',
'#B8CCEF',
'#6B7EBE',
'#92E6E8',
];

const colorsSectorReformAHoutreach = [
  '#FD595D',
  '#92E0E8'
]
const colorsSectorReformEvents = [
  '#626cdb',
  '#ffd995'
]
const colorsSectorReformParticipants = [
  '#B7A1FF',
  '#2D77FC'
]

export function iconFuncs(
  selectedLayers,
  selectedSubLayers,
  layersMeta,
  layersData,
  layersFilterObjects,
  buttonsLabelsColors,
  options
) {
  // Отдаём функции генераторы иконок кластеров и маркеров
  // Distribution case:

  //return [ clusterIcon, markerIcon ]
  return [
    (cluster) => {
      var markers = cluster.getAllChildMarkers();

      return buildIcon(
        selectedLayers, selectedSubLayers, layersMeta, layersData, layersFilterObjects,
        markers.map(x => x.options), buttonsLabelsColors, options, true
      )
    },
    (feature) => {
      return buildIcon(
        selectedLayers, selectedSubLayers, layersMeta, layersData, layersFilterObjects,
        [ feature ], buttonsLabelsColors, options, false
      );
    }
  ]
}

function buildIcon(selectedLayers, selectedSubLayers, layersMeta, layersData, filterObjects, units, buttonsLabelsColors, options, asCluster){

  const unit = asCluster ? null : units[0]
  let numberedData = [];
  let pieData = [];
  let pieObjects = []
  let value;

  let asColorGradient = options.asColorGradient && options.singleLayerMode;

  // Gender Proportion is available for single layer ONLY
  // Color gradient + GP = 1 donut
  // Single number layer + GP = number + 1 pie
  const pieLayers = []
  if (!asColorGradient || selectedLayers.length > 1) {
    each(selectedLayers, (layerId) => {
      const meta = layersMeta[layerId]
      const data = layersData[layerId]
      const subLayers = selectedSubLayers[layerId] || []
      const subLayerIds = subLayers.map(x => ''+x.id)
      const isSingleSubLayer = subLayers.length == 1
      if (!data) { return }
      if (meta.type == 'number'){
        value = asCluster ? d3.sum(units, x => getNumber(data[x.unitId])) : getNumber(data[unit.unitId])
        numberedData.push({ value, label: meta.label, key: layerId })
      } else if (meta.type == 'entity') {
        value = asCluster ? d3.sum(units, x => (data[x.unitId] ? 1 : 0)) : data[unit.unitId] ? 1 : 0
        numberedData.push({ value, label: meta.label, key: layerId })
      } else if ((meta.type == 'distribution') && isSingleSubLayer){
        value = asCluster ? d3.sum(units, x => getNumber(data[x.unitId] && data[x.unitId][subLayers[0].id])) : getNumber(data[unit.unitId] && data[unit.unitId][subLayers[0].id])
        numberedData.push({ value, label: meta.label, key: layerId })
      } else if (meta.type == 'distribution'){
        pieLayers.push(layerId)
        if (asCluster){
          value = markerDistributionAgg(units, data, subLayers)
        }else {
          value = clone(data[unit.unitId]) || {}
          if (subLayers.length){
            each(value, (sectionValue, sectionId) => {
              if (subLayerIds.indexOf(sectionId)<0){
                delete value[sectionId];
              }
            })
          }
        }
        pieData.push(value)
        pieObjects.push(filterObjects[layerId] || [])
      }
    })
  }


  if (options.withGenderProportion){
    const layerId = selectedLayers[0]
    const subLayers = selectedSubLayers[layerId] || []
    const selectedSubLayer = subLayers[0]
    const data = layersData[layerId]
    let value = {}
    if (selectedSubLayer && asCluster){
      each(units, (feature) => {
        each(data[feature.unitId][selectedSubLayer.id], (val, gender) => {
          value[gender] = (value[gender] || 0) + val
        })
      })
    } else if (selectedSubLayer) {
      value = data[unit.unitId][selectedSubLayer.id]
    } else {
      value = asCluster ? markerDistributionAgg(units, data) : data[unit.unitId]
    }
    pieData.push(value ? value : {})
    pieObjects.push([{id: "MALE", en: "Male"}, {id: "FEMALE", en: "Female"}, {id: "UNKNOWN", en: "Unknown"}])
  }

  // 1n     - num in circle
  // 2n     - 2 rows num
  // 3n     - 3 rows num
  // 1n+1d  - num + donut
  // 1n+2d  - num + 2 donuts
  // 2n+d   - 2 rows num + donuts
  // 1d     - pie
  // 2d     - pie+donut
  // 3d     - pie+2 donuts
  // num + 2distr

  let iconOpts = {}

  if (pieData.length){
    iconOpts = donutOpts(pieData, numberedData.map(obj => obj.value), pieObjects, selectedLayers, pieLayers, layersMeta, options.asHromada)
  }else if (numberedData.length){
    numberedData = numberedData.map(({value, key }, index) =>
      value ?
        `<span
          id="${index}"
          key="${index}_${key}"
          class="text-mark"
          style="
            background: ${buttonsLabelsColors[key] || 'black'};
          "
        >
          ${key == 'r3b' && options.asHromada ? '<span class="red-bullit">•</span>' : ''}
          ${value}
        </span>` : ''
    ).filter(v => !!v)
    iconOpts = numberOpts(numberedData.join('<br/>'));
  }

  return L.divIcon(iconOpts)
}

export function getNumber(value){
  // ! For layers with 'particicipants: true' value is a gender distribution (e.g. {"Male": 1, "Female": 2})
  // ! So wee have to summaraze all values to show the total number
  if (!value) { return 0 }
  return (typeof value == 'number') ? value : sum(Object.values(value))
}


function numberOpts(value) {
  return {
    html: value || '',
    className: value ? `marker-cluster-custom` : 'empty-marker',
    iconSize: L.point(40, 40)
  }
}

function donutOpts(data, innerNumbers, filterObjects, selectedLayers, pieLayers, layersMeta, asHromada) {
  const rmax = 30;

  const strokeWidth = 1 //Set clusterpie stroke width
  const n = innerNumbers //Get number of markers in cluster
  const r = rmax - 2 * strokeWidth //Calculate clusterpie radius...
  const iconDim = (r+strokeWidth)*2 //...and divIcon dimensions (leaflet really want to know the size)

  const labelClass = 'marker-cluster-pie-label';
  let labelClasses = [];
  each(selectedLayers, (layerId) => {
    const layerType = layersMeta[layerId].type;
    switch(layerType){
      case 'number':
      case 'distribution': labelClasses.push(labelClass)
      break;
      case 'entity': labelClasses.push(labelClass + (asHromada ? ' with-bullit' : ''))
      break;
    }
  })

  const html = bakeThePie({
    data: data,
    valueFunc: function(d){
      return getNumber(d.value);
    },
    strokeWidth: strokeWidth,
    outerRadius: r,
    pieClass: 'cluster-pie',
    pieLabels: innerNumbers,
    pieLabelClass: labelClasses,

    pathTitleFunc: function(dataIndex){
      let sum = 0
      each(data[dataIndex], (value, id) => { sum += getNumber(value) })
      return function(d){
        const arcValue = getNumber(d.data.value)
        let value = Math.round((arcValue / sum) * 100)
        const obj = filterObjects[dataIndex].find(x => x.id == d.data.key)
        return `${obj ? (obj.en || obj.nameEn) : 'obj'}: ${arcValue} (${value}%)`
      }
    },

    pathFillFunc0: function (d) {
      return section2color(d.data.key, pieLayers[0]);
    },
    pathStrokeFunc0: function(d){
      return Color('#ffffff').string();
    },

    pathFillFunc1: function (d) {
      if (pieLayers[1]) {
        return section2color(d.data.key, pieLayers[1]);
      }
    },
    pathStrokeFunc1: function(d) {
      if (pieLayers[1]) {
        return Color('#ffffff').string();
      }
    },

    pathFillFunc2: function (d) {
      if (pieLayers[2]) {
        return section2color(d.data.key, pieLayers[2]);
      }
    },
    pathStrokeFunc2: function(d) {
      if (pieLayers[2]) {
        return Color('#ffffff').string();
      }
    },
  })

  // TODO: on svg click do:
  // var layer = geojson.getLayer(id)  =>> this.overLayer in hromada-layer.js
  // layer.fireEvent('click')

  //Create a new divIcon and assign the svg markup to the html property

  return {
    html: html,
    className: 'marker-cluster',
    iconSize: new L.Point(iconDim, iconDim)
  }
}


/*function that generates a svg markup for the pie chart*/
function bakeThePie(options) {

  /*data and valueFunc are required*/
  if (!options.data || !options.valueFunc) {
      return '';
  }
  var data = options.data,
      valueFunc = options.valueFunc,
      strokeWidth = options.strokeWidth ? options.strokeWidth : 1, //Default stroke is 1
      pathFillFunc0 = options.pathFillFunc0 ? options.pathFillFunc0 : function(){return '#fff';}, //Fill color for each path
      pathStrokeFunc0 = options.pathStrokeFunc0 ? options.pathStrokeFunc0 : function(){return '#fff';}, //Fill color
      pathFillFunc1 = options.pathFillFunc1 ? options.pathFillFunc1 : function(){return '#fff';}, //Fill color for each path
      pathStrokeFunc1 = options.pathStrokeFunc1 ? options.pathStrokeFunc1 : function(){return '#fff';}, //Fill color
      pathFillFunc2 = options.pathFillFunc2 ? options.pathFillFunc2 : function(){return '#fff';}, //Fill color for each path
      pathStrokeFunc2 = options.pathStrokeFunc2 ? options.pathStrokeFunc2 : function(){return '#fff';}, //Fill color
      pathTitleFunc = options.pathTitleFunc ? options.pathTitleFunc : function(){return '';}, //Title for each path
      pieClass = options.pieClass ? options.pieClass : 'marker-cluster-pie', //Class for the whole pie
      pieLabels = options.pieLabels || [],
      // ? options.pieLabel : d3.sum(data,valueFunc), //Label for the whole pie
      pieLabelClass = options.pieLabelClass ? options.pieLabelClass : ['marker-cluster-pie-label'];//Class for the pie label

  let r = options.outerRadius || 28 //Default outer radius = 28px
  const arcWidth = 8

  const origo = r+strokeWidth  + arcWidth * (data.length-1) //Center coordinate


  let rInner = pieLabels.length ? r-arcWidth : 1

  let w = origo*2  //width and height of the svg element
  let h = w

  const donut = d3.pie()

  //Create an svg element
  const svg = document.createElementNS(d3.namespace.svg, 'svg')

  let vis = d3.select(svg)
    .attr('class', pieClass)
    .attr('width', w)
    .attr('height', h)
    .append("g")
    .attr("transform", "translate(" + w / 2 + "," + h / 2 + ")")

  each(data, (distribution, index)=>{
    const pieData = d3.entries(distribution)
    const arc = d3.arc().innerRadius(rInner).outerRadius(r)

    /*var arc = d3.arc()
        .outerRadius((index + 1) * pieWidth - 1)
        .innerRadius(index * pieWidth);
    */

    vis = vis.data([ pieData ])
    var g = vis.selectAll(".arc" + index).data(donut.value(valueFunc)).enter().append("g")
        .attr("class", "arc" + index);
    if (index === 1) {

      rInner = r - strokeWidth
      r += arcWidth
      return (
        g.append("path")
        .attr("d", arc)
        .attr('fill', pathFillFunc1)
        .attr('stroke', pathStrokeFunc1)
        .attr('stroke-width', strokeWidth)
        .append('svg:title')
        .text(pathTitleFunc(index))
      );
    }
    if (index === 2) {

      rInner = r - strokeWidth
      r += arcWidth
      return (
        g.append("path")
        .attr("d", arc)
        .attr('fill', pathFillFunc2)
        .attr('stroke', pathStrokeFunc2)
        .attr('stroke-width', strokeWidth)
        .append('svg:title')
        .text(pathTitleFunc(index))
      );
    }

    g.append("path")
      .attr("d", arc)
      .attr('fill', pathFillFunc0)
      .attr('stroke', pathStrokeFunc0)
      .attr('stroke-width', strokeWidth)
      .append('svg:title')
      .text(pathTitleFunc(index));
    //Create the pie chart
    /*const vis = d3.select(svg)
      .data([ d3.entries(distribution) ])
      .attr('class', pieClass)
      .attr('width', w)
      .attr('height', h);
    if (i == 0) {
      basePie = vis
    }
    const arcs = vis.selectAll('g.arc')
        .data(donut.value(valueFunc))
        .enter().append('svg:g')
        .attr('class', 'arc')
        .attr('transform', 'translate(' + origo + ',' + origo + ')');

    arcs.append('svg:path')
        .attr('fill', pathFillFunc)
        .attr('stroke', pathStrokeFunc)
        .attr('stroke-width', strokeWidth)
        .attr('d', arc)
        .append('svg:title')
          .text(pathTitleFunc(i));
    */
    rInner = r - strokeWidth
    r += arcWidth
  })

  let yOrigo = origo - (pieLabels.length-1) * 8
  each(pieLabels, (number, i) => {
    if (!number) { return }
    const klass = pieLabelClass[i]
    const withBullit = klass.indexOf('with-bullit') >= 0;
    if (withBullit){
      d3.select(svg).append('text')
      .attr('x', origo-5)
      .attr('y', yOrigo)
      .attr('class', 'marker-cluster-pie-label-bullit')
      .attr('text-anchor', 'middle')
      .attr('dy','.3em')
      .text('•');
    }
    d3.select(svg).append('text')
      .attr('x', origo+(withBullit ? 5 : 0))
      .attr('y', yOrigo)
      .attr('class', klass)
      .attr('text-anchor', 'middle')
      //.attr('dominant-baseline', 'central')
      /*IE doesn't seem to support dominant-baseline, but setting dy to .3em does the trick*/
      .attr('dy','.3em')
      .text(number);

    yOrigo += 15
  })

  //Return the svg-markup rather than the actual element
  return serializeXmlNode(svg);
}

function serializeXmlNode(xmlNode) {
  if (typeof window.XMLSerializer != "undefined") {
      return (new window.XMLSerializer()).serializeToString(xmlNode);
  } else if (typeof xmlNode.xml != "undefined") {
      return xmlNode.xml;
  }
  return "";
}

const genderColors = {
  "MALE": "#d3ecfc", //"#36A2EB",
  "FEMALE": "#fcdfe6", //"#FF6384",
  "UNKNOWN": "#5854D7"
}

const layer2colors = {
  event_formats:               colorsEventFormats,
  employer_types:              colorsEmployerType,
  event_results:               colorsResultsByEvents,
  participant_results:         colorsResultsByEventsparticipants,
  leader_steps:                colorsResultsByEvents,
  special_steps:               colorsResultsByEventsparticipants,
  sector_decentr_ah_outreach:  colorsSectorReformAHoutreach,
  sector_decentr_events:       colorsSectorReformEvents,
  sector_decentr_participants: colorsSectorReformParticipants,
}

export function section2color(sectionId, layerId){
  const number = parseInt(sectionId)
  if (!number) { return genderColors[sectionId] }

  const colorsSet = layer2colors[layerId] || colors;
  return colorsSet[number % colorsSet.length];
}

function markerDistributionAgg(features, layerData, subLayers){
  const data = {}
  each(features, (feature) => {
    each(layerData[feature.unitId], (value, sectionId) => {
      if (subLayers && subLayers.length && !subLayers.find(x => x.id == sectionId)) { return }
      data[sectionId] = (data[sectionId] || 0) + getNumber(value)
    })
  })
  return data
}
