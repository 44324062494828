import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";

const CustomTitle = ({ title, props, xs = 12 }) => {
  const { classes } = props;

  return (
    <Grid container alignItems="center" className={classes.title}>
      <Grid item xs={xs}>
        <Typography gutterBottom variant="h2" component="h2">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomTitle);
