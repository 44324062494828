import { DataFetcher } from "../utils/DataFetcher";
import { RouterStore } from "./RouterStore";
import { AppStore } from "./AppStore";
import { LangStore } from "./LangStore";
import { AuthStore } from "./AuthStore";
import { UserStore } from "./UserStore";
import { PreregistrationStore } from "./PreregistrationStore";
import { EventStore } from "./EventStore";

export class RootStore {
  constructor(history) {
    const dataFetcher = new DataFetcher();
    this.routerStore = new RouterStore(history);
    this.appStore = new AppStore();
    this.langStore = new LangStore();
    this.eventStore = new EventStore(dataFetcher);
    this.userStore = new UserStore(dataFetcher, this.langStore);
    this.preregistrationStore = new PreregistrationStore(
      dataFetcher,
      this.langStore
    );
    this.authStore = new AuthStore(dataFetcher, this);
    this.authStore.initialize();
  }
}
