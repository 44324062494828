import React from 'react';
import {Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import fetchData from '../../utils/fetch';
import EventByYearChart from '../../components/charts/registry/event-by-year';
import {Link, Redirect} from 'react-router-dom';
import {styles} from './styles';
import {stateLabel} from '../indicator/utils';


const COUNT_RES = [1,2,3,4,5,6,7];

class Dashboard extends React.Component {
  state = {
    loading: true,
    indicators: [],
    gotoIndicators: false,
    gotoCharts: false
  };

  componentDidMount() {
    this.getIndicators()
  }

  async getIndicators() {
    const data = await fetchData('get', '/indicators');

    this.setState({ loading: false, indicators: data[0] });
  }

  setRedirectToIndicators = () => {
    this.setState({ gotoIndicators: true })
  }
  redirectToIndicators() {
    return <Redirect to='/indicators' />
  }

  setRedirectToCharts = () => {
    this.setState({ gotoCharts: true })
  }

  redirectToCharts() {
    return <Redirect to='/graphs' />
  }

  isFirstPhase() {
    return this.props.user?.currentPhaseNumber == 1;
  }

  search = (array, search='R1') => array.filter(item => item.code.substring(0, search.length) === search);

  render() {
    const { classes, lang, user } = this.props;
    const { indicators, gotoIndicators, gotoCharts } = this.state;
    const isFirstPhase = this.isFirstPhase();

    return (
      <>
      {gotoIndicators && this.redirectToIndicators()}
      {gotoCharts && this.redirectToCharts()}
      <Grid container spacing={24} className={classes.root}>
        <Grid item xs={4} style={{ paddingLeft: 0 }} onClick={this.setRedirectToCharts}>
          <h3>Graphs</h3>
          <Grid container xs={12} onClick={this.setRedirectToCharts}>
            <Grid item xs={12} lg={12}>
              {/*<h4> Progress of Amalgamation in Ukraine </h4>*/}
              {/*<AmalgamationProgressChart noActions="true" />*/}
              <EventByYearChart/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}/>
        <Grid item xs={7} style={{paddingRight: 0}}>
          <h3>Interactive Mapping Tool</h3>
          <br/>
          <Link to={'/map'}>
            <img width='100%' src={'/images/pics/mapV2.jpg'} alt="Map of AHs" />
          </Link>
        </Grid>

        {!isFirstPhase ? '' : (
        <Grid item xs={12} style={{ paddingLeft: 0 }}>
          {/* <Table className={classes.indicatorsTable} onClick={this.setRedirectToIndicators}> */}
          <h3>Indicators</h3>
          <Grid container spacing={24}>
            <Grid item xs={3}>
              <h4>Specific Objective</h4>
                <div className={classes.SO}>
                  {indicators && this.search(indicators, 'SO').map(indicator =>
                    indicator.activated && <Link to={`indicator/${indicator.id}`} style={{ marginBottom: "5px", marginRight: '5px' }}>
                      <div className={classes.subTitle}>
                        {indicator.code}
                      </div>
                      <div className={classes.indicatorState}>
                        {stateLabel(indicator.status)}
                      </div>
                    </Link>
                  )}
                </div>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={7}>
              <div className={classes.RES}>
                {COUNT_RES.map(item => {
                  return (
                    <div className='resultId'>
                      <h4>Result {item}</h4>
                      <div className={classes.SO} style={{ flexDirection: 'column' }}>
                        {indicators && this.search(indicators, `R${item}`).map(indicator =>
                          indicator.activated && <Link to={`indicator/${indicator.id}`} style={{ marginBottom: "5px" }}>
                            <div className={classes.subTitle}>
                              {indicator.code}
                            </div>
                            <div className={classes.indicatorState}>
                              {stateLabel(indicator.status)}
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </Grid>
            <Grid item xs={1}/>
          </Grid>
        </Grid>
        )}
        </Grid>
      </>
    )
  }
}

export default withRoot(withStyles(styles)(Dashboard));
