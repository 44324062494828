import React from "react";
import classNames from "classnames";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    List,
    ListItem,
    Card,
    CardContent,
    CardActions, withWidth,
} from "@material-ui/core";
import Hidden from '@material-ui/core/Hidden';
import notify from "../../utils/notifier";
import Loader from "../../components/loader";
import {Link, Route} from "react-router-dom";
import {changeUrl} from "../../utils/history";
import {find} from "lodash";
import "url-search-params-polyfill";
import TablePaginationActions from "../../components/pagination/CustomPagination";
import GIZButton from "../../components/GIZButton";
import PropTypes from "prop-types";
import CustomSearch from "../../components/search/index";
import CustomTitle from "../../components/title/index";
import {I18n} from "../../i18n";
import {isSmall, isXl} from "../../config";

class IndexTable extends React.Component {
    constructor(props) {
        super(props);
        this.service = new props.serviceClass(this.handleUpdateState, props.lang);
        this.state = this.service.GetInitialIndexState(
            props.user ? props.user.current_phase_id : undefined
        );
    }

    handleUpdateState = (state) => {
        this.setState(state, this.handleMessages);
    };

    componentDidMount() {
        let newState = this.service.UpdateStateFromQuery(
            this.state,
            document.location.search
        );

        this.setState(newState, this.load(this.state, this.service));
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.path !== prevProps.path) {

            const newService = new this.props.serviceClass(this.handleUpdateState, this.props.lang);
            const nextState = newService.GetInitialIndexState(
                this.props.user ? this.props.user.current_phase_id : undefined
            );
            const newState = newService.UpdateStateFromQuery(nextState, document.location.search);
            this.setState(newState, this.load(newState, newService));
        }
    }

    load = (state, service) => {
        service.GetList(state).then((r) => {
            this.setState(r);
            if (r.queryString && r.queryString !== "") {
                changeUrl(r.queryString);
            }
        });
    }

    handleChangePage = (event, page) => {
        this.service.SetPage(this.state, page).then((r) => {
            this.setState(r);
            changeUrl(r.queryString);
        });
    };

    handleMessages = () => {
        if (this.state.messages) {
            for (let message of this.state.messages.filter((m) => !m.handled)) {
                message.handled = true;
                switch (message.type) {
                    case "message":
                        notify(message.payload);
                        break;
                    case "redirect":
                        this.props.history.push(message.payload);
                        break;
                    case "close":
                        this.setState({open: false});
                        break;
                    default:
                }
            }
        }
    };

    viewItem = ({
                    match: {
                        params: {id},
                    },
                }) => {
        if (this.state.loading) return null;
        const item = find(this.state.items, {id: Number(id)});

        return this.props.onViewItem(id, item, this.handleItemSave);
    };

    editItem = ({
                    match: {
                        params: {id},
                    },
                }) => {
        if (this.state.loading) return null;
        const item = find(this.state.items, {id: Number(id)});

        return this.props.onEditItem(id, item, this.handleItemSave, this.props);
    };

    handleChangeRowsPerPage = (event) => {
        this.setState(
            this.service.SetPageSize(this.state, event.target.value),
            this.handleMessages
        );
    };

    updateInputValue = (e) => {
        this.setState(
            this.service.SetSearchQuery(this.state, e.target.value),
            this.handleMessages
        );
    };

    clearSearchField = () => {
        this.service.ClearSearch(this.state).then((r) => {
            this.setState(r, this.handleMessages);
            changeUrl(r.queryString);
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.load(this.state, this.service);
    };

    handleSort = (orderBy) => () => {
        this.service.SetSort(this.state, orderBy).then((r) => {
            this.setState(r, this.handleMessages);
            changeUrl(r.queryString);
        });
    };

    handleItemSave = (r) => {
        // received new item from dialog
        this.updateSingleItem(r);
    };

    handleCancelDelete = () => {
        this.setState(
            this.service.SetCancelDelete(this.state),
            this.handleMessages
        );
    };

    handleConfirmDelete = () => {
        this.service
            .SetDelete(this.state)
            .then((r) => this.setState(r, this.handleMessages));
    };

    handleColumnFilterChange = (
        value,
        isChecked,
        groupsBuf = "false",
        callback = null
    ) => {
        this.service
            .SetColumnFilter(this.state, value, isChecked)
            .then((r) => this.setState(r, this.handleMessages));
    };

    handleDeleteItem(id) {
        this.setState(
            this.service.SetConfirmDelete(this.state, id),
            this.handleMessages
        );
    }

    updateSingleItem(newItem) {
        let items = [...this.state.items];

        if (this.props.path === 'participants') {
            if (newItem.shortEvents.length === 0) {
                items = items.filter(item => item.id !== newItem.id)
                return this.setState({items: items});
            }
        }

        const index = items.findIndex((x) => x.id === newItem.id);
        if (index === -1) {
            items.unshift(newItem);
        } else {
            items[index] = newItem;
        }

        this.setState({items: items});
    }

    render() {
        const {
            classes,
            columns,
            showView,
            showEdit,
            showCreate,
            showDelete,
            renderCreateButton = null,
        } = this.props;
        const {items, page, rowsPerPage, total, order, orderBy} = this.state;

        if (this.props.render) {
            const tableProps = {
                ...this.props,
                state: this.state,
                columns,
                handleSort: this.handleSort,
                clearSearchField: this.clearSearchField,
                updateInputValue: this.updateInputValue,
                handleSubmit: this.handleSubmit,
                handleChangePage: this.handleChangePage,
                handleColumnFilterChange: this.handleColumnFilterChange,
                handleResetColumnFilters: this.handleResetColumnFilters,
                viewItem: this.viewItem.bind(this),
                editItem: this.editItem.bind(this),
            };
            return this.props.render(tableProps);
        } else {
            return (
                <div className={classNames(classes.root, "toPrint")}>
                    {this.state.loading && <Loader/>}
                    <Grid container direction="row" alignItems="center">
                        <Grid item xs={12} md={7}>
                            <CustomTitle
                                title={
                                    this.props.header ??
                                    this.props.i18n.header ??
                                    I18n[this.props.lang].header[this.props.path]
                                }
                                props={this.props}
                            />
                        </Grid>
                        {(!showCreate ? true : showCreate()) && (
                            <Grid item xs={12} md={5} style={{display: "flex", justifyContent: "flex-end"}}>
                                {(!isSmall(this.props.width) || !this.props.hideButtonsOnMobile) && (
                                    <Link to={`/${this.props.path}/create/`}>
                                    <div className={classes.indexCreate}>
                                    <GIZButton color="primary" icon="Add" isRound>
                                {this.props.i18n.create}
                                    </GIZButton>
                                    </div>
                                    </Link>
                                    )}
                            </Grid>
                        )}
                        {renderCreateButton &&
                            (<Grid item xs={12} md={5} style={{display: "flex", justifyContent: "flex-end"}}>
                                {renderCreateButton()}
                            </Grid>)
                        }
                    </Grid>

                    <Grid container direction="row" justify="space-around">
                        <Grid item xs={12} sm={6} md={4}>
                            <div className={classes.indexSearchField}>
                                <CustomSearch
                                    placeholder={""}
                                    props={this.props}
                                    value={this.state.searchQuery}
                                    updateInputValue={(item) => this.updateInputValue(item)}
                                    submit={(event) => this.handleSubmit(event)}
                                    state={this.state}
                                    clearSearchField={() => this.clearSearchField()}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={8}
                            className={classes.alignRight}
                        ></Grid>
                    </Grid>
                    <div className={classes.tableWrapper}>
                        <Hidden mdUp>
                            <List>
                                {items &&
                                    items.map &&
                                    items.map((row) => {
                                        return (
                                            <ListItem>
                                                <Card classes={{root: classes.tableCard}}>
                                                    <CardContent>
                                                        <div>
                                                            {columns.map((column, index) => {
                                                                return (
                                                                    <div className={classes.cardTableRow}>
                                                                        <div className={classes.cardTableHeader}>
                                                                            <TableSortLabel
                                                                                active={orderBy === column.id}
                                                                                direction={order === "" ? "asc" : order}
                                                                                onClick={this.handleSort(column.sort ?? column.id)}
                                                                            >
                                                                                {/* Lookupup i18n by: Title ten id or use Title direct  */}
                                                                                {this.props.i18n[column.shortTitle ?? column.title ?? column.id]
                                                                                    ?? column.title}
                                                                            </TableSortLabel>
                                                                        </div>

                                                                        <div className={classes.cardTableValue}>
                                                                        <span>
                                                                          {column.displayFunc
                                                                              ? column.displayFunc(row)
                                                                              : row[column.id]}
                                                                        </span></div>
                                                                    </div>


                                                                );
                                                            })}
                                                        </div>
                                                    </CardContent>
                                                    {!this.props.hideButtonsOnMobile && (
                                                        <CardActions className={classNames(classes.cardActions)}>

                                                            {(!showView ? true : showView(row)) && (
                                                                <Link
                                                                    to={`/${this.props.path}/view/${row.id}`}
                                                                    className={classNames("view")}
                                                                >
                                                                    <i></i>
                                                                    {/* {showButtonLabel && (
                                <span>{this.props.i18nCommon.view}</span>
                              )} */}
                                                                </Link>
                                                            )}

                                                            {(!showEdit ? true : showEdit(row)) && (
                                                                <Link
                                                                    to={`/${this.props.path}/edit/${row.id}`}
                                                                    className={classNames("edit")}
                                                                >
                                                                    <i></i>
                                                                    {/* <Edit />
                              {showButtonLabel && (
                                <span>{this.props.i18nCommon.edit}</span>
                              )} */}
                                                                </Link>
                                                            )}

                                                            {(!showDelete ? true : showDelete(row)) && (
                                                                <a
                                                                    className={classNames("delete")}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        this.handleDeleteItem(row.id);
                                                                    }}
                                                                    href="/delete"
                                                                >
                                                                    <i></i>
                                                                    {/* {showButtonLabel && (
                                <span>{this.props.i18nCommon.delete}</span>
                              )} */}
                                                                </a>
                                                            )}


                                                        </CardActions>
                                                    )}
                                                </Card>
                                            </ListItem>)
                                    })}
                            </List>
                        </Hidden>
                        <Hidden only={['xs', 'sm']}>
                            <Table className={classes.indexTable}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item) => {
                                            return (
                                                <TableCell
                                                    key={item.id}
                                                    className={classNames({
                                                        [classes.centerCell]: item.center,
                                                    })}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === item.id}
                                                        direction={order === "" ? "asc" : order}
                                                        onClick={this.handleSort(item.sort ?? item.id)}
                                                    >
                                                        {/* Lookupup i18n by: Title ten id or use Title direct  */}
                                                        {/*<Hidden lgUp>{item.shortTitle ?? item.title ?? item.id}</Hidden>*/}
                                                        <Hidden
                                                            lgUp>{(this.props.i18n[item.shortTitle ?? item.title ?? item.id] ?? item.title) ?? "&nbsp"}</Hidden>
                                                        <Hidden
                                                            only={['xs', 'sm', 'md']}>{this.props.i18n[item.title ?? item.id] ?? item.title}</Hidden>
                                                    </TableSortLabel>
                                                </TableCell>
                                            );
                                        })}
                                        {(!this.props.buttonsOnlyXl || isXl(this.props.width)) &&
                                            (
                                                <TableCell></TableCell>
                                            )}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items &&
                                        items.map &&
                                        items.map((row) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    {columns.map((column, index) => {
                                                        return (
                                                            <TableCell
                                                                key={`${column.id}.${row.id}`}
                                                                className={classNames({
                                                                    [classes.evenCell]: index % 2 === 0,
                                                                    [classes.centerCell]: column.center,
                                                                })}
                                                            >
                                                                <Link to={`/${this.props.path}/view/${row.id}`}>
                                <span>
                                  {column.displayFunc
                                      ? column.displayFunc(row)
                                      : row[column.id]}
                                </span>
                                                                </Link>
                                                            </TableCell>
                                                        );
                                                    })}

                                                    {(!this.props.buttonsOnlyXl || isXl(this.props.width)) &&
                                                        (
                                                            <TableCell
                                                                className={classNames("actions")}
                                                            >
                                                                {(!showView ? true : showView(row)) && (
                                                                    <Link
                                                                        to={`/${this.props.path}/view/${row.id}`}
                                                                        className={classNames("view")}
                                                                    >
                                                                        <i></i>

                                                                    </Link>
                                                                )}

                                                                {(!showEdit ? true : showEdit(row)) && (
                                                                    <Link
                                                                        to={`/${this.props.path}/edit/${row.id}`}
                                                                        className={classNames("edit")}
                                                                    >
                                                                        <i></i>

                                                                    </Link>
                                                                )}

                                                                {(!showDelete ? true : showDelete(row)) && (
                                                                    <a
                                                                        className={classNames("delete")}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            this.handleDeleteItem(row.id);
                                                                        }}
                                                                        href="/delete"
                                                                    >
                                                                        <i></i>

                                                                    </a>
                                                                )}
                                                            </TableCell>
                                                        )}

                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Hidden>
                        <TablePagination
                            className={classes.pagination}
                            labelDisplayedRows={({from, to, count}) =>
                                `Showing ${from} - ${to} of ${count} items`
                            }
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[]}
                            page={page}
                            onChangePage={this.handleChangePage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </div>
                    <Route
                        path={`/${this.props.path}/view/:id`}
                        render={this.viewItem.bind(this)}
                    />
                    <Route
                        path={`/${this.props.path}/edit/:id`}
                        render={this.editItem.bind(this)}
                    />
                    <Route
                        path={`/${this.props.path}/create/`}
                        render={this.editItem.bind(this)}
                    />
                    <Dialog
                        open={this.state.showConfirmDelete}
                        onClose={this.handleCancelDelete}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {`${this.props.i18nCommon.deleteConfirm}`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {`${this.props.i18nCommon.deleteConfirm} ${this.props.i18n.header} ${this.state.deleteId}?`}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCancelDelete} color="primary">
                                Return
                            </Button>
                            <Button
                                onClick={this.handleConfirmDelete}
                                color="primary"
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}

IndexTable.propTypes = {
    serviceClass: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired,
    lang: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    i18n: PropTypes.object.isRequired,
    i18nCommon: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    // onEditItem: PropTypes.func.isRequired,
    // onViewItem: PropTypes.func.isRequired,
};

export default withWidth()(IndexTable);
