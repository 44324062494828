import React from "react";

import { ExpertService } from "../../services/ExpertService.js";
import Edit from "../../components/dialog/edit.js";
import {
    FormTextField,
    FormCheckboxField,
    FormSelectField, FormRadioField, FormPhoneField, FormFileInput, ReadOnlyHorizontalField, FormTextAreaField,
} from "../../components/dialog/formField";
import {Paper, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../style/styles";
import {Settings} from "../../config";
import {I18n} from "../../i18n";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";

const Item = (props) => {
    const handleGetData = async (service, editorState) => {
        // const resultsList = await service.GetAllSelectOptions(
        //     "categories/results",
        //     editorState.categories
        // );
        // const state = { resultsList: resultsList };
        return {};
    };

    const { classes, i18n, i18nCommon, lang, ...rest } = { ...props };

    return (
        <Edit
            i18n={i18n}
            i18nCommon={i18nCommon}
            handleGetData={handleGetData}
            serviceClass={ExpertService}
            {...rest}
            lang={lang}
            classes={classes}
            render={(fieldProps) => (
                <>
                    <Grid container spacing={24} alignItems="flex-start">
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>

                                        {/*<Grid item xs={12}>*/}
                                        {/*    <Typography variant="h3">*/}
                                        {/*        {i18n.personalDetails}*/}
                                        {/*    </Typography>*/}
                                        {/*</Grid>*/}

                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            xs={12}
                                            spacing={8}
                                        >
                                            <Grid item>
                                                <FormTextField fieldName="name" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField fieldName="surname" i18n={i18n} {...fieldProps} />
                                            </Grid>

                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="information"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        </Grid>


                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>

                                        <Grid
                                            item
                                            container
                                            direction="column"
                                            xs={12}
                                            spacing={8}
                                        >
                                            <Grid item>
                                                <FormTextAreaField fieldName="expertise" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField fieldName="country" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField fieldName="position" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField fieldName="email" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField label="Cell phone" fieldName="cellphone" i18n={i18n} {...fieldProps} />
                                            </Grid>
                                            <FormRadioField
                                                fieldName="gender"
                                                selectList={[
                                                    {value: "MALE", label: "Male"},
                                                    {value: "FEMALE", label: "Female"},
                                                    {value: "N/A", label: "n/a"},
                                                ]}
                                                {...fieldProps}
                                            />
                                        </Grid>


                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3">
                                              Documents
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction="column">
                                            {fieldProps.formState.documents?.map(
                                                (document, idx) => (
                                                    <Grid
                                                        key={idx}
                                                        item
                                                        container
                                                        direction="column"
                            
                                                    >
                                                        {!document.id && (
                                                        <FormFileInput
                                                            fieldName={`new_document_${idx}`}
                                                            message="Add document"
                                                            icon="Add"
                                                            {...fieldProps}
                                                        />)}
                                                        {document.id && (
                                                            <a href={document.url}>{document.filename}</a>)}
                                                    </Grid>
                                                )
                                            )}
                                            <Grid item>
                                                <button
                                                    type="button"
                                                    className={classes.formButton}
                                                    onClick={(e) => {
                                                        fieldProps.action({
                                                            fieldName: "document_Add",
                                                        });
                                                    }}
                                                >
                                                    {I18n[lang].common.add}
                                                </button>
                                            </Grid>
                                        </Grid>
                            
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {!fieldProps.formState.isTransient && (
                                <div>
                                    <Typography variant="h3">
                                        {I18n[lang].events.events}
                                    </Typography>
                                    <Table className={classes.inlineTable}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.idColumn}>{I18n[lang].events.id}</TableCell>
                                                <TableCell>{I18n[lang].events.name}</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {fieldProps.formState.events.map((event, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{event.id}</TableCell>
                                                        <TableCell>
                                                            {`${lang === "Eng" ? event.nameEn : event.nameUa}`}
                                                        </TableCell>
                                                        {fieldProps.isEditable &&
                                                            <TableCell>
                                                                <a
                                                                    onClick={() =>
                                                                        fieldProps.action({
                                                                            fieldName: "disconnectItemFromEvent",
                                                                            value: event,
                                                                        })
                                                                    }
                                                                    href="#"
                                                                >
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        className={classNames("button-cancel", [
                                                                            classes.buttonRemoveSmall,
                                                                        ])}
                                                                        color="primary"
                                                                    >
                                                                        <i/>
                                                                        {I18n[lang].events.deleteParticipant}
                                                                    </Button>
                                                                </a>
                                                            </TableCell>}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            )}
                        </Grid>

                    </Grid>
                </>
            )}
        />
    );
};

export default withStyles(styles)(Item);
