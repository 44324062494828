import React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  Modal,
  InputLabel
} from '@material-ui/core';
import { Close} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../../withRoot';
import fetchMultiPartData from '../../../utils/fetchMultiPart';
import fetchData from '../../../utils/fetch';
import { Redirect } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { I18n } from '../../../i18n/';
import classNames from 'classnames';
import { metricValue } from '../utils';
import FileUploadProgress from '../../../components/file-upload-progress';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '100%',
    margin: '30px 3%',
  },
  dataCollection:{
    position: 'relative',
    top: -15,
    marginBottom: 66,
    '& label':{
      marginBottom: '2px',
      letterSpacing: '1px',
      fontSize: '24px',
    },
    '& .text-field':{
      margin: 0,
    },
    '& button span':{
      minWidth: 24,
    },
    '& .text-area > div':{
      paddingBottom: '7px',
      marginBottom: '15px',
    },
    '& .text-area label':{
      marginLeft: '0 !important',
    },
  },
  pb3:{
    paddingBottom: '3px !important',
  },
  modal: {
    position: 'relative',
    width: '96%',
    backgroundColor: '#f7f9fb',
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    height: '90vh',
    overflowY: 'auto',
    borderRadius: '10px',
    margin: '5vh auto',
    '&:focus':{
      outline: '5px solid transparent',
    },
    '& h2': {
      textAlign: 'center',
      fontSize: '37px',
      textTransform: 'uppercase',
      color: '#88ADF0',
      marginTop: '-5px',
      marginBottom: '45px !important',
      letterSpacing: '1.6px',
    },
    '& .text-area': {
      marginBottom: '40px',
      '& label': {
        transform: 'translate(0, 0) scale(1)',
        marginBottom: '2px',
        letterSpacing: '1px',
        fontSize: '24px',
        marginLeft: '5px',
      }
    },
    '& .btn': {
      display: 'block',
      width: '45px',
      height: '45px',
      borderRadius: '6px',
      background: '#c3bdd2 no-repeat center center',
      '&:hover': {
        background: '#958DAE no-repeat center center'
      },
      marginBottom: '10px',
      '&.btn-word': {
        backgroundImage: 'url(/images/icons/icon-word.png)',
        border: '3px solid #fe8368',
        position: 'relative',
        marginLeft: '5px',
        marginRight: '5px',
        '& img': {
          position: 'relative',
          top: '-9px',
          left: '30px',
        },
        '& i': {
          display: 'block',
          color: '#ffffff',
          borderRadius: '50%',
          background: '#fe8368',
          position: 'absolute',
          top: '-7px',
          right: '-7px',
          fontSize: '14px',
          fontWeight: '900',
          textAlign: 'center',
          lineHeight: '14px',
          width: '14px',
        }
      },
      '&.btn-upload': {
        width: '45px',
        height: '45px',
        margin: '0',
        background: '#77d2b7 no-repeat center center / 50px 50px',
        transform: 'rotate(180deg)',
        backgroundImage: 'url(/images/icons/icon-download.svg)',
        cursor: 'pointer',
        marginLeft: '12px',
        marginRight: '12px',
        '&:hover': {
          background: '#9bd9c8 no-repeat center center / 50px 50px',
          backgroundImage: 'url(/images/icons/icon-download.svg)',
        }
      },
    },
    '& .btn-remove': {
      backgroundColor: '#fe8368',
      minWidth: 40,
      padding: '8px 0px',
      borderRadius: '10px',
    }
  },
  buttons: {
    textAlign: 'right',
    '& .button-submit': {
      marginRight: '0',
      maxHeight: '47px',
    }
  },
  textField: {
    width: '100%',
    marginBottom: '40px',
    backgroundColor: '#E1EDFB'
  },
  buttonCancel: {
    marginRight: '20px'
  },
  wrapFiles:{
    display: 'flex',
    flexWrap: 'wrap'
  },
  block: {
    '& label': {
      marginBottom: '2px',
      letterSpacing: '1px',
      fontSize: '24px',
      display: 'block',
    },
    '& .btn-create': {
      height: '45px',
      margin: '5px 0 30px'
    },
    '& .btn': {
      display: 'block',
      width: '45px',
      height: '45px',
      borderRadius: '6px',
      background: '#c3bdd2 no-repeat center center',
      '&:hover':{
        background: '#958DAE no-repeat center center'
      },
      // marginTop: '10px',
      marginLeft: 45,
      '&.btn-word': {
        backgroundImage: 'url(/images/icons/icon-word.png)',
        border: '3px solid #fe8368',
        position: 'relative',
        '& i': {
          display: 'block',
          color: '#ffffff',
          borderRadius: '50%',
          background: '#fe8368',
          position: 'absolute',
          top: '-7px',
          right: '-7px',
          fontSize: '14px',
          fontWeight: '900',
          textAlign: 'center',
          lineHeight: '14px',
          width: '14px',
        }
      },
      '&.btn-upload': {
        background: '#9BD9C8 no-repeat center center / 50px 50px',
        transform: 'rotate(180deg)',
        backgroundImage: 'url(/images/icons/icon-download.svg)',
      },
    },
  }
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class DataCollection extends React.Component {
  state = {
    open: true,
    item: null,
    processSteps: [],
    deletedSteps: [],
    files: [],
    fileUploadModal: false,
    fileUploadStatus: ''
  }

  componentDidMount() {
    this.setState({item: this.props.indicator, processSteps: this.props.indicator.processSteps})
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  renderRedirect = () => {
    if (!this.state.open) {
      return <Redirect to={`/indicator/${this.state.item.id}`} />
    }
  }

  onFilesAdded = e => {
    this.setState({ fileUploadModal: true, fileUploadStatus: 'loading' })
    const files = e.target.files;
    const array = [];

    for (var i = 0; i < files.length; i++) {
      array.push(files.item(i));
    }

    this.setState({ fileUploadModal: false, files: this.state.files.concat(array) });
  }

  saveItem = async (values) => {
    var data = new FormData();
    this.setState({ fileUploadModal: 'true', fileUploadStatus: 'success' });

    [
      'id', 'sourcesAndMethods', 'validityAndLimitation', 'partnerInvolvment'
    ].forEach(x => data.set(x, values[x]) )

    const filesCount = this.fileInput.files.length;
    for(let i = 0; i < filesCount; i++){
      data.append('files[]', this.fileInput.files[i])
    }

    const steps = []
    this.state.processSteps.forEach(step => {
      steps.push(step)
    })
    this.state.deletedSteps.forEach(step => {
      step['_destroy'] = true
      steps.push(step)
    })
    data.set('process_steps_attributes', JSON.stringify(steps))

    await fetchMultiPartData('put', `/indicators/${values.id}/data_collection`, data);
    this.setState({ open: false });

    this.props.handleItemSave();
  }

  onSubmit = async values => {
    await sleep(300)
    this.saveItem(values);
  }

  handleStepTextChange = (field, idx) => {
    return (event) => {
      const steps = this.state.processSteps;
      steps[idx][field] = event.target.value
      this.setState({steps: steps})
    }
  }

  handleNewProcessStep = () => {
    const steps = this.state.processSteps;
    steps.push({position: steps.length+1, dueDate: '', description: '', responsible: ''})
    this.setState({steps: steps})
  }

  handleRemoveStep = (idx) => {
    return (event) => {
      const { processSteps, deletedSteps } = this.state;
      const step = processSteps[idx]
      if (step.id) { deletedSteps.push(step) };

      processSteps.splice(idx, 1)
      this.setState({processSteps: processSteps, deletedSteps: deletedSteps})
    }
  }

  handleRemoveNewFile = (e, nameFiles) => {
    var removeByAttr = function (arr, attr, value) {
      var i = arr.length;
      while (i--) {
        if (arr[i] && (arguments.length > 2 && arr[i][attr] === value)) {
          arr.splice(i, 1);
        }
      }
      return arr;
    }
    this.setState({ files: removeByAttr(this.state.files, 'name', nameFiles) });
  }

  renderProcessStep = (step, idx) => {
    return (
      <Grid container spacing={24} alignItems='flex-start'>
        <Grid item xs={6}>
          <Grid container spacing={24} alignItems='flex-start'>
            <Grid item xs={2}>
              <Field name="no">
                {({ input }) =>
                  <TextField
                    margin="normal"
                    className='text-field'
                    variant="outlined"
                    value={step.position}
                  />
                }
              </Field>
            </Grid>
            <Grid item xs={10}>
              <Field name="process">
                {({ input }) =>
                  <TextField
                    margin="normal"
                    className='text-field'
                    variant="outlined"
                    value={step.description}
                    onChange={this.handleStepTextChange('description', idx)}
                  />
                }
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={24} alignItems='flex-start'>
            <Grid item xs={6}>
              <Field name="responsible">
                {({ input }) =>
                  <TextField
                    margin="normal"
                    className='text-field'
                    variant="outlined"
                    value={step.responsible}
                    onChange={this.handleStepTextChange('responsible', idx)}
                  />
                }
              </Field>
            </Grid>
            <Grid item xs={5}>
              <Field name="date">
                {({ input }) =>
                  <TextField
                    margin="normal"
                    className='text-field'
                    variant="outlined"
                    value={step.dueDate}
                    onChange={this.handleStepTextChange('dueDate', idx)}
                  />
                }
              </Field>
            </Grid>
            <Grid item xs={1}>
              <div className="text-field">
                <Button variant="contained" className="btn-remove" color="primary" onClick={this.handleRemoveStep(idx)}>
                  <Close/>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  handleRemoveFile = file => {
    const lang = this.props.lang;
    return async (event) => {
      if (window.confirm(`${I18n[lang].common.deleteConfirm}${I18n[lang].common.file.toLowerCase()} ${file.name}?`)) {
        event.currentTarget.remove()
        await fetchData('delete', `/indicators/attachments/${file.id}`);
      }
    }
  }

  render() {
      const { classes, indicator, lang } = this.props;
      const { item, processSteps } = this.state;
      return (
        <>
          {this.renderRedirect()}

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div className={classes.modal}>
              <a className="close" onClick={this.handleClose}>
                <Close/>
              </a>
              <Typography gutterBottom variant="h2" component="h2">
                ENTER OR EDIT DATA COLLECTION INFORMATION
              </Typography>

              <Typography className={classes.data}>
                <Form
                  onSubmit={this.onSubmit}
                  initialValues={item}
                  render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={24} alignItems='flex-start'>
                        <Grid item xs={6}>
                          <Field name="sourcesAndMethods">
                            {({ input }) =>
                              <TextField
                                label='Sources/Methods of data collection'
                                multiline
                                rows="4"
                                rowsMax="20"
                                className='text-area'
                                variant="outlined"
                                {...input}
                              />}
                          </Field>
                        </Grid>
                        <Grid item xs={6}>
                          <Field name="validityAndLimitation">
                            {({ input }) =>
                              <TextField
                                label='Validity/Data limitation'
                                multiline
                                rows="4"
                                rowsMax="20"
                                className='text-area'
                                variant="outlined"
                                {...input}
                              />}
                          </Field>
                        </Grid>
                      </Grid>

                      <div className={classes.block}>
                        <InputLabel>Data collection process</InputLabel>
                        <Button variant="contained" className="btn-create" color="primary" onClick={this.handleNewProcessStep}>
                          {/* <Add /> */}
                          <img src="/images/icons/add-icon.png" alt="add" style={{ marginRight: 27 }} />
                          Add Process Step
                        </Button>
                      </div>
                      <div className={classes.dataCollection}>
                        <Grid container spacing={24} alignItems='flex-start' >
                          <Grid item xs={6}>
                            <Grid container spacing={24} alignItems='flex-start'>
                              <Grid item xs={2} className={classes.pb3}>
                                <InputLabel>No</InputLabel>
                              </Grid>
                              <Grid item xs={10} className={classes.pb3}>
                                <InputLabel>Process Step</InputLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container spacing={24} alignItems='flex-start'>
                              <Grid item xs={6} className={classes.pb3}>
                                <InputLabel>Responsible</InputLabel>
                              </Grid>
                              <Grid item xs={5} className={classes.pb3}>
                                <InputLabel>Due Date</InputLabel>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {processSteps.map(this.renderProcessStep)}

                        <Grid container spacing={24} alignItems='flex-start' style={{ marginTop: 55}}>
                          <Grid item xs={6}>
                            <Field name="partnerInvolvment">
                              {({ input }) =>
                                <TextField
                                  label='Partner involvement'
                                  multiline
                                  rows="7"
                                  rowsMax="20"
                                  className='text-area'
                                  variant="outlined"
                                  style={{height: 60}}
                                  {...input}
                                />}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={classes.block}>
                              <InputLabel>Upload files</InputLabel>
                              <div className={classes.wrapFiles}>
                                <a 
                                  href={undefined} 
                                  className='btn btn-upload' 
                                  onClick={event => { event.preventDefault(); this.fileInput.click() }}
                                />  
                              
                                <input 
                                  type="file" 
                                  name="fileInput" 
                                  onChange={this.onFilesAdded}
                                  ref={
                                    input => {
                                      if (!input) return;
                                      //input.addEventListener('change', this.handleFileUpload);
                                      this.fileInput = input;
                                    }
                                  }
                                  multiple="multiple"
                                  style={{ 'display': 'none' }}
                                />
                                {item && item.files.map((file, i) => {
                                  return (
                                    <a
                                      href={undefined}
                                      key={i}
                                      title={`Delete '${file.name}'`}
                                      className="btn btn-word"
                                      onClick={this.handleRemoveFile(file)}
                                    >
                                      <img src="/images/icons/icon-delete.png" alt="delete" />
                                    </a>
                                  )
                                })}
                                {this.state.files.length > 0 && this.state.files.map((file, i) => {
                                  return (
                                    <a
                                      href={undefined}
                                      key={i}
                                      title={`New file '${file.name}'`}
                                      className="btn btn-word"
                                      onClick={(e) => this.handleRemoveNewFile(e, file.name)}
                                    >
                                      <img src="/images/icons/icon-delete.png" alt="delete" />
                                    </a>
                                  )
                                })}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <Grid container spacing={24} alignItems='center'>
                        <Grid item xs={12} className={classes.buttons}>
                          <Button variant="contained" className='button-cancel' onClick={this.handleClose}>
                            <i></i>
                            {I18n[lang].common.cancel}
                          </Button>
                          <Button variant="contained" className='button-submit' type="submit" color="primary" onClick={this.handleSubmit}>
                            <i></i>
                            {I18n[lang].common.save}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                />
              </Typography>
            </div>
          </Modal>
          {this.state.fileUploadModal && (
            <FileUploadProgress status={this.state.fileUploadStatus} />
          )}
        </>
      );
  }
}

export default withRoot(withStyles(styles)(DataCollection));
