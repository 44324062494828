import React from 'react';
import {
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import { availableColors } from './constant';
import { createMuiTheme } from '@material-ui/core/styles';


export const ColorMarker = ({onChange, type, value}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const styles = [
    {
      width: '25px',
      height: '25px',
      background: value || 'black',
      marginLeft: '10px',
      border: '2px solid white',
      borderRadius: '6px',
      cursor: 'pointer',
      zIndex: '9999',
    },
    {
      margin: '-9px',
      border: '9px solid #6495ed',
      // columnCount: '4',
      fontSize: '0px',
      cursor: 'pointer',
      zIndex: '9999',
      borderRadius: '6px',
      display: 'flex',
      background: 'rgb(100, 149, 237)'
    },
    {
      fontSize: '555px',
      background: 'red'
    },
  ];

  const handleClickIsVisible = () => {
    setIsVisible(!isVisible);
  }

  const handleClickDisable = () => {
    setIsVisible(false);
  }

  const handleClickSetColor = (event) => {
    const color = event.target.dataset.value;
    setIsVisible(false);
    
    onChange(type, color);
  }

  return (
    <ClickAwayListener onClickAway={handleClickDisable}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: false,
          }}
          onClose={handleClickIsVisible}
          open={isVisible}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive={true}
          placement="right"
          title={
            <div style={styles[1]} id="map-list-pickers">
              {availableColors.map((color, index) => {
                return (
                  <div
                    key={index}
                    data-value={color}
                    style={{ width: '25px', height: '25px', background: color }}
                    onClick={handleClickSetColor}
                  />
                )
              })}
            </div>
          }
        >
          <div style={styles[0]} onClick={handleClickIsVisible} />
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
};

export default ColorMarker;
