import React, {useContext} from "react";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {Grid, Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import GIZButton from "../../components/GIZButton";
import {StoreContext} from "../../stores/context";
import styles from "../../style/styles";


const CreateButton = (props) => {
    const {path, classes} = props;
    const {userStore, langStore} = useContext(StoreContext);

    return (

        <>
            {userStore.isFirstPhase && (
                <Tooltip
                    title="Unable to create an event for first phase"
                    aria-label="Unable to create an event for first phase"
                >
                    <div className={classNames(classes.indexTooltip, classes.indexCreate)}>
                        <GIZButton color="primary" icon="Add" disabled isRound>
                            {langStore.events.create}
                        </GIZButton>
                    </div>
                </Tooltip>
            )}
            {!userStore.isFirstPhase && (
                <Link to={`/${path}/create/`}>
                    <div className={classes.indexCreate}>
                        <GIZButton color="primary" icon="Add" isRound>
                            {langStore.events.create}
                        </GIZButton>
                    </div>
                </Link>
            )}
        </>

    );
};

export default withStyles(styles)(observer(CreateButton));
