import React from 'react';
import {
  Modal,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

const styles = theme => ({
  root: {
    textAlign: 'left',
    width: '600px',
    margin: '30px auto',
  },

  modal: {
    position: 'absolute',
    width: '588px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    left: '50%',
    height: 'auto',
    overflowY: 'auto',
    top: '50%',
    borderRadius: '10px',
    transform: `translate(-50%, -50%)`,
    '& h2': {
      textAlign: 'center',
      fontSize: '37px',
      textTransform: 'uppercase',
      color: '#ADD8E6',
      marginBottom: '30px !important'
    },
    '& div': {
      fontSize: '30px',
      fontWeight: '100',
      paddingBottom: '170px',
      textAlign: 'center',
      fontFamily: 'Montserrat'
    },
    '& .loading': {
      color: '#191970',
      background: 'url(/images/icons/upload-process.svg) no-repeat -60px -75px / 835px 530px'
    },
    '& .load-success': {
      color: '#191970',
      background: 'url(/images/icons/uploaded.svg) no-repeat -460px -80px / 835px 530px'
    },
    '& .load-error': {
      color: '#FE8368',
      background: 'url(/images/icons/upload-failed.svg) no-repeat -270px -80px / 835px 530px'
    },
  },
  content: {
    textAlign: 'center',
    color: '#778899',
    fontSize: '1rem'
  }
});


class Dialogue extends React.Component {
  state = {
    open: true
  }

  componentDidMount() {
  }

  handleClose = () => {
    this.setState({ open: false });
    this.props.onClose && this.props.onClose()
  }

  render() {
    const { classes, content, title } = this.props;
    const {} = this.state;

    return (
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div className={classes.modal}>
            <a className="close" onClick={this.handleClose}>
              <Close/>
            </a>
            {title && (
              <Typography gutterBottom variant="h2" component="h2">
                {title}
              </Typography>
            )}
            <Typography className={classes.content}>
              {content.split('\n').map(x => {
                return (
                  <>
                  {x}
                  <br/><br/>
                  </>
                )
              })}
            </Typography>
            {(this.props.status === 'loading') && (
              <div className="loading">In progress</div>
            )}
            {(this.props.status === 'success') && (
              <div className="load-success">Upload complete</div>
            )}
            {(this.props.status === 'failed') && (
              <div className="load-error">Upload failed — please check file sizeand try to upload again</div>
            )}
          </div>
        </Modal>
    );
  }
}

export default withRoot(withStyles(styles)(Dialogue));
